import { useCallback, useRef, useState, VideoHTMLAttributes } from "react";

type Props = {
  fallback?: string;
  customFallback?: JSX.Element;
  renderer?: (element: JSX.Element) => JSX.Element;
} & VideoHTMLAttributes<HTMLVideoElement>;

export const FallbackVideo = ({
  fallback,
  customFallback,
  renderer,
  ...props
}: Props) => {
  const [didFail, setDidFail] = useState(false);
  const ref = useRef<HTMLVideoElement>(null);
  const handleError = useCallback(
    () => {
      if (ref.current && fallback) {
        ref.current.src = fallback;
      }
      setDidFail(true);
    },
    [fallback]
  );

  if (customFallback && (didFail || (!props.src && !fallback))) {
    return customFallback;
  }

  const element = (
    <video
      ref={ref}
      {...props}
      src={props.src || fallback}
      onError={handleError}
    />
  );

  if (renderer) {
    return renderer(element);
  }

  return element;
};
