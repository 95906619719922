import { makeAutoObservable } from "mobx";

import { LiveStream } from "../models";

import {
  createLiveStream,
  disableLiveStream,
  getLiveStreamConnection,
} from "../services/chathub";

import bots from "./bots";
import audit from "./audit";

class SettingsStore {
  liveStream?: LiveStream;

  constructor() {
    makeAutoObservable(this);
  }

  fetchLiveStream = async () => {
    if (!bots.selectedBot) {
      return;
    }

    const liveStream = await getLiveStreamConnection(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password
    );

    this.setLiveStream(liveStream || undefined);
  };

  startLiveStream = async (liveCardId: number) => {
    if (!bots.selectedBot) {
      return;
    }

    const liveStream = await createLiveStream(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      liveCardId
    );

    this.setLiveStream(liveStream || undefined);

    if (liveStream) {
      audit.logEvent("livestream_started", {
        id: liveStream?.id,
        doc: liveStream,
        botId: bots.selectedBot.id,
      });
    }
  };

  stopLiveStream = async () => {
    if (!bots.selectedBot || !this.liveStream) {
      return;
    }

    const success = await disableLiveStream(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      this.liveStream.id
    );

    if (success) {
      this.setLiveStream(undefined);

      audit.logEvent("livestream_stopped", {
        id: this.liveStream.id,
        doc: this.liveStream,
        botId: bots.selectedBot.id,
      });
    }
  };

  private setLiveStream = (liveStream?: LiveStream) => {
    this.liveStream = liveStream;
  };
}

export default new SettingsStore();
