import { useTranslation } from "react-i18next";
import { useStore } from "../../../stores";
import { useHistory, useLocation } from "react-router";
import routes from "../../../constants/routes";
import { CiCirclePlus } from "react-icons/ci";
import styles from "../WorkspaceSidebar.module.scss";
import locale from "../../../constants/locale";

export default function AddNew() {
  const { t } = useTranslation();
  const { selectedWorkspace } = useStore("workspaces");
  const location = useLocation();
  const history = useHistory();
  const auth = useStore("auth");

  const routeTo = (path: string) => {
    history.push(path.replace(":workspaceId", String(selectedWorkspace?.id)));
  };

  return (
    <>
      {auth.userHasAdminAccess && (
        <button
          style={{ height: "5rem" }}
          className="is-relative w-100 is-no-box-shadow button has-text-white is-flex is-flex-direction-column is-ghost"
          onClick={() => routeTo(routes.workspaceHome)}
        >
          <div
            style={{ display: "flex", padding: "0.4rem" }}
            className="is-relative"
          >
            {location.pathname ===
              routes.workspaceHome.replace(
                ":workspaceId",
                String(selectedWorkspace?.id)
              ) && <div className={styles.active}></div>}
            <CiCirclePlus
              style={{ zIndex: 50 }}
              fill={
                location.pathname ===
                routes.workspaceHome.replace(
                  ":workspaceId",
                  String(selectedWorkspace?.id)
                )
                  ? "#2A79EF"
                  : "#fff"
              }
              size={26}
            />
          </div>
          <span className="subtitle is-7 has-text-white">
            {t(locale.addNew)}
          </span>
        </button>
      )}
    </>
  );
}
