import { makeAutoObservable } from "mobx";
import { Node } from "reactflow";
import bots from "./bots";
import {
  Workflow,
  Category,
  WorkflowNodes,
  WorkflowEvent,
} from "../models/Workflow";
import {
  getWorkflows,
  getWorkflowNodes,
  deleteWorkflow as deleteWorkflowChathub,
  createWorkflow,
  updateWorkflow,
  getWorkflowCategories,
  updateWorkflowCategory,
  createWorkflowCategory,
  deleteWorkflowCategory,
  triggerWorkflow,
  getWorkflowEvents,
} from "../services/chathub";
import auth from "./auth";
import { DateTime } from "luxon";
import workspaces from "./workspaces";
import i18next from "i18next";

type Paginate = {
  data: WorkflowEvent[];
  total: number;
  last_page: number;
  current_page: number;
};

class WorkflowStore {
  categories: Category[] = [];
  workflows: Workflow[] = [];
  workflowNodes: WorkflowNodes | null = null;
  selectedCategory?: Category;
  selectedWorkflow?: Workflow;
  currentNode: Node | null = null;
  workflowEventsPaginate?: Paginate;

  constructor() {
    makeAutoObservable(this);
  }

  loadCategories = async () => {
    if (!bots.selectedBot) {
      return;
    }

    const categories = await getWorkflowCategories(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password
    );

    this.setCategories(categories.map(this.categoryAdapter));
  };

  updateOrCreateCategory = async (categoryObj: Category) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    if (categoryObj?.id) {
      const updatedCategory = await updateWorkflowCategory(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        categoryObj.id,
        categoryObj.name
      );
      const category = this.categoryAdapter(updatedCategory);
      const index = this.categories.findIndex((f) => f.id === category.id);
      if (index > -1) {
        this.categories[index] = category;
      }

      if (this.selectedCategory?.id === category.id) {
        this.selectCategory(category);
      }
    } else {
      const newCategory = await createWorkflowCategory(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        categoryObj.name
      );

      this.categories.unshift(this.categoryAdapter(newCategory));
    }
  };

  deleteCategory = async (category: Category) => {
    if (!bots.selectedBot) {
      return;
    }

    if (category?.id) {
      await deleteWorkflowCategory(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        category.id
      );

      const index = this.categories.findIndex((f) => f.id === category.id);

      if (index > -1) {
        this.categories.splice(index, 1);
      }
    }
  };

  loadWorkflows = async () => {
    if (!bots.selectedBot || !this.selectedCategory) {
      return;
    }

    const workflows = await getWorkflows(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      this.selectedCategory.id
    );

    this.setWorkflows(workflows.map(this.workflowAdapter));
  };

  updateOrCreateWorkflow = async (workflow: Workflow, basicUpdates = false) => {
    if (!bots.selectedBot || !auth.user || !workspaces.selectedWorkspace) {
      return;
    }

    if (workflow?.id) {
      const updatedWorkflow = await updateWorkflow(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        workflow.id,
        workflow.name,
        workflow.categoryId,
        workflow.userId,
        workflow.status,
        workflow.isActive,
        basicUpdates
          ? undefined
          : {
              ...workflow.draftData,
              workspaceId: workspaces?.selectedWorkspace?.id,
              functionId: workspaces?.selectedFunction?.slug,
            }
      );

      const index = this.workflows.findIndex((f) => f.id === workflow.id);
      if (index > -1) {
        const ref = [...this.workflows];
        ref[index] = this.workflowAdapter(updatedWorkflow);
        this.setWorkflows(ref);
      }
    } else {
      const newWorkflow = await createWorkflow(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        workflow.name,
        workflow.categoryId,
        auth.user.id,
        workflow.status,
        workflow.isActive,
        workflow.draftData
      );

      this.workflows.unshift(this.workflowAdapter(newWorkflow));
    }
  };

  deleteWorkflow = async (workflow: Workflow) => {
    if (!bots.selectedBot) {
      return;
    }

    if (workflow?.id) {
      await deleteWorkflowChathub(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        workflow.id
      );

      const index = this.workflows.findIndex((f) => f.id === workflow.id);

      if (index > -1) {
        this.workflows.splice(index, 1);
      }
    }
  };

  triggerWorkflowEvent = async (
    workflowTriggerKey: string,
    workflowMeta: any
  ) => {
    if (!bots.selectedBot) {
      return;
    }

    await triggerWorkflow(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      workflowTriggerKey,
      workflowMeta
    );
  };

  loadWorkflowNodes = async () => {
    if (!bots.selectedBot) {
      return;
    }

    const workflowNodes = await getWorkflowNodes(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      i18next.language
    );

    this.setWorkflowNodes(workflowNodes);
  };

  openNodeSettings = (node: Node) => {
    this.currentNode = node;
  };

  closeNodeSettings = () => {
    this.currentNode = null;
  };

  setCategories = (categories: Category[]) => {
    this.categories = categories;
  };

  selectCategory = (category: Category) => {
    this.selectedCategory = category;
  };

  setWorkflows = (workflows: Workflow[]) => {
    this.workflows = workflows;
  };

  setWorkflowNodes = (workflowNodes: WorkflowNodes) => {
    this.workflowNodes = workflowNodes;
  };

  categoryAdapter = (category: any): Category => {
    return {
      id: category.id,
      name: category.name,
    };
  };

  loadWorkflowEvents = async (workflowId: number, page = 1) => {
    if (!bots.selectedBot) {
      return;
    }

    const events = await getWorkflowEvents(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      workflowId,
      page
    );

    this.setWorkflowEvents({
      ...events,
      data: events.data.map(this.workflowEventAdapter),
    });
  };

  private setWorkflowEvents(paginate: Paginate) {
    this.workflowEventsPaginate = paginate;
  }

  workflowAdapter = (workflow: any): Workflow => {
    return {
      id: workflow.id,
      name: workflow.name,
      categoryId: workflow.workflow_category_id,
      userId: workflow.user_id,
      status: workflow.status,
      draftData: workflow?.draft_data,
      publishedData: workflow?.published_data,
      eventsCount: workflow.events_count,
      isActive: workflow.is_active,
      createdAt: DateTime.fromISO(workflow.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(workflow.updated_at, {
        zone: "utc",
      }).toLocal(),
    };
  };

  workflowEventAdapter = (item: any): WorkflowEvent => {
    return {
      id: item.id,
      workflowData: item?.workflow_data,
      meta: item?.meta,
      status: item.status,
      failedReason: item.failed_reason,
      createdAt: DateTime.fromISO(item.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(item.updated_at, {
        zone: "utc",
      }).toLocal(),
    };
  };
}

export default new WorkflowStore();
