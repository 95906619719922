import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "../../../stores";
import { functionIconsMap } from "../../../models/Workspace";
import routes from "../../../constants/routes";
import styles from "../WorkspaceSidebar.module.scss";

export default function WorkspaceFunctions() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { selectedWorkspace, findActiveModuleOfFunction } =
    useStore("workspaces");

  const openFunction = (slug: string) => {
    const path = findActiveModuleOfFunction(slug).replace(":functionId", slug);
    history.push(path.replace(":workspaceId", String(selectedWorkspace?.id)));
  };

  return (
    <>
      {selectedWorkspace?.functions
        ?.filter((f) => f.active)
        ?.map((f) => {
          const Icon = functionIconsMap[f.slug as any];
          const isActiveRoute = location.pathname.startsWith(
            routes.chats
              .replace(":workspaceId", String(selectedWorkspace?.id))
              .replace(":functionId", f.slug)
              .replace("/chats/:chatId?", "")
          );
          return (
            <button
              key={f.slug}
              style={{ height: "5rem" }}
              onClick={() => openFunction(f.slug)}
              className="is-relative w-100 is-no-box-shadow button has-text-white is-flex is-flex-direction-column is-ghost"
            >
              <div
                style={{ display: "flex", padding: "0.4rem" }}
                className={"is-relative"}
              >
                {isActiveRoute && <div className={styles.active}></div>}
                <Icon
                  style={{ zIndex: 50 }}
                  fill={isActiveRoute ? "#2A79EF" : "#fff"}
                  size={26}
                />
              </div>
              <span className="subtitle is-7 has-text-white has-long-text">
                {t(f.name)}
              </span>
            </button>
          );
        })}
    </>
  );
}
