import clsx from "clsx";

import { Loader } from "./Loader";

import styles from "./ComponentLoader.module.scss";

export const ScreenLoader = ({
  backgroundColor,
}: {
  backgroundColor?: string;
}) => (
  <div
    className={clsx(
      "is-flex is-flex-grow-1 is-justify-content-center is-align-items-center",
      styles.container
    )}
    style={{ backgroundColor }}
  >
    <Loader />
  </div>
);
