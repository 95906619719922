import { useState } from "react";
import clsx from "clsx";
import { useField, FieldConfig } from "formik";
import { ChromePicker } from "react-color";

import styles from "./ColorInput.module.scss";
import { Popover } from "react-tiny-popover";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  disableAlpha?: boolean;
  useInputField?: boolean;
} & FieldConfig<string>;

export const ColorInput = ({ children, ...props }: Props) => {
  const [, meta, { setValue }] = useField(props);
  const [show, setShow] = useState(false);

  return (
    <div className="field">
      {!!props.label && (
        <label className="label" htmlFor={props.name}>
          {props.label}
        </label>
      )}
      <div className="control">
        <Popover
          isOpen={show}
          padding={10}
          containerStyle={{ zIndex: "999999999999" }}
          onClickOutside={() => setShow(false)}
          content={() => (
            <ChromePicker
              disableAlpha={props.disableAlpha}
              color={meta.value}
              onChange={(c) => setValue(c.hex)}
            />
          )}
        >
          <div
            className={clsx("is-clickable", styles.swatch)}
            style={{
              zIndex: 2,
              borderRadius: "50%",
              border: "2px solid " + meta.value,
              height: "1.2rem",
              width: "1.2rem",
            }}
            onClick={() => {
              if (props.disabled) return;
              setShow(!show);
            }}
          ></div>
        </Popover>
      </div>
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
    </div>
  );
};
