import firebaseApp from "firebase/compat/app";
export type MediaType =
  | "audio"
  | "video"
  | "image"
  | "document"
  | "emailTemplate"
  | "contentTemplate";

export type CannedResponseMedia = {
  type: MediaType;
  url: string;
  name?: string;
  meta?: any;
};

export type CannedResponse = {
  id: string;
  category?: string;
  // groupId?: number;
  groupIds?: number[];
  content: string;
  media?: CannedResponseMedia[];
  createdBy: string;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
};

export const DefaultCategoryName = "Default";
