import { useCallback, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import Countdown from "react-countdown";

import { Modal } from "../components";
import { useStore } from "../stores";

import locale from "../constants/locale";

const TIMEOUT = 60000;

type Props = {
  onClose: () => void;
};

const SessionTimeout = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const auth = useStore("auth");
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleClose = useCallback(() => {
    if (!timeoutRef.current) {
      return;
    }

    clearTimeout(timeoutRef.current);

    onClose();
  }, [onClose]);

  useEffect(() => {
    timeoutRef.current = setTimeout(auth.logOut, TIMEOUT);

    return handleClose;
  }, [auth.logOut, handleClose]);

  return (
    <Modal
      isOpen
      title={t(locale.sessionTimeout)}
      primaryText={t(locale.stillHere)}
      onPrimary={handleClose}
    >
      <Trans
        i18nKey={locale.youWillBeLoggedOutDescription}
        components={[
          <Countdown
            date={Date.now() + TIMEOUT}
            renderer={({ seconds }) => seconds}
          />,
        ]}
      />
    </Modal>
  );
};

export default SessionTimeout;
