import { LiveChatCard, User, UserType } from "../models";
import { Workspace, WorkspaceFunction } from "../models/Workspace";

export const filterLiveCards = (
  cards: LiveChatCard[],
  user: User,
  selectedWorkspace: Workspace,
  selectedFunction: WorkspaceFunction
) => {
  return (
    cards.filter((card) => {
      if ([UserType.Root, UserType.Administrator].includes(user?.type!)) {
        return true;
      }

      const liveChatFilters =
        user?.permissions?.perWorkspace?.[selectedWorkspace.id]?.perFunction?.[
          selectedFunction.slug
        ]?.liveChatFilters || {};

      const keysWithNonEmptyValues = Object.keys(liveChatFilters).filter(
        (key) => liveChatFilters[key].length > 0
      );

      return keysWithNonEmptyValues.map((m) => parseInt(m)).includes(card.id!);
    }) ?? []
  );
};
