import styles from "../WorkspaceSidebar.module.scss";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores";
import { Popover } from "react-tiny-popover";
import { useCallback, useMemo, useState } from "react";
import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import routes from "../../../constants/routes";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { Collapse } from "../..";

type Menu = {
  icon: string;
  text: string;
  onClick?: () => void;
  iconClass?: string;
  children?: Menu[];
};

const MorePopover = observer(() => {
  const { selectedWorkspace } = useStore("workspaces");
  const { userHasAdminAccess } = useStore("auth");
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const { aITeams } = useStore("aITeams");
  const { t } = useTranslation();
  const history = useHistory();

  const routeTo = useCallback(
    (path: string) => {
      setIsMoreOpen(false);
      history.push(path.replace(":workspaceId", String(selectedWorkspace?.id)));
    },
    [history, selectedWorkspace]
  );

  const menus: Menu[] = useMemo(() => {
    return [
      {
        icon: "/assets/ai-team.svg",
        text: locale.aITeams,
        onClick: () => routeTo(routes.emailTemplate),
        children: aITeams.map((aITeam) => ({
          icon: aITeam?.teamInfo?.icon || "/assets/bot.svg",
          text: aITeam.name,
          iconClass: "is-image-rounded",
          onClick: () =>
            routeTo(routes.aITeamChats.replace(":teamId", aITeam.id || "")),
        })),
      },
      {
        icon: "/assets/automation.svg",
        text: locale.aITeamsSettings,
        onClick: () => routeTo(routes.aITeams.replace("/:teamId?", "")),
      },
      {
        icon: "/assets/edna-bot-settings.svg",
        text: locale.ednaBotSettings,
        onClick: () => routeTo(routes.ednaBotSettings),
      },
    ];
    // eslint-disable-next-line
  }, [routeTo, aITeams, aITeams.length]);

  if (!selectedWorkspace || !userHasAdminAccess) {
    return null;
  }

  return (
    <Popover
      isOpen={isMoreOpen}
      positions={["right", "top"]}
      align="start"
      onClickOutside={() => setIsMoreOpen(false)}
      containerStyle={{ zIndex: "9999" }}
      content={() => (
        <>
          <div
            className={clsx(styles.channelWrapper, "is-clickable")}
            style={{ width: "200px" }}
          >
            {menus.map((menu, i) => {
              if (menu.children) {
                return (
                  <Collapse
                    key={menu.text + menu.children.length}
                    renderTrigger={(trigger) => (
                      <div
                        key={menu.text}
                        {...trigger}
                        className={clsx(
                          "is-clickable is-flex is-align-items-center",
                          styles.channelName
                        )}
                        style={{ cursor: "pointer" }}
                      >
                        <span className={clsx("icon mr-2", styles.icon)}>
                          <img src={menu.icon} alt={menu.text} width={20} />
                        </span>
                        <span>{t(menu.text)}</span>

                        <div className="icon">
                          {trigger.isExpanded ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="16"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="16"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    )}
                  >
                    <div className="mx-4">
                      {menu.children.map((child, i) => (
                        <div
                          key={i}
                          onClick={child.onClick}
                          className={clsx(
                            "is-clickable is-flex is-align-items-center",
                            styles.channelName
                          )}
                        >
                          <span className={clsx("icon mr-2", styles.icon)}>
                            <img
                              className={child?.iconClass}
                              src={child.icon}
                              alt={child.text}
                              width={30}
                            />
                          </span>
                          <span>{t(child.text)}</span>
                        </div>
                      ))}
                    </div>
                  </Collapse>
                );
              }
              return (
                <div
                  key={i}
                  onClick={menu.onClick}
                  className={clsx(
                    "is-clickable is-flex is-align-items-center",
                    styles.channelName
                  )}
                >
                  <span className={clsx("icon mr-2", styles.icon)}>
                    <img src={menu.icon} alt={menu.text} width={20} />
                  </span>
                  <span>{t(menu.text)}</span>
                </div>
              );
            })}
          </div>
        </>
      )}
    >
      <button
        onClick={() => setIsMoreOpen(!isMoreOpen)}
        style={{ height: "5rem" }}
        className="w-100 is-no-box-shadow button has-text-white is-flex is-flex-direction-column is-ghost"
      >
        <div>
          <MdOutlineMoreHoriz size={26} />
        </div>
        <span className="subtitle is-7 has-text-white">{t(locale.more)}</span>
      </button>
    </Popover>
  );
});

export default MorePopover;
