import { useEffect } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { useStore } from "../../stores";

import routes from "../../constants/routes";
import { FullScreenLoader } from "../loader/FullScreenLoader";

const AutoSelect = observer(
  ({ Comp, guard }: { Comp: React.ComponentClass; guard: boolean }) => {
    const { functionId, chatId, workspaceId } = useParams<{
      functionId?: string;
      botId?: string;
      chatId?: string;
      workspaceId?: string;
    }>();
    const history = useHistory();
    const { selectChat, selectedChat } = useStore("chats");
    const {
      workspaceLoaded,
      selectWorkspace,
      selectedWorkspace,
      selectedFunction,
      selectFunction,
    } = useStore("workspaces");
    const [loading, setLoading] = React.useState(false);

    const select = React.useCallback(async () => {
      if (workspaceId && selectedWorkspace?.id !== workspaceId && guard) {
        try {
          setLoading(true);
          await selectWorkspace(workspaceId);
        } catch (error) {
          history.push(
            routes.workspaceNoAccess.replace(":workspaceId", workspaceId)
          );
        } finally {
          setLoading(false);
        }
      }
      if (functionId && selectedFunction?.slug !== functionId) {
        setLoading(true);
        await selectFunction(functionId);
        setLoading(false);
      }
      if (chatId && selectedChat?.id !== Number(chatId)) {
        await selectChat(Number(chatId));
      }
    }, [
      workspaceId,
      selectedWorkspace?.id,
      guard,
      functionId,
      selectedFunction?.slug,
      chatId,
      selectedChat?.id,
      selectWorkspace,
      history,
      selectFunction,
      selectChat,
    ]);

    useEffect(() => {
      select();
    }, [select]);

    if (loading && !workspaceLoaded) {
      return (
        <div>
          <FullScreenLoader isOpen={true} />
        </div>
      );
    }
    return <Comp />;
  }
);

export const GuardedRoute = observer(
  ({ render: Comp, guard, ...rest }: any) => {
    const { user } = useStore("auth");

    if (guard && user && !user.enrolled2fa && !user.skip2fa) {
      return (
        <Route
          {...rest}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: routes.mfa,
                state: { from: location },
              }}
            />
          )}
        />
      );
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          user || !guard ? (
            <AutoSelect Comp={Comp} guard={guard} />
          ) : (
            <HandleRedirect
              to={{
                pathname: routes.signIn,
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
);

function HandleRedirect({ to }: any) {
  const location = useLocation();

  if (location.pathname === "/join-workspace") {
    localStorage.setItem("intendedURL", window.location.href);
  }

  return <Redirect to={to} />;
}
