import Firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/remote-config";
import "firebase/compat/messaging";

const firebase = Firebase.initializeApp((window as any).firebaseAppConfig);

firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true });

// var firebaseEmulators = {
//   auth: {
//     host: "localhost",
//     port: 9099,
//   },
//   firestore: {
//     host: "localhost",
//     port: 8080,
//   },
//   functions: {
//     host: "localhost",
//     port: 5001,
//   },
// };

// if (
//   process.env.NODE_ENV !== "production" &&
//   firebaseEmulators.firestore &&
//   typeof firebase.firestore === "function"
// ) {
//   firebase
//     .firestore()
//     .useEmulator(
//       firebaseEmulators.firestore.host,
//       firebaseEmulators.firestore.port
//     );
// }

// if (
//   process.env.NODE_ENV !== "production" &&
//   firebaseEmulators.functions &&
//   typeof firebase.functions === "function"
// ) {
//   firebase
//     .functions()
//     .useEmulator(
//       firebaseEmulators.functions.host,
//       firebaseEmulators.functions.port
//     );
// }

// if (
//   process.env.NODE_ENV !== "production" &&
//   firebaseEmulators.auth &&
//   typeof firebase.auth === "function"
// ) {
//   firebase
//     .auth()
//     .useEmulator(
//       "http://" +
//         firebaseEmulators.auth.host +
//         ":" +
//         firebaseEmulators.auth.port
//     );
// }

export default firebase;
