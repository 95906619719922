import { Location } from "./Location";
import { Restaurant } from "./Restaurant";

export const PaymentMethods = {
  CreditCard: 0,
  Cash: 1,
  EdnaPay: 3,
  Other: 2,
};

export enum BranchPaymentMethod {
  CreditCard = 0,
  Cash = 1,
  EdnaPay = 3,
  Other = 2,
}

export enum DeliveryType {
  Express = 0,
  PickUp = 1,
  InRestaurant = 2,
}

export type DeliveryZone = {
  time: number;
  price: number;
  name?: string;
  radius?: number;
};

export type OtherPaymentMethodInfo = {
  image?: string;
  fbAttachmentId?: string;
  description?: string;
};

export enum DeliveryCoverageType {
  Radius = 0,
  Zone = 1,
}

export type Branch = {
  paymentMethods: BranchPaymentMethod[];
  deliveryTypes: DeliveryType[];
  deliveryCoverageType?: DeliveryCoverageType;
  deliveryCoverage: DeliveryZone[];
  managers?: string[];
  notificationNumbers?: string[];
  otherPaymentMethod?: string;
  otherPaymentMethodInfo?: OtherPaymentMethodInfo;
  disableNotifications: boolean;
  disableEstimateDeliveryTime: boolean;
  disableOrderTracking: boolean;
  location?: Location;
} & Omit<
  Restaurant,
  | "botId"
  | "botName"
  | "botGreetingMessage"
  | "preparationTime"
  | "contactEmails"
  | "contactPhones"
  | "logo"
  | "currency"
  | "country"
  | "plan"
  | "cuisine"
  | "selectedPage"
>;

export const paymentMethodsMap = {
  [BranchPaymentMethod.Cash]: "cash",
  [BranchPaymentMethod.CreditCard]: "creditCard",
  [BranchPaymentMethod.Other]: "otherPaymentMethod",
  [BranchPaymentMethod.EdnaPay]: "ednaPay",
};

export const disabledPaymentMethodToolTipMap = {
  [BranchPaymentMethod.EdnaPay]: "disabledEdnaPay",
  [BranchPaymentMethod.Cash]: "disabledCash",
  [BranchPaymentMethod.CreditCard]: "disabledCreditCard",
  [BranchPaymentMethod.Other]: "disabledOther",
};

export const deliveryTypeMap = {
  [DeliveryType.Express]: "express",
  [DeliveryType.InRestaurant]: "inRestaurant",
  [DeliveryType.PickUp]: "pickUp",
};

export const deliveryCoverageTypeMap = {
  [DeliveryCoverageType.Radius]: "radius",
  [DeliveryCoverageType.Zone]: "zone",
};
