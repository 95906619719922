import { observer } from "mobx-react-lite";
import { FallbackImage, FullScreenLoader, Modal } from "../..";
import { useStore } from "../../../stores";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import routes from "../../../constants/routes";
import styles from "./WorkspaceSwitcherModal.module.scss";
import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  onClose: () => void;
};

const WorkspaceSwitcherModal = ({ open, onClose }: Props) => {
  const auth = useStore("auth");
  const { clearSelectedWorkspace, selectedWorkspace, userWorkspaces } =
    useStore("workspaces");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedWorkspace?.id) {
      setIsLoading(false);
    }
  }, [selectedWorkspace?.id]);

  const handleWorkspaceSelect = (workspaceId: string) => {
    setIsLoading(true);
    clearSelectedWorkspace();
    onClose();
    history.push(routes.workspaceHome.replace(":workspaceId", workspaceId));
  };

  const getImage = (image?: string) =>
    image?.startsWith("/")
      ? process.env.REACT_APP_CHATHUB_APP_URL + image
      : image;

  return (
    <>
      <Modal
        isOpen={open}
        title={() => (
          <div className="w-100 is-flex is-justify-content-space-between">
            <p className="modal-card-title has-ellipsis-if-long">
              {t(locale.workspaces)}
            </p>
            {auth.userHasAdminAccess && (
              <div
                onClick={() => {
                  clearSelectedWorkspace();
                  history.push(routes.createWorkspace);
                  onClose();
                }}
              >
                <span
                  className={clsx(styles.addWorkspaceIcon, "icon is-clickable")}
                >
                  <i className="fas fa-plus" />
                </span>
              </div>
            )}
          </div>
        )}
        onClose={() => {
          onClose();
        }}
      >
        <div>
          {userWorkspaces?.map((m) => (
            <div
              key={m.id}
              className={clsx(
                "is-flex is-clickable has-rounded-corners",
                styles.botItem,
                {
                  [styles.botSelected]: m.id === selectedWorkspace?.id,
                }
              )}
              onClick={() => handleWorkspaceSelect(String(m.id))}
            >
              <figure className={clsx("is-flex-shrink-0", { "mr-3": true })}>
                <p
                  className="image is-square"
                  style={{ width: 18, height: 18 }}
                >
                  <FallbackImage
                    className="is-rounded"
                    src={getImage(m.logo)}
                    fallback="/assets/bot.png"
                    alt={m.companyName || "workspace"}
                  />
                </p>
              </figure>
              {m.companyName}
            </div>
          ))}
        </div>
        <FullScreenLoader isOpen={isLoading} />
      </Modal>
    </>
  );
};

export default observer(WorkspaceSwitcherModal);
