import { observer } from "mobx-react-lite";
import { Modal } from "../../components";
import { useStore } from "../../stores";
import { toast } from "bulma-toast";
import { useState } from "react";
import { Upload } from "antd";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { deleteFiles, uploadFiles } from "../../stores/utils";
import locale from "../../constants/locale";
import { useTranslation } from "react-i18next";

type Props = {
  title?: string;
  open: boolean;
  onClose: () => void;
  onChange?: (file: string) => Promise<void>;
  successMessage?: string;
};

export interface UploadFileInterface {
  file: RcFile;
  onSuccess: (response: any, file: File) => void;
  onError: (error: any) => void;
}

const AvatarModal = ({
  title,
  open,
  onClose,
  onChange,
  successMessage = "Avatar upload successfully",
}: Props) => {
  const { selectedWorkspace } = useStore("workspaces");
  const { updateAvatar } = useStore("auth");
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const uploadButton = (
    <div>
      +<div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = (info: UploadChangeParam) => {
    if (handleBeforeUpload(info.file)) {
      setFileList(info.fileList);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    const { response } = file;
    window.open(response, "_blank");
  };

  const handleUpload = async ({
    file,
    onSuccess,
    onError,
  }: UploadFileInterface) => {
    try {
      const links = await uploadFiles([file]);
      if (onChange) {
        await onChange(links[0]);
      } else {
        await updateAvatar(links[0]);
      }

      toast({
        message: successMessage,
        position: "top-right",
        dismissible: true,
        type: "is-success",
        pauseOnHover: true,
      });

      onSuccess(links[0], file);
    } catch (errors) {
      onError(errors);
    }
  };

  const handleBeforeUpload = (file: UploadFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toast({
        message: "You can only upload JPG/PNG file!",
        position: "top-right",
        dismissible: true,
        type: "is-danger",
        pauseOnHover: true,
      });
    }
    const isLt2M = (file?.size || 1) / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast({
        message: "Image must smaller than 2MB!",
        position: "top-right",
        dismissible: true,
        type: "is-danger",
        pauseOnHover: true,
      });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleRemoveFile = async (file: any) => {
    try {
      await deleteFiles([file.response]);
      setFileList([]);
    } catch (error) {
      console.error(error);
      toast({
        message: "Something went wrong",
        position: "top-right",
        dismissible: true,
        type: "is-danger",
        pauseOnHover: true,
      });
    }
  };

  if (!selectedWorkspace) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={open}
        title={title ?? t(locale.editAvatar)}
        onClose={() => {
          onClose();
        }}
      >
        <div className="has-text-centered">
          <Upload
            action=""
            customRequest={handleUpload as any}
            listType="picture-circle"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            onRemove={handleRemoveFile as any}
            maxCount={1}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </div>
      </Modal>
    </>
  );
};

export default observer(AvatarModal);
