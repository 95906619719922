import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useField, FieldConfig } from "formik";

import strings from "../../constants/locale";

import { DeliveryZone, Location } from "../../models";
import { useStore } from "../../stores";
import { lightOrDark, makeRandomColor } from "../../utils/color";

import MapCoverage from "./MapCoverage";
import useGoogleMapsLib from "../../hooks/useGoogleMapsLib";

export const DeliveryCoverage = (
  props: FieldConfig<DeliveryZone[]> & {
    children?: never;
    disabled?: boolean;
    label?: string;
    tooltip?: string;
    mapCenter?: Location;
  }
) => {
  const { t } = useTranslation();
  const [, { value, touched, error }, { setValue }] = useField(props);
  const { selectedRestaurant } = useStore("restaurants");
  const [colors] = useState(new Map<DeliveryZone, string>());
  const { ready } = useGoogleMapsLib();

  const handleTimeChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(
        value.map((m, i) => {
          if (i === index) {
            m.time = Number(event.currentTarget.value);
          }

          return m;
        })
      );
  const handlePriceChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(
        value.map((m, i) => {
          if (i === index) {
            m.price = Number(event.currentTarget.value);
          }

          return m;
        })
      );
  const handleDistanceChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(
        value.map((m, i) => {
          if (i === index) {
            m.radius = Number(event.currentTarget.value);
          }

          return m;
        })
      );
  const handleRadiusChange = (index: number, r: number) =>
    setValue(
      value.map((m, i) => {
        if (i === index) {
          m.radius = r;
        }

        return m;
      })
    );
  const handleAdd = () =>
    setValue([
      ...value,
      {
        time: 0,
        price: 0,
        radius:
          (value.length && (value[0].radius || 0) * (value.length + 1)) || 2000,
      },
    ]);
  const handleRemove = (index: number) => () =>
    setValue(value.filter((_, i) => i !== index));

  useEffect(() => {
    value.forEach((v) => {
      if (colors.has(v)) {
        return;
      }

      const generated = makeRandomColor();
      colors.set(v, generated);
    });
  }, [colors, value]);

  return ready ? (
    <div className="field">
      <label className="label">
        {props.label}
        {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
      </label>
      <MapCoverage
        defaultLocation={props.mapCenter}
        zones={value}
        colors={colors}
        onRadiusChange={handleRadiusChange}
      />
      <table className="table is-bordered is-fullwidth mt-2">
        <thead>
          <tr>
            <th>{t(strings.zone)}</th>
            <th>
              {t(strings.deliveryTime)} ({t(strings.minutes)})
            </th>
            <th>{t(strings.distance)}</th>
            <th>
              {t(strings.price)} ({selectedRestaurant?.currency})
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {value.map((c, i) => (
            <tr key={i}>
              <td
                style={{
                  verticalAlign: "middle",
                  textAlign: "center",
                  backgroundColor: colors.get(c),
                  fontWeight: "bolder",
                  color:
                    lightOrDark(colors.get(c)) === "dark" ? "white" : "black",
                }}
              >
                {i + 1}
              </td>
              <td>
                <input
                  className="input"
                  type="number"
                  onChange={handleTimeChange(i)}
                  value={c.time?.toString() || ""}
                />
                {touched && Array.isArray(error) && error[i]?.time && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.time}</p>
                  </>
                )}
              </td>
              <td>
                <input
                  className="input"
                  type="number"
                  onChange={handleDistanceChange(i)}
                  value={c.radius?.toFixed(0) || ""}
                />
                {touched && Array.isArray(error) && error[i]?.radius && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.radius}</p>
                  </>
                )}
              </td>
              <td>
                <input
                  className="input"
                  type="number"
                  onChange={handlePriceChange(i)}
                  value={c.price?.toString() || ""}
                />
                {touched && Array.isArray(error) && error[i]?.price && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.price}</p>
                  </>
                )}
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <button
                  className="delete"
                  type="button"
                  onClick={handleRemove(i)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={5}>
              <button
                type="button"
                className="button is-light is-rounded"
                onClick={handleAdd}
              >
                {t(strings.add)}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {touched && !!error && !Array.isArray(error) && (
        <p className="help is-danger">{error}</p>
      )}
    </div>
  ) : null;
};
