import { makeAutoObservable } from "mobx";
import { create, persist } from "mobx-persist";
import firebase from "firebase/compat/app";
import { DateTime } from "luxon";

import { AnalyticsKpi, getMetricId, Metric, MetricData } from "../models";

import {
  createKpi,
  destroyKpi,
  getKpiNames,
  getKpis,
  getMetric,
  updateKpi,
} from "../services/chathub";

import bots from "./bots";
import audit from "./audit";
import auth from "./auth";

class AnalyticsStore {
  @persist dateFrom: string;
  @persist dateTo: string;
  @persist agent?: string;
  @persist currentBot?: number = 0;
  @persist("list") metrics: Metric[] = [];
  @persist("map") metricsData: Map<string, MetricData[]> = new Map();

  kpisLoading = false;
  kpiNames: AnalyticsKpi[] = [];
  kpis: AnalyticsKpi[] = [];

  private db: firebase.firestore.Firestore;

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
    this.dateFrom = DateTime.local()
      .set({
        day: 1,
        hour: 0,
        minute: 0,
        second: 0,
      })
      .toISO();
    this.dateTo = DateTime.local().toISO();
  }

  resetData = () => {
    this.dateFrom = DateTime.local()
      .set({
        day: 1,
        hour: 0,
        minute: 0,
        second: 0,
      })
      .toISO();
    this.dateTo = DateTime.local().toISO();
    this.agent = undefined;
    this.clearMetricsData();
  };

  reloadAllMetrics = async () => {
    if (!bots.selectedBot) {
      return;
    }

    const data = await this.db
      .collection("bots")
      .doc(`${bots.selectedBot.id}`)
      .collection("metrics")
      .get();

    this.setMetrics(data.docs.map((f) => f.data() as Metric));

    if (bots.selectedBot.id !== this.currentBot) {
      this.resetData();
    }

    this.currentBot = bots.selectedBot.id;

    if (this.agent) {
      this.agent = undefined;
      this.clearMetricsData();
    }
  };

  addMetric = async (metric: Metric) => {
    const id = getMetricId(metric);
    if (!bots.selectedBot || this.metrics.some((s) => getMetricId(s) === id)) {
      return;
    }

    this.setMetrics([...this.metrics, metric]);

    const metricId = getMetricId(metric);

    await this.db
      .collection("bots")
      .doc(`${bots.selectedBot.id}`)
      .collection("metrics")
      .doc(metricId)
      .set(metric);

    audit.logEvent("metric_added", {
      id: metricId,
      doc: metric,
      botId: bots.selectedBot.id,
    });
  };

  removeMetric = async (metric: Metric) => {
    const metricId = getMetricId(metric);

    if (
      !bots.selectedBot ||
      this.metrics.every((s) => getMetricId(s) !== metricId)
    ) {
      return;
    }

    this.setMetrics(this.metrics.filter((f) => getMetricId(f) !== metricId));

    await this.db
      .collection("bots")
      .doc(`${bots.selectedBot.id}`)
      .collection("metrics")
      .doc(metricId)
      .delete();

    this.deleteMetricData(metricId);

    audit.logEvent("metric_deleted", {
      id: metricId,
      doc: metric,
      botId: bots.selectedBot.id,
    });
  };

  fetchMetric = async ({ name, channel, params }: Metric) => {
    if (!bots.selectedBot) {
      return;
    }

    const response = await getMetric(
      bots.selectedBot.id,
      name,
      channel,
      this.dateFrom,
      this.dateTo,
      this.agent ? [this.agent] : undefined,
      undefined,
      params
    );

    this.setMetricData(getMetricId({ name, channel, params }), response);

    return response;
  };
  setDateRange = (from: Date, to: Date) => {
    if (!bots.selectedBot) {
      return;
    }

    const dateTimeFrom = DateTime.fromJSDate(from)
      .setZone(bots.selectedBot.settings.timezone, {
        keepLocalTime: true,
      })
      .startOf("day")
      .toUTC()
      .toISO();

    const dateTimeTo = DateTime.fromJSDate(to)
      .setZone(bots.selectedBot.settings.timezone, {
        keepLocalTime: true,
      })
      .endOf("day")
      .toUTC()
      .toISO();

    if (this.dateFrom === dateTimeFrom && this.dateTo === dateTimeTo) {
      return;
    }

    this.dateFrom = dateTimeFrom;
    this.dateTo = dateTimeTo;

    this.clearMetricsData();
  };

  loadKpiNames = async () => {
    if (!bots.selectedBot) {
      return;
    }

    try {
      const kpiNames = await getKpiNames(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password
      );

      this.kpiNames = kpiNames;
    } catch (error) {
      console.log(error);
    }
  };

  loadKpis = async (filters?: any) => {
    if (!bots.selectedBot) {
      return;
    }

    this.kpisLoading = true;

    try {
      const kpis = await getKpis(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        filters
      );
      this.kpis = kpis;
    } catch (error) {
      console.log(error);
    } finally {
      this.kpisLoading = false;
    }
  };

  updateOrCreateKpi = async (kpi: AnalyticsKpi) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    if (kpi?.id) {
      await updateKpi(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        kpi.id,
        kpi.target
      );
    } else {
      await createKpi(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        kpi.name,
        auth.user.id,
        kpi.target,
        kpi.symbol
      );
    }
  };

  deleteKpi = async (kpi: AnalyticsKpi) => {
    if (!bots.selectedBot) {
      return;
    }

    if (kpi?.id) {
      await destroyKpi(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        kpi.id
      );

      const index = this.kpis.findIndex((f) => f.id === kpi.id);

      if (index > -1) {
        this.kpis.splice(index, 1);
      }
    }
  };

  setAgent = (agent?: string) => {
    if (this.agent === agent) {
      return;
    }

    this.agent = agent;
    this.clearMetricsData();
  };

  private setMetrics = (metrics: Metric[]) => {
    this.metrics = metrics;
  };

  private clearMetricsData = () => {
    this.metricsData.clear();
  };

  private deleteMetricData = (key: string) => {
    this.metricsData.delete(key);
  };

  private setMetricData = (key: string, value: MetricData[]) => {
    this.metricsData.set(key, value);
  };
}

const analytics = new AnalyticsStore();

export default analytics;

create()("analytics", analytics);
