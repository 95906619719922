import clsx from "clsx";
import { useField, FieldConfig, useFormikContext } from "formik";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";

export const DatePicker = ({
  children,
  ...props
}: FieldConfig<Date | Date[]> & {
  disabled?: boolean;
  label?: string;
  isRange?: boolean;
  minDate?: Date;
  dateFormat?: string;
  maxDate?: Date;
  isHorizontal?: boolean;
  inputClass?: string;
  wrapperClassName?: string;
  labelClass?: string;
  showTimeInput?: boolean;
  placeholder?: string;
  noControl?: boolean;
  onDatesChange?: (value: Date | Date[]) => void;
}) => {
  const [field, { value, touched, error }, { setValue }] = useField(props);
  const { submitCount } = useFormikContext();

  useEffect(() => {
    if (!props.onDatesChange) {
      return;
    }

    props.onDatesChange(value);
  }, [props, value]);

  const isTouched = touched || submitCount > 0;

  const labelHtml = (
    <label className={"label " + props.labelClass}>{props.label}</label>
  );
  const input = (
    <>
      <div
        className={clsx({
          control: !props.noControl,
          "is-flex is-align-items-center": props.isRange,
        })}
      >
        <ReactDatePicker
          wrapperClassName={props.wrapperClassName}
          className={props.inputClass + " input"}
          disabled={props.disabled}
          selected={props.isRange ? (value as Date[])?.[0] : (value as Date)}
          maxDate={props.maxDate}
          minDate={props.minDate}
          placeholderText={props.placeholder}
          dateFormat={props.dateFormat}
          showTimeInput={props.showTimeInput}
          onChange={(date) =>
            props.isRange
              ? setValue([date as any, (value as Date[])?.[1]])
              : setValue(date as any)
          }
          onBlur={field.onBlur}
          {...(props.isRange
            ? {
                startDate: (value as Date[])?.[0],
                endDate: (value as Date[])?.[1],
                selectsStart: true,
              }
            : {})}
        />
        {props.isRange && (
          <>
            <div className="mx-2">-</div>
            <ReactDatePicker
              wrapperClassName={props.wrapperClassName}
              className={props.inputClass + " input"}
              disabled={props.disabled}
              selected={(value as Date[])?.[1]}
              maxDate={props.maxDate}
              minDate={(value as Date[])?.[0]}
              startDate={(value as Date[])?.[0]}
              endDate={(value as Date[])?.[1]}
              popperPlacement="bottom-end"
              showTimeInput={props.showTimeInput}
              dateFormat={props.dateFormat}
              onChange={(date) =>
                setValue([(value as Date[])?.[0], date as any])
              }
              onBlur={field.onBlur}
            />
          </>
        )}
      </div>
      {isTouched && !!error && <p className="help is-danger">{error}</p>}
    </>
  );

  return (
    <div className={clsx("field", { "is-horizontal": props.isHorizontal })}>
      {!props.isHorizontal ? (
        <>
          {props.label && labelHtml}
          {input}
        </>
      ) : (
        <>
          <div className={"field-label is-normal"}>{labelHtml}</div>
          <div className="field-body">
            <div className="field">{input}</div>
          </div>
        </>
      )}
    </div>
  );
};
