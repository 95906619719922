import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components";
import { useStore } from "../../stores";
import locale from "../../constants/locale";
import { UserStatus, userStatusColorMap, userStatusMap } from "../../models";
import clsx from "clsx";
import styles from "./UpdateUserStatus.module.scss";
import { toast } from "bulma-toast";
import { useState } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
};

const UpdateUserStatus = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { user, updateStatus } = useStore("auth");
  const [statusLoading, setStatusLoading] = useState(false);

  const onStatusChange = async (status: string) => {
    setStatusLoading(true);

    try {
      await updateStatus(status as UserStatus);

      toast({
        message: t(locale.statusUpdated),
        position: "top-right",
        dismissible: true,
        type: "is-success",
        pauseOnHover: true,
      });

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      if (error instanceof Error) {
        toast({
          message: error.message,
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
      console.log(error);
    } finally {
      setStatusLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={open}
        title={t(locale.updateStatus)}
        onClose={() => {
          onClose();
        }}
      >
        <div
          className={clsx({
            [styles.disabled]: statusLoading,
          })}
        >
          {Object.keys(userStatusMap).map((status) => (
            <div
              key={status}
              onClick={() => onStatusChange(status)}
              className={clsx(
                styles.status,
                "has-border my-2 has-rounded-corners is-flex p-2",
                {
                  [styles.statusIsActive]: status === user?.status,
                }
              )}
            >
              <div
                className={clsx("mr-2")}
                style={{
                  borderRadius: "50%",
                  background: userStatusColorMap[status as UserStatus],
                  height: "1.2rem",
                  width: "1.2rem",
                }}
              />
              <span className="subtitle is-6 has-text-weight-medium">
                {userStatusMap[status as UserStatus]}
              </span>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default observer(UpdateUserStatus);
