const originalConsoleError = console.error;

export const customError = (...args: (string | string[])[]) => {
  try {
    if (
      args?.[0] &&
      args?.[0]?.includes("useLayoutEffect does nothing on the server")
    ) {
      //only for Mentionify text parsing in ActionDropdown
      return;
    }
  } catch (error) {}
  originalConsoleError.apply(console, args);
};
