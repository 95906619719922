import React from "react";
export function ComponentLoader(loader: () => Promise<any>) {
  const Lazy = React.lazy(loader);

  return () => (
    <React.Suspense fallback={null}>
      <Lazy />
    </React.Suspense>
  );
}
