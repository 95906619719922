import { Chat } from "./Chat";
import { DateTime } from "luxon";
import { TicketComment } from "./Ticket";

export type BoardItemType = "contact" | "card";

export type BoardItemHistory = {
  createdBy?: string;
  currentStageId: string;
  newStageId?: string;
  addedAt: DateTime;
  removedAt?: DateTime;
};

export type CustomField = {
  name: string;
  value?: any;
  type: "text" | "number" | "date";
};

export type BoardItemComment = {
  id: number;
  userId: string;
  comment: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  notified?: boolean;
};

export type BoardItemCard = {
  id: number;
  uuid: string;
  boardId?: number;
  columnId?: number;
  name: string;
  subscriberId: number;
  dueDate: DateTime;
  assigneeId: string;
  opportunity: number;
  createdAt: DateTime;
  updatedAt: DateTime;
  openedAt?: DateTime;
  closedAt?: DateTime;
  closedLostReason?: string;
  histories?: BoardItemHistory[];
  comments?: TicketComment[];
  customFields?: CustomField[];
  subscriber?: Chat;
};

export type BoardItem = {
  id: string;
  type: BoardItemType;
} & (
  | BoardItemCard
  | {
      type: "contact";
    }
);

export type BoardColumn = {
  id: number;
  uuid: string;
  name: string;
  type: BoardColummType;
  probability: number;
  forecastCategory: ForecastCategory;
  noOfItems: number;
  sumOfOpportunity: number;
  description?: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  items?: BoardItemCard[];
};

export type Board = {
  id: string;
  uuid: string;
  name: string;
  columns?: BoardColumn[];
  createdAt: DateTime;
  updatedAt: DateTime;
  deletedAt?: DateTime;
};

export enum BoardColummType {
  Open = "open",
  ClosedWon = "closed-won",
  ClosedLost = "closed-lost",
}

export const boardColumnTypeMap: Record<BoardColummType, string> = {
  [BoardColummType.Open]: "Open",
  [BoardColummType.ClosedWon]: "Closed Won",
  [BoardColummType.ClosedLost]: "Closed Lost",
};

export enum ForecastCategory {
  Omitted = "omitted",
  Pipeline = "pipeline",
  BestCase = "best-case",
  Commit = "commit",
  Closed = "closed",
}

export const forecastCategoryProbabilityMap: Record<ForecastCategory, number> =
  {
    [ForecastCategory.Omitted]: 0,
    [ForecastCategory.Pipeline]: 25,
    [ForecastCategory.BestCase]: 70,
    [ForecastCategory.Commit]: 90,
    [ForecastCategory.Closed]: 0,
  };

export const forecastCategoryMap: Record<ForecastCategory, string> = {
  [ForecastCategory.Omitted]: "Omitted",
  [ForecastCategory.Pipeline]: "Pipeline",
  [ForecastCategory.BestCase]: "Best Case",
  [ForecastCategory.Commit]: "Commit",
  [ForecastCategory.Closed]: "Closed",
};
