import clsx from "clsx";
import { FieldConfig, useField } from "formik";
import { TFunctionResult } from "i18next";
import { ChangeEvent, FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import locale from "../../constants/locale";

type Props = {
  label?: React.ReactChild | TFunctionResult;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  useTextArea?: boolean;
  tooltip?: string;
  className?: string;
  static?: string[];
  options?: string[]
} & FieldConfig<string[]>;

export const ListInput: FC<Props> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const [field, meta, { setValue }] = useField(props);
  const label = typeof props.label === "function" ? props.label() : props.label;
  const inputProps = (i: number) => ({
    name: field.name,
    onBlur: field.onBlur,
    className: clsx("input", props.className, {
      "is-danger": meta.touched && meta.error?.[i],
    }),
    type: props.type,
    placeholder: props.placeholder,
    disabled: props.disabled,
    readOnly: props.readOnly,
    tooltip: props.tooltip,
  });

  const labelHtml = !!label && (
    <label className="label has-text-left">
      {label}
      {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
    </label>
  );

  const handleChange =
    (i: number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(
        field.value.map((m, x) => (i === x ? e.currentTarget.value : m))
      );
    };

  const handelRemove = (i: number) => () => {
    const d = [...field.value];
    d.splice(i, 1);
    setValue(d);
  };

  const handelAdd = () => {
    setValue([...field.value, ""]);
  };

  return (
    <>
      <div className="field-label is-normal">{labelHtml}</div>
      <div className="is-flex-direction-column">
        {field.value.map((m, i) => (
          <Fragment key={i}>
            <div className="field mb-0">
              <div
                className={clsx("control", {
                  "has-icons-right": !props.static?.includes(m),
                })}
              >
                {props.useTextArea ? (
                  <textarea
                    ref={props.innerRef}
                    {...inputProps(i)}
                    readOnly={
                      props.static?.includes(m) || inputProps(i).readOnly
                    }
                    value={m}
                    onChange={handleChange(i)}
                  />
                ) : (
                  <>

                    <input
                      style={{ marginRight: 0 }}
                      ref={props.innerRef}
                      {...inputProps(i)}
                      readOnly={
                        props.static?.includes(m) || inputProps(i).readOnly
                      }
                      value={m}
                      onChange={handleChange(i)}
                      list={inputProps.name + "List"}
                    />
                    {props.options && (
                      <datalist id={inputProps.name + "List"}>
                        {props.options.map(option => <option key={option} value={option} />)}
                      </datalist>
                    )}
                  </>
                )}
                {!props.static?.includes(m) && !inputProps(i).readOnly && (
                  <div
                    className="icon is-small is-right is-clickable"
                    onClick={handelRemove(i)}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                )}
              </div>
              {meta.touched && !!meta.error?.[i] && (
                <p className="help is-danger">{meta.error[i]}</p>
              )}
            </div>
            <br />
          </Fragment>
        ))}
        {!props.readOnly && (
          <button
            type="button"
            onClick={handelAdd}
            className="button custom-button has-text-weight-medium has-background-light w-100"
          >
            <span className="icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1V13" stroke="#2A79EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1 7H13" stroke="#2A79EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span>{t(locale.add)}</span>
          </button>
        )}
      </div>
    </>
  );
};
