import auth from "./auth";
import workspaces from "./workspaces";
import chats from "./chats";
import bots from "./bots";
import audit from "./audit";
import settings from "./settings";
import users from "./users";
import analytics from "./analytics";
import stream from "./stream";
import campaigns from "./campaigns";
import boards from "./boards";
import billing from "./billing";
import ednaChats from "./ednaChats";
import ednaThreadChats from "./ednaThreadChats";
import orgKnowledge from "./orgKnowledge";
import aITeams from "./aITeams";
import helpCenterUserView from "./helpCenterUserView";
import restaurants from "./restaurants";
import menu from "./menu";
import orders from "./orders";
import workflows from "./workflows";
import calendar from "./calendar";
import notifications from "./notifications";
import integrations from "./integrations";
import realTimeEvents from "./realTimeEvents";

const stores = {
  auth,
  workspaces,
  settings,
  chats,
  bots,
  audit,
  users,
  analytics,
  stream,
  campaigns,
  boards,
  billing,
  ednaChats,
  ednaThreadChats,
  orgKnowledge,
  aITeams,
  helpCenterUserView,
  restaurants,
  menu,
  orders,
  workflows,
  calendar,
  notifications,
  integrations,
  realTimeEvents,
};

export function useStore<T extends keyof typeof stores>(
  name: T
): (typeof stores)[T] {
  return stores[name];
}
