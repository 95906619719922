import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import workspaces from "./workspaces";
import firebaseApp from "firebase/compat/app";
import { AITeam, EdnaChatAITeamTask } from "../models/AITeam";
import { getAITeams } from "../services/chathub";
import auth from "./auth";
import { EdnaMessage } from "../models/EdnaChat";

class AITeamStore {
  private db: firebase.firestore.Firestore;
  aITeams: AITeam[] = [];
  backendAITeams: any[] = [];
  selectedAITeam?: AITeam;
  tasks: EdnaChatAITeamTask[] = [];
  taskMessages: EdnaMessage[] = [];

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  updateOrCreateAITeam = async (aITeam: AITeam) => {
    if (!workspaces.selectedWorkspace || !auth.user) {
      return;
    }

    if (aITeam?.id) {
      await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("aITeams")
        .doc(aITeam.id)
        .set(
          {
            name: aITeam.name,
            function: aITeam.function,
            agentNames: aITeam.agentNames,
            updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );

      const index = this.aITeams.findIndex((f) => f.id === aITeam.id);

      if (index > -1) {
        this.aITeams[index] = {
          ...this.aITeams[index],
          name: aITeam.name,
          function: aITeam.function,
          agentNames: aITeam.agentNames,
        };
      }

      if (this.selectedAITeam?.id === aITeam.id) {
        this.selectAITeam(aITeam);
      }
    } else {
      const aITeamObj: AITeam = {
        createdByUserId: auth.user?.id,
        name: aITeam.name,
        function: aITeam.function,
        proxyAgentName: aITeam.proxyAgentName,
        createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      };

      const docRef = await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("aITeams")
        .add(aITeamObj);

      const newAITeam = (await docRef.get()).data() as AITeam;
      newAITeam.id = docRef.id;
      newAITeam.teamInfo = this.backendAITeams.find(
        (f) => f.name === newAITeam.name
      );
      this.aITeams.unshift(newAITeam);
    }
  };

  deleteAITeam = async (aITeam: AITeam) => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    if (aITeam?.id) {
      // await this.deleteAllDocumentsOfAAITeam(aITeam.id)

      await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("aITeams")
        .doc(aITeam.id)
        .delete();

      const index = this.aITeams.findIndex((f) => f.id === aITeam.id);

      if (index > -1) {
        this.aITeams.splice(index, 1);
      }
    }
  };

  loadAITeams = async () => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    await this.loadBackendAITeams();

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("aITeams")
      .orderBy("createdAt", "desc")
      .get();

    const aITeams = snapshot.docs.map((doc) => {
      const obj = doc.data() as AITeam;
      obj.id = doc.id;
      return obj;
    }) as AITeam[];

    this.aITeams = aITeams.map((team) => {
      const teamInfo = this.backendAITeams.find((f) => f.name === team.name);
      if (teamInfo) {
        team.teamInfo = teamInfo;
      }
      return team;
    });
  };

  loadTasks = async () => {
    if (!workspaces.selectedWorkspace || !this.selectedAITeam) {
      return;
    }

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChatAITeamTasks")
      .where("aITeamId", "==", this.selectedAITeam?.id)
      .orderBy("createdAt", "asc")
      .get();

    const tasks = snapshot.docs.map((doc) => {
      const obj = doc.data() as EdnaChatAITeamTask;
      obj.id = doc.id;
      return obj;
    }) as EdnaChatAITeamTask[];

    this.setTasks(tasks);
  };

  loadTaskMessages = async (chatId: string, taskId: string) => {
    if (!workspaces.selectedWorkspace || !this.selectedAITeam) {
      return;
    }
    this.setTaskMessages([]);

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(chatId)
      .collection("messages")
      .where("aITeamTaskId", "==", taskId)
      .orderBy("createdAt", "desc")
      .get();

    const tasks = snapshot.docs.map((doc) => {
      const obj = doc.data() as EdnaMessage;
      obj.id = doc.id;
      return obj;
    }) as EdnaMessage[];

    this.setTaskMessages(tasks);
  };

  loadBackendAITeams = async () => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    const response = await getAITeams(workspaces.selectedWorkspace.id);

    this.backendAITeams = response;
  };

  selectAITeam = (aITeam: AITeam) => {
    this.selectedAITeam = aITeam;
  };

  private setTasks(tasks: EdnaChatAITeamTask[]) {
    this.tasks = tasks;
  }

  private setTaskMessages(messages: EdnaMessage[]) {
    this.taskMessages = messages;
  }
}

export default new AITeamStore();
