import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";
import { Loader } from "../loader/Loader";

const modalRoot = document.getElementById("root");

type Props = {
  isOpen: boolean;
};

export const FullScreenLoader = ({ isOpen }: Props) => {
  const container = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    modalRoot?.appendChild(container);
    return () => {
      modalRoot?.removeChild(container);
    };
  }, [container]);

  const modal = (
    <div
      className={clsx("modal", {
        "is-active": isOpen,
      })}
    >
      <div className="modal-background" />
      <div className="modal-content is-flex is-justify-content-center">
        <Loader />
      </div>
    </div>
  );

  return createPortal(modal, container);
};
