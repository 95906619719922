import { HTMLProps } from "react";
import { TFunctionResult } from "i18next";
import clsx from "clsx";
import { useField, FieldConfig } from "formik";

type Props = {
  label?: React.ReactChild | TFunctionResult;
  icon?: string | (() => React.ReactChild);
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  useTextArea?: boolean;
  showCounter?: boolean;
  tooltip?: string;
  className?: string;
  isHorizontal?: boolean;
  inputProps?: HTMLProps<HTMLInputElement> | HTMLProps<HTMLTextAreaElement>;
  labelClass?: string;
  iconClass?: string;
  controlClass?: string;
  iconPosition?: string;
  fieldClassName?: string;
  errorClass?: string;
  extras?: React.ReactChild;
  options?: string[];
} & FieldConfig<string>;

export const Field = ({ children, ...props }: Props) => {
  const [field, meta] = useField(props);

  const iconPosition = props.iconPosition || "left";

  const label = typeof props.label === "function" ? props.label() : props.label;
  const renderCheckbox = () => (
    <div className={clsx(props.fieldClassName, "field")}>
      <div className="control">
        <label
          className={clsx("checkbox", props.labelClass, {
            "is-danger": meta.touched && meta.error,
          })}
        >
          <input
            type="checkbox"
            {...(props.inputProps as any)}
            {...field}
            disabled={props.disabled}
          />{" "}
          {label}
        </label>
      </div>

      {props.extras && props.extras}

      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
    </div>
  );

  const inputProps = {
    ...props.inputProps,
    ...field,
    className: clsx("input", props.className, {
      "is-danger": meta.touched && meta.error,
    }),
    type: props.type,
    placeholder: props.placeholder,
    disabled: props.disabled,
    readOnly: props.readOnly,
    tooltip: props.tooltip,
  };

  const labelHtml = !!label && (
    <label className={clsx("label", props.labelClass)}>
      {label}
      {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
    </label>
  );

  const input = (
    <>
      <div
        className={clsx(
          "control",
          {
            ["has-icons-" + iconPosition]: !!props.icon,
          },
          props.controlClass
        )}
      >
        {props.useTextArea ? (
          <textarea ref={props.innerRef} {...(inputProps as any)} />
        ) : (
          <input
            ref={props.innerRef}
            {...(inputProps as any)}
            list={inputProps.name + "List"}
          />
        )}

        {props.options && (
          <datalist id={inputProps.name + "List"}>
            {props.options.map((option) => (
              <option key={option} value={option} />
            ))}
          </datalist>
        )}

        {!!props.icon && (
          <span
            className={clsx(
              `icon is-small is-${iconPosition}`,
              props.iconClass
            )}
            style={{
              pointerEvents: typeof props.icon === "string" ? "none" : "all",
            }}
          >
            {typeof props.icon === "string" ? (
              <i className={clsx("fas", props.icon)}></i>
            ) : (
              props.icon()
            )}
          </span>
        )}
      </div>
      {meta.touched && !!meta.error && (
        <p className={clsx("help is-danger", props.errorClass)}>{meta.error}</p>
      )}

      {props.showCounter && (
        <p className="help has-text-right">
          {field?.value?.length || 0} / {props.inputProps?.maxLength || 0}
        </p>
      )}

      {props.extras && props.extras}
    </>
  );

  return props.type === "checkbox" ? (
    renderCheckbox()
  ) : (
    <div
      className={clsx("field", props.fieldClassName, {
        "is-horizontal": props.isHorizontal,
      })}
    >
      {!props.isHorizontal ? (
        <>
          {labelHtml}
          {input}
        </>
      ) : (
        <>
          <div className="field-label is-normal">{labelHtml}</div>
          <div className="field-body">
            <div className="field">{input}</div>
          </div>
        </>
      )}
    </div>
  );
};
