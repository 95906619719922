import styles from "../WorkspaceSidebar.module.scss";
import clsx from "clsx";
import { useStore } from "../../../stores";
import { Popover } from "react-tiny-popover";
import { useState } from "react";
import WorkspaceSwitcherModal from "./WorkspaceSwitcherModal";
import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import routes from "../../../constants/routes";

export default function WorkspaceMenuPopover() {
  const { selectedWorkspace } = useStore("workspaces");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  if (!selectedWorkspace) {
    return null;
  }

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={["right"]}
        align="end"
        containerStyle={{ zIndex: "9999" }}
        onClickOutside={() => setIsPopoverOpen(false)}
        content={() => (
          <WorkspaceMenuContent onClose={() => setIsPopoverOpen(false)} />
        )}
      >
        <button
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          className="button is-ghost is-no-box-shadow workspace-profile"
        >
          <img
            src={selectedWorkspace.logo || "/assets/avatar.svg"}
            style={{
              height: "3rem",
              width: "3rem",
              borderRadius: 7,
            }}
            alt=""
          />
        </button>
      </Popover>
    </>
  );
}

export const WorkspaceMenuContent = ({ onClose }: { onClose: () => void }) => {
  const auth = useStore("auth");
  const { selectedWorkspace } = useStore("workspaces");
  const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const routeTo = (path: string) => {
    history.push(path.replace(":workspaceId", String(selectedWorkspace?.id)));
    onClose();
  };

  const menus = [
    {
      icon: "/assets/workspaces.svg",
      text: locale.workspaces,
      onClick: () => {
        setIsWorkspaceModalOpen(true);
      },
      hasAccess: true,
    },
    {
      icon: "/assets/email-templates.svg",
      text: locale.emailTemplates,
      onClick: () => routeTo(routes.emailTemplate),
      hasAccess: true,
    },
    {
      icon: "/assets/wallet.svg",
      text: locale.billing,
      onClick: () => routeTo(routes.billing),
      hasAccess: false,
    },
    {
      icon: "/assets/users.svg",
      text: locale.users,
      onClick: () => routeTo(routes.users),
      hasAccess: auth.userHasAdminAccess,
    },
    {
      icon: "/assets/help-center.svg",
      text: locale.helpCenter,
      onClick: () => routeTo(routes.helpCenter),
      hasAccess: true,
    },
    {
      icon: "/assets/org-knowledge.svg",
      text: locale.orgKnowledge,
      onClick: () => routeTo(routes.orgKnowledge.replace("/:folderId?", "")),
      hasAccess: true,
    },
    {
      icon: "/assets/workspace-profile.svg",
      text: locale.workspaceProfile,
      onClick: () => routeTo(routes.workspaceSettings),
      hasAccess: auth.userHasAdminAccess,
    },
  ];

  return (
    <>
      <div className={clsx(styles.channelWrapper, "is-clickable")}>
        {menus
          .filter((f) => f.hasAccess)
          .map((menu, i) => {
            return (
              <div
                key={i}
                onClick={menu.onClick}
                className={clsx(
                  "is-clickable is-flex is-align-items-center",
                  styles.channelName
                )}
              >
                <span className={clsx("icon mr-2", styles.icon)}>
                  <img src={menu.icon} alt={menu.text} width={20} />
                </span>
                <span>{t(menu.text)}</span>
              </div>
            );
          })}
      </div>

      <WorkspaceSwitcherModal
        open={isWorkspaceModalOpen}
        onClose={() => setIsWorkspaceModalOpen(false)}
      />
    </>
  );
};
