import firebase from "firebase/compat/app";

export const logError = (error: any, extra?: any) => {
  if (process.env.NODE_ENV === "development") {
    console.error(error, extra);
  } else {
    firebase.analytics().logEvent("exception", {
      description: (typeof error === "string"
        ? error
        : error.message
      ).substring(0, 150),
      fatal: true,
    });
  }
};
