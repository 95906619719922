import styles from "./WorkspaceSidebar.module.scss";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores";
import { useHistory, useLocation } from "react-router";
import routes from "../../constants/routes";
import MorePopover from "./components/MorePopover";
import DMsLink from "./components/DMsLink";
import WorkspaceMenuPopover from "./components/WorkspaceMenuPopover";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import WorkspaceFunctions from "./components/WorkspaceFunctions";
import AddNew from "./components/AddNew";
import CalendarLink from "./components/CalendarLink";
import ActivityLink from "./components/ActivityLink";

const WorkspaceSidebar = observer(
  ({ checkMobile = true }: { checkMobile?: boolean }) => {
    const { selectedWorkspace, workspaceLoaded } = useStore("workspaces");
    const history = useHistory();
    const location = useLocation();
    const isMobile = useMobileResponsive();

    const routeTo = (path: string) => {
      history.push(path.replace(":workspaceId", String(selectedWorkspace?.id)));
    };

    const isHelpCenterUserViewPage = location.pathname.includes(
      routes.helpCenterUserViewHome.replace(":workspaceId", "")
    );

    const isBookingPage = location.pathname.includes(
      routes.booking
        .replace("/:bookingWorkspaceId", "")
        .replace("/:eventId", "")
    );

    if (
      (checkMobile && isMobile) ||
      !selectedWorkspace ||
      !workspaceLoaded ||
      isHelpCenterUserViewPage ||
      isBookingPage
    ) {
      return null;
    }

    return (
      <div
        className={clsx(
          "has-background-primary is-align-items-center is-flex is-flex-direction-column is-justify-content-space-between py-4",
          styles.h100
        )}
      >
        <div>
          <div className="w-100 is-flex is-justify-content-center mb-3">
            <img
              onClick={() => routeTo(routes.workspaceHome)}
              className={clsx("is-clickable", styles.logo)}
              src="/assets/edna-sidebar-logo.png"
              alt="logo"
              width={50}
            />
          </div>

          <DMsLink />
          <WorkspaceFunctions />
          <CalendarLink />
          <ActivityLink />
          <MorePopover />
        </div>
        <div
          className="is-flex is-flex-direction-column w-100"
          style={{ gap: "1rem" }}
        >
          <AddNew />
          <WorkspaceMenuPopover />
        </div>
      </div>
    );
  }
);

export default WorkspaceSidebar;
