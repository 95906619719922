import firebase from "firebase/compat/app";
import locale from "../constants/locale";

export enum TicketStatus {
  TODO = "todo",
  IN_PROGRESS = "in-progress",
  DONE = "done",
  CLOSED = "closed",
}

export const ticketStatusMap: Record<TicketStatus, string> = {
  [TicketStatus.TODO]: locale.taskStatusTodo,
  [TicketStatus.IN_PROGRESS]: locale.taskStatusInProgress,
  [TicketStatus.DONE]: locale.taskStatusDone,
  [TicketStatus.CLOSED]: locale.closed,
};

export const ticketStatusColorMap: Record<TicketStatus, string> = {
  [TicketStatus.TODO]: "#3d3d3d",
  [TicketStatus.IN_PROGRESS]: "#ffc61d",
  [TicketStatus.DONE]: "#27AE60",
  [TicketStatus.CLOSED]: "#ce2c2c",
};

export enum TicketType {
  TICKET = "ticket",
  TASK = "task",
}

export const typeTypeColorMap: Record<TicketType, string> = {
  [TicketType.TASK]: "#0CC0DF",
  [TicketType.TICKET]: "#E4007F",
};

export const ticketTypeMap: Record<TicketType, string> = {
  [TicketType.TASK]: locale.task,
  [TicketType.TICKET]: locale.ticket,
};

export enum TicketRepeatFrequency {
  NONE = "none",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export const ticketRepeatMap: Record<TicketRepeatFrequency, string> = {
  [TicketRepeatFrequency.NONE]: "None",
  [TicketRepeatFrequency.DAILY]: locale.daily,
  [TicketRepeatFrequency.WEEKLY]: locale.weekly,
  [TicketRepeatFrequency.MONTHLY]: locale.monthly,
  [TicketRepeatFrequency.YEARLY]: locale.yearly,
};

export enum TicketRepeatDailyOption {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export const ticketRepeatDailyOptionMap: Record<
  TicketRepeatDailyOption,
  string
> = {
  [TicketRepeatDailyOption.SUNDAY]: locale.sunday,
  [TicketRepeatDailyOption.MONDAY]: locale.monday,
  [TicketRepeatDailyOption.TUESDAY]: locale.tuesday,
  [TicketRepeatDailyOption.WEDNESDAY]: locale.wednesday,
  [TicketRepeatDailyOption.THURSDAY]: locale.thursday,
  [TicketRepeatDailyOption.FRIDAY]: locale.friday,
  [TicketRepeatDailyOption.SATURDAY]: locale.saturday,
};

export enum TicketRepeatMonthlyOption {
  FIRST = 1,
  MID = 15,
  LAST = -1,
}

export const ticketRepeatMonthlyOptionMap: Record<
  TicketRepeatMonthlyOption,
  string
> = {
  [TicketRepeatMonthlyOption.FIRST]: "First day",
  [TicketRepeatMonthlyOption.MID]: "15th day",
  [TicketRepeatMonthlyOption.LAST]: "Last day",
};

export enum TicketRepeatYearlyOption {
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}

export const ticketRepeatYearlyOptionMap: Record<
  TicketRepeatYearlyOption,
  string
> = {
  [TicketRepeatYearlyOption.JANUARY]: "January",
  [TicketRepeatYearlyOption.FEBRUARY]: "February",
  [TicketRepeatYearlyOption.MARCH]: "March",
  [TicketRepeatYearlyOption.APRIL]: "April",
  [TicketRepeatYearlyOption.MAY]: "May",
  [TicketRepeatYearlyOption.JUNE]: "June",
  [TicketRepeatYearlyOption.JULY]: "July",
  [TicketRepeatYearlyOption.AUGUST]: "August",
  [TicketRepeatYearlyOption.SEPTEMBER]: "September",
  [TicketRepeatYearlyOption.OCTOBER]: "October",
  [TicketRepeatYearlyOption.NOVEMBER]: "November",
  [TicketRepeatYearlyOption.DECEMBER]: "December",
};

export type Ticket = {
  id?: string;
  userId?: string;
  name: string;
  description: string;
  assigneeId: string;
  assigneeName?: string;
  dueDate: firebase.firestore.Timestamp;
  status: TicketStatus;
  type: TicketType;
  chatId?: number;
  liveCardId: number;
  repeatFrequency?: TicketRepeatFrequency;
  frequencyConfig?: number[];
  labels?: string[];
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  attachments: TicketAttachment[];
  comments: TicketComment[];
  histories?: TicketHistory[];
  meta: Record<string, any>;
};

export type TicketComment = {
  id: string;
  userId: string;
  comment: string;
  createdAt: Date;
  updatedAt: Date;
  notified?: boolean;
};

export type TicketAttachment = {
  uid: string;
  name: string;
  url: string;
  type: string;
  status?: string;
  createdAt: Date;
};

export type TicketHistory = {
  createdBy?: string;
  currentStatus: TicketStatus;
  newStatus?: TicketStatus;
  addedAt: Date;
  removedAt?: Date;
};
