import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import { Integration } from "../models";
import workspaces from "./workspaces";

class IntegrationStore {
  private db: firebase.firestore.Firestore;
  loading: boolean = true;
  integrations: Integration[] = [];

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  loadIntegrations = async () => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    this.loading = true;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(workspaces.selectedWorkspace.id)
      .collection("integrations")
      .orderBy("createdAt", "asc")
      .get();

    const integrations = snapshot.docs.map((doc) => {
      const data = doc.data();
      return { ...data, id: doc.id } as Integration;
    });
    this.setIntegrations(integrations);

    this.loading = false;
  };

  addIntegration = async (integration: Integration) => {
    if (!workspaces.selectedWorkspace) {
      return;
    }
    const ref = await this.db
      .collection("workspaces")
      .doc(workspaces.selectedWorkspace.id)
      .collection("integrations")
      .add(integration);

    integration = (await ref.get()).data() as Integration;
    integration.id = ref.id;

    this.setIntegrations([...this.integrations, integration]);
  };

  updateIntegrationStatus = async (integration: Integration) => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    await this.db
      .collection("workspaces")
      .doc(workspaces.selectedWorkspace.id)
      .collection("integrations")
      .doc(integration.id)
      .update(integration);

    const integrations = this.integrations.map((i) =>
      i.id === integration.id ? integration : i
    );

    this.setIntegrations(integrations);
  };

  deleteIntegration = async (integration: Integration) => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    await this.db
      .collection("workspaces")
      .doc(workspaces.selectedWorkspace.id)
      .collection("integrations")
      .doc(integration.id)
      .delete();

    const integrations = this.integrations.filter(
      (i) => i.id !== integration.id
    );
    this.setIntegrations(integrations);
  };

  private setIntegrations = (integrations: Integration[]) => {
    this.integrations = integrations;
  };
}

export default new IntegrationStore();
