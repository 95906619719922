import { ImgHTMLAttributes, useCallback, useRef, useState } from "react";

type Props = {
  fallback?: string;
  customFallback?: JSX.Element;
  renderer?: (element: JSX.Element) => JSX.Element;
} & ImgHTMLAttributes<HTMLImageElement>;

export const FallbackImage = ({
  fallback,
  customFallback,
  renderer,
  ...props
}: Props) => {
  const [didFail, setDidFail] = useState(false);
  const ref = useRef<HTMLImageElement>(null);
  const handleError = useCallback(() => {
    if (ref.current && fallback && ref.current.src !== fallback) {
      ref.current.src = fallback;
    }
    setDidFail(true);
  }, [fallback]);

  if (customFallback && (didFail || (!props.src && !fallback))) {
    return customFallback;
  }

  const element = (
    <img
      ref={ref}
      {...props}
      src={props.src || fallback}
      alt={props.alt}
      onError={handleError}
    />
  );

  if (renderer) {
    return renderer(element);
  }

  return element;
};
