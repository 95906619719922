import firebase from "firebase/compat/app";
import locale from "../constants/locale";
import { User } from "./User";
import { t } from "i18next";

export enum ChatChannel {
  FacebookMessenger = "messenger",
  TwilioWhatsApp = "twilio_wa",
  Wazzup = "wazzup",
  WebChat = "webchat",
  Telegram = "telegram",
  Instagram = "instagram",
  Teams = "msteams",
  Email = "email",
  ChatRoom = "chatroom",
  Lead = "lead",
  Other = "other",
}

export const channelMap: Record<ChatChannel, string> = {
  [ChatChannel.FacebookMessenger]: "Messenger",
  [ChatChannel.Telegram]: "Telegram",
  [ChatChannel.TwilioWhatsApp]: "WhatsApp",
  [ChatChannel.Wazzup]: "WhatsApp",
  [ChatChannel.WebChat]: "Web Chat",
  [ChatChannel.Instagram]: "Instagram",
  [ChatChannel.Teams]: "Teams",
  [ChatChannel.Email]: "Email",
  [ChatChannel.ChatRoom]: "Chatroom",
  [ChatChannel.Lead]: "Lead",
  [ChatChannel.Other]: "Other",
};

export const channelColorMap: Record<ChatChannel, string> = {
  [ChatChannel.FacebookMessenger]: "#2A79EF",
  [ChatChannel.Telegram]: "#FBBC05",
  [ChatChannel.TwilioWhatsApp]: "#55CD6C",
  [ChatChannel.Wazzup]: "#55CD6C",
  [ChatChannel.WebChat]: "#29D9FF",
  [ChatChannel.Instagram]: "#F56040",
  [ChatChannel.Teams]: "#8F56FC",
  [ChatChannel.Email]: "#8F56FC",
  [ChatChannel.ChatRoom]: "#8F56FC",
  [ChatChannel.Lead]: "#8F56FC",
  [ChatChannel.Other]: "#8F56FC",
};

export const channelMapping: Record<ChatChannel, string> = {
  [ChatChannel.FacebookMessenger]: "fab fa-facebook-messenger",
  [ChatChannel.TwilioWhatsApp]: "fab fa-whatsapp",
  [ChatChannel.Wazzup]: "fab fa-whatsapp",
  [ChatChannel.WebChat]: "fas fa-comment",
  [ChatChannel.Telegram]: "fab fa-telegram",
  [ChatChannel.Instagram]: "fab fa-instagram",
  [ChatChannel.Teams]: "fab fa-microsoft",
  [ChatChannel.Email]: "fab fa-email",
  [ChatChannel.ChatRoom]: "fas fa-comments",
  [ChatChannel.Lead]: "fas fa-comments",
  [ChatChannel.Other]: "fab fa-email",
};

export const channelMaxTextLength: Record<ChatChannel, number | undefined> = {
  [ChatChannel.FacebookMessenger]: undefined,
  [ChatChannel.Telegram]: undefined,
  [ChatChannel.TwilioWhatsApp]: 1600,
  [ChatChannel.Wazzup]: undefined,
  [ChatChannel.WebChat]: undefined,
  [ChatChannel.Instagram]: undefined,
  [ChatChannel.Teams]: undefined,
  [ChatChannel.Email]: undefined,
  [ChatChannel.ChatRoom]: undefined,
  [ChatChannel.Lead]: undefined,
  [ChatChannel.Other]: undefined,
};

export enum ChatPriority {
  HIGH = 0,
  MEDIUM = 1,
  LOW = 2,
}

export const chatPriorityMap: Record<ChatPriority, string> = {
  [ChatPriority.HIGH]: "high",
  [ChatPriority.MEDIUM]: "medium",
  [ChatPriority.LOW]: "low",
};

export const chatPriorityColorMap: Record<ChatPriority, string> = {
  [ChatPriority.HIGH]: "#ff2e00",
  [ChatPriority.MEDIUM]: "#ffab00",
  [ChatPriority.LOW]: "#0165ff",
};

export type ChatTab =
  | "myQueue"
  | "snoozed"
  | "connected"
  | "requested"
  | "all"
  | "live_streams"
  | "blocked"
  | "chatrooms";

export type RequestStatus =
  | "none"
  | "sent"
  | "accepted"
  | "rejected"
  | "completed"
  | "timed_out"
  | "canceled"
  | "agent_engaged";

export type ContactMerged = {
  id: number;
  channel: ChatChannel;
};

export type Chat = {
  id: number;
  image?: string;
  firstName: string;
  lastName?: string;
  channel?: ChatChannel;
  newMessagesCount: number;
  hasRequestedAgent: boolean;
  requestStatus: RequestStatus;
  isConnected: boolean;
  userConnected?: string;
  previousUserConnected?: string;
  disableVideoCall?: boolean;
  updatedAt: Date;
  lastMessage?: Date;
  contactCreatedAt?: Date;
  data?: { [key: string]: string };
  labels?: string[];
  archived?: boolean;
  starred?: boolean;
  isContact?: boolean;
  isBlocked?: boolean;
  priority?: number;
  hasInteractionWithAgent?: boolean;
  lastLiveCardId?: number;
  delayedDisconnect?: number;
  merged?: ContactMerged[];
};

export type ChatLogType =
  | "data"
  | "set_contact"
  | "remove_contact"
  | "move_board"
  | "remove_board"
  | "move_column"
  | "in_column_order"
  | "contact_merged"
  | "contact_unmerged"
  | "blocked"
  | "unblocked"
  | "profile_update"
  | "task_created";

export type ChatLog = {
  type: ChatLogType;
  data?: Record<string, any[]>;
  createdBy: string;
  createdAt: firebase.firestore.Timestamp;
};

export const chatLogTypeMap: Record<ChatLogType, string> = {
  data: locale.changedFields,
  in_column_order: locale.changedPosition,
  move_board: locale.movedToBoard,
  move_column: locale.movedToColumn,
  remove_board: locale.removedFromBoard,
  set_contact: locale.addedAsContact,
  remove_contact: locale.removedAsContact,
  contact_merged: locale.mergedContact,
  contact_unmerged: locale.unmergedContact,
  blocked: locale.blockedUser,
  unblocked: locale.unblockedUser,
  profile_update: locale.profileUpdate,
  task_created: locale.taskCreated,
};

export enum ChatDisconnect {
  _5m = 5,
  _15 = 15,
  _30 = 30,
  _1 = 60,
  _3 = 180,
  _8 = 480,
  _12 = 720,
  _24 = 1440,
} // in hours

export const chatDisconnectMap: Record<ChatDisconnect, string> = {
  [ChatDisconnect._5m]: "5 min",
  [ChatDisconnect._15]: "15 min",
  [ChatDisconnect._30]: "30 min",
  [ChatDisconnect._1]: "1 hour",
  [ChatDisconnect._3]: "3 hour",
  [ChatDisconnect._8]: "8 hour",
  [ChatDisconnect._12]: "12 hour",
  [ChatDisconnect._24]: "24 hour",
};

export const ratingTypes = [
  { label: "NPS", value: "nps", maxRatings: 10 },
  { label: "CSAT", value: "csat", maxRatings: 5 },
  { label: "3-Point CSAT", value: "3-point-csat", maxRatings: 3 },
];

export const chatRatingTypesMap: Record<
  "nps" | "csat" | "3-point-csat",
  string
> = {
  nps: "NPS",
  csat: "CSAT",
  "3-point-csat": "3 Point CSAT",
};

export enum ChatActivityLogType {
  AGENT_CONNECTED = "agent_connected",
  AGENT_DISCONNECTED = "agent_disconnected",
  LIVE_CHAT_REQUESTED = "live_chat_requested",
  LIVE_CHAT_TIMEOUT = "live_chat_timeout",
  ASSIGNED_BY_ROUND_ROBIN = "assigned_by_round_robin",
  TRANSFERRED_BY_ROUND_ROBIN = "transferred_by_round_robin",
  TRANSFERRED_TO_AGENT = "transferred_to_agent",
  TRANSFERRED_BACK_TO_AGENT = "transferred_back_to_agent",
  TRANSFERRED_TO_TEAM = "transferred_to_team",
  TRANSFERRED_TO_AGENT_AUTO_ASSIGNED = "transferred_to_agent_auto_assigned",
  TRANSFERRED_TIMEOUT = "transferred_timeout",
  AGENT_ACCEPTED_TRANSFER = "agent_accepted_transfer",
  AGENT_REJECTED_TRANSFER = "agent_rejected_transfer",
  DIALOG_TRIGGERED = "dialog_triggered",
  CHAT_TRANSFERRED_TO_BOT = "chat_transferred_to_bot",
  CHAT_TRANSFERRED_FROM_BOT = "chat_transferred_from_bot",
}

export const parseActivityLog = (
  meta: {
    type: ChatActivityLogType;
    extras: Record<string, any>;
  },
  users?: User[]
): string => {
  const getName = (id: string) => {
    const user = users?.find((user) => user.id === id);
    return user ? `${user.name}` : "User";
  };

  try {
    switch (meta?.type) {
      case ChatActivityLogType.AGENT_CONNECTED:
        return t(locale.chatACLAgentConnected, {
          agent: getName(meta.extras.agent_id),
        });
      case ChatActivityLogType.AGENT_DISCONNECTED:
        return t(locale.chatACLAgentDisconnected, {
          agent: getName(meta.extras.agent_id),
        });
      case ChatActivityLogType.LIVE_CHAT_REQUESTED:
        return t(locale.chatACLLiveChatRequested, {
          card: meta.extras?.card_name,
        });
      case ChatActivityLogType.LIVE_CHAT_TIMEOUT:
        return t(locale.chatACLLiveChatTimeout);
      case ChatActivityLogType.ASSIGNED_BY_ROUND_ROBIN:
        return t(locale.chatACLAssignedByRoundRobin, {
          agent: getName(meta.extras.agent_id),
        });
      case ChatActivityLogType.TRANSFERRED_BY_ROUND_ROBIN:
        return t(locale.chatACLTransferredByRoundRobin, {
          agent: getName(meta.extras.agent_id),
        });
      case ChatActivityLogType.TRANSFERRED_TO_AGENT:
        return t(locale.chatACLTransferredToAgent, {
          agent: getName(meta.extras.agent_id),
          transfer: getName(meta.extras.transfer_to),
        });
      case ChatActivityLogType.TRANSFERRED_BACK_TO_AGENT:
        return t(locale.chatACLTransferredBackToAgent, {
          agent: getName(meta.extras.agent_id),
        });
      case ChatActivityLogType.TRANSFERRED_TO_TEAM:
        return t(locale.chatACLTransferredToTeam, {
          agent: getName(meta.extras.agent_id),
          card: meta.extras.card_name,
        });
      case ChatActivityLogType.TRANSFERRED_TO_AGENT_AUTO_ASSIGNED:
        return t(locale.chatACLTransferredToAgentAutoAssigned, {
          agent: getName(meta.extras.agent_id),
          transfer: getName(meta.extras.transfer_to),
        });
      case ChatActivityLogType.TRANSFERRED_TIMEOUT:
        return t(locale.chatACLTransferredTimeout);
      case ChatActivityLogType.AGENT_ACCEPTED_TRANSFER:
        return t(locale.chatACLAgentAcceptedTransfer, {
          agent: getName(meta.extras.agent_id),
        });
      case ChatActivityLogType.AGENT_REJECTED_TRANSFER:
        return t(locale.chatACLAgentRejectedTransfer, {
          agent: getName(meta.extras.agent_id),
        });
      case ChatActivityLogType.DIALOG_TRIGGERED:
        return t(locale.chatACLDialogTriggered, {
          name: `${meta.extras.dialog_id} (${meta.extras.dialog_name})`,
        });
      case ChatActivityLogType.CHAT_TRANSFERRED_TO_BOT:
        return t(locale.botTransferred, {
          name: meta.extras.bot_name,
        });
      case ChatActivityLogType.CHAT_TRANSFERRED_FROM_BOT:
        return t(locale.botTransferredFrom, {
          name: meta.extras.bot_name,
        });
      default:
        return meta.type;
    }
  } catch (error) {
    return meta?.type;
  }
};
