import { useCallback } from "react";
import { useField, FieldConfig } from "formik";
import { useTranslation } from "react-i18next";

import locale from "../../constants/locale";
import clsx from "clsx";
import { TFunctionResult } from "i18next";

export const FileField = ({
  children,
  onChange,
  ...props
}: FieldConfig<File> & {
  disabled?: boolean;
  onChange?: (v: any) => void;
  label?: React.ReactChild | TFunctionResult;
  accept?: string;
  className?: string
}) => {
  const { t } = useTranslation();
  const [, { value, touched, error }, { setValue }] = useField(props);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files) return;
      const file = e.currentTarget.files[0];
      setValue(file);
      if (onChange) {
        onChange(file as any);
      }
    },
    [setValue, onChange]
  );

  const label = typeof props.label === "function" ? props.label() : props.label;

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="file hast-name">
        <label className={clsx("file-label", props.className)}>
          <input
            value=""
            onChange={handleChange}
            type="file"
            className="file-input"
            accept={props.accept}
            disabled={props.disabled}
          />
          <span className={clsx("file-cta", props.className)} >
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label has-ellipsis-text">{value ? value.name : t(locale.file)}</span>
          </span>
        </label>
      </div>
      {touched && !!error && <p className="help is-danger">{error}</p>}
    </div >
  );
};
