import firebase from "firebase/compat/app";
import { v4 as uuid } from "uuid";
import { AddonEnum, Feature, FeatureEnum } from "../models";

import ImageTools from "../services/images";

export type FirebaseSubscriber = {
  id: any;
  unsubscribe: () => void;
};

export const uploadImage = async (
  img: File,
  path?: string
): Promise<string | null> => {
  const imageTools = new ImageTools();
  const thumbSize = Number(process.env.REACT_APP_THUMBNAIL_SIZE) || 500;
  const blob = await imageTools.resize(img, {
    width: thumbSize,
    height: thumbSize,
  });

  if (!blob) return null;

  const ref = firebase
    .storage()
    .ref("images")
    .child(path || uuid());
  await ref.put(blob);

  return ref.getDownloadURL();
};

export const uploadFiles = async (file: File[]): Promise<string[]> => {
  const ref = firebase.storage().ref(`files`);

  const tasks = file.map(async (file) => {
    const fileRef = ref.child(uuid());
    await fileRef.put(file);
    return fileRef.getDownloadURL();
  });

  return Promise.all(tasks);
};

export const deleteFiles = async (urls: string[]): Promise<void> => {
  const ref = firebase.storage().ref(`files`);

  const tasks = urls.map(async (url) => {
    const idx = url.lastIndexOf("/");
    const fileRef = ref.child(url.substring(idx + 1)?.split("?")?.[0]);
    await fileRef.delete();
  });

  await Promise.all(tasks);
};

export const features = [
  {
    name: "unifiedInbox",
    type: "default",
    includedIn: ["starter", "pro", "premium"],
  },
  {
    name: "integrationWith",
    type: "default",
    includedIn: ["starter", "pro", "premium"],
  },
  {
    name: "conversationRoutingBot",
    type: "default",
    includedIn: ["starter", "pro", "premium"],
  },
  {
    name: "whatsAppIntegration",
    type: "default",
    values: {
      pro: "selfService",
      premium: "guidedOnboarding",
    },
    addonsFor: [],
    includedIn: ["pro", "premium"],
  },
  {
    name: "videoCalls",
    key: AddonEnum.VIDEO_CALL,
    type: "addon",
    data: {
      realtime: true,
      price: {
        starter: 0.05,
        pro: 0.05,
        premium: 0.05,
      },
    },
    values: {
      starter: "0.05PerMinute",
      pro: "0.05PerMinute",
      premium: "0.05PerMinute",
    },
    addonsFor: ["starter", "pro", "premium"],
  },
  {
    name: "liveStreaming",
    key: AddonEnum.LIVESTREAM,
    type: "addon",
    data: {
      realtime: true,
      price: {
        starter: 0.05,
        pro: 0.05,
        premium: 0.05,
      },
    },
    values: {
      starter: "0.05PerMinute",
      pro: "0.05PerMinute",
      premium: "0.05PerMinute",
    },
    addonsFor: ["starter", "pro", "premium"],
  },
  {
    name: "customBot",
    type: "default",
    includedIn: ["pro", "premium"],
  },
  {
    name: "advancedCollab",
    key: FeatureEnum.COLLABORATOR_PERMISSION,
    type: "default",
    includedIn: ["pro", "premium"],
  },
  {
    name: "multipleBoards",
    type: "default",
    key: FeatureEnum.MULTIPLE_BOARDS,
    includedIn: ["pro", "premium"],
  },
  {
    name: "campaigns",
    key: FeatureEnum.CAMPAIGNS,
    type: "default",
    includedIn: ["pro", "premium"],
  },
  {
    name: "zohoIntegration",
    type: "default",
    includedIn: ["pro", "premium"],
  },
  {
    name: "allSeats",
    type: "default",
    limits: {
      starter: 6,
      pro: 12,
      premium: 24,
    },
    values: {
      starter: "6Seats",
      pro: "12Seats",
      premium: "24Seats",
    },
    includedIn: ["starter", "pro", "premium"],
  },
  {
    name: "additionalSeats",
    type: "addon",
    key: AddonEnum.ADDITIONAL_SEATS,
    inputType: "slider",
    data: {
      basePrice: 15,
      minOrMax: {
        starter: [6, 100],
        pro: [12, 100],
        premium: [24, 100],
      },
    },
    values: {
      starter: "15PerMonthPerSeat",
      pro: "15PerMonthPerSeat",
      premium: "15PerMonthPerSeat",
    },
    addonsFor: ["starter", "pro", "premium"],
  },
  {
    name: "2FA",
    type: "default",
    includedIn: ["starter", "pro", "premium"],
  },
  {
    name: "accountManager", // have a look here
    type: "default",
    values: {
      pro: "shared",
      premium: "dedicated",
    },
    includedIn: ["pro", "premium"],
  },
  {
    name: "customIntegrations",
    type: "default",
    values: {
      pro: "onDemand",
      premium: "included",
    },
    includedIn: ["pro", "premium"],
  },
  {
    name: "standardSupport",
    type: "addon",
    price: 29,
    values: {
      starter: "29PerMonth",
      pro: "29PerMonth",
      premium: "29PerMonth",
    },
    addonsFor: ["starter", "pro", "premium"],
  },
  {
    name: "premiumSupport",
    type: "addon",
    price: 49,
    values: {
      starter: "49PerMonth",
      pro: "49PerMonth",
      premium: "49PerMonth",
    },
    addonsFor: ["starter", "pro", "premium"],
  },
] as Feature[];
