import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";

import { LiveChatCard, User, UserPermissions, UserType } from "../models";

import { logError } from "../services/logging";

import { FirebaseSubscriber } from "./utils";
import { getLiveChatCards } from "../services/chathub";
import audit from "./audit";
import workspaces from "./workspaces";
import auth from "./auth";

class UsersStore {
  private db: firebase.firestore.Firestore;

  private usersSubscriber?: FirebaseSubscriber;

  users?: User[] = [];
  allUsers?: User[] = [];
  liveChatCards?: LiveChatCard[];

  constructor() {
    this.db = firebase.firestore();

    makeAutoObservable(this);
  }

  loadUsers = async (workspaceId: string) => {
    try {
      const snap = await this.db
        .collection("users")
        .where("workspaceIds", "array-contains", workspaceId)
        .get();

      const ref = snap.docs
        .map((m) => {
          const data = m.data() as User;
          data.id = m.id;
          return data;
        })
        .filter(
          (f) => !f.permissions?.perWorkspace?.[workspaceId]?.userDeactivated
        );
      this.setUsers(ref);
    } catch (error) {
      logError(error);
    }
  };

  loadAllUsers = async (workspaceId: string) => {
    if (this.usersSubscriber?.id === workspaceId) {
      return;
    }
    this.setAllUsers([]);
    if (this.usersSubscriber) {
      this.usersSubscriber.unsubscribe();
    }

    this.usersSubscriber = {
      id: workspaceId,
      unsubscribe: this.db
        .collection("users")
        .where("workspaceIds", "array-contains", workspaceId)
        .onSnapshot((snap) => {
          if (snap.empty) {
            return;
          }

          let temp = this.allUsers?.slice() || [];

          snap.docChanges().forEach((change) => {
            const data = change.doc.data() as User;

            switch (change.type) {
              case "added":
                temp.push(data);
                break;
              case "modified":
                //listen for auth user status updates
                if (
                  data.id === auth?.user?.id &&
                  auth?.user?.status &&
                  auth?.user?.status !== data.status
                ) {
                  auth.setUser({
                    ...auth.user,
                    status: data.status,
                  });
                }
                temp = temp.map((m) => (m.id === data.id ? data : m));
                break;
              case "removed":
                temp = temp.filter((f) => f.id !== data.id);
                break;
            }
          });

          this.setAllUsers(temp);
        }, logError),
    };
  };

  loadLiveChatCards = async (
    botId: number,
    botKey: string,
    botPass: string
  ) => {
    const cards = await getLiveChatCards(botId, botKey, botPass);

    this.setLiveChatCards(cards || undefined);

    return cards;
  };

  updateUserType = async (id: string, type: UserType) => {
    try {
      await this.db.collection("users").doc(id).update({ type });
      await this.updateUserInChathub(id);
      audit.logEvent("user_updated", {
        id,
        doc: { type },
        botId: workspaces.selectedWorkspace?.id,
      });
    } catch (error) {
      logError(error);
    }
  };

  updateUserPermissions = async (id: string, permissions: UserPermissions) => {
    try {
      await this.db.collection("users").doc(id).update({ permissions });
      this.setUsers(
        this.users?.map((f) => {
          if (f.id === id)
            return {
              ...f,
              permissions,
            };
          return f;
        }) ?? []
      );
      this.setAllUsers(
        this.allUsers?.map((f) => {
          if (f.id === id)
            return {
              ...f,
              permissions,
            };
          return f;
        }) ?? []
      );
      await this.updateUserInChathub(id);
      audit.logEvent("user_updated", {
        id,
        doc: { permissions },
        botId: workspaces.selectedWorkspace?.id,
      });
    } catch (error) {
      logError(error);
    }
  };

  deleteUser = async (user: User, workspaceId: string) => {
    try {
      await this.db
        .collection("users")
        .doc(user.id)
        .update({
          workspaceIds: user.workspaceIds?.filter((b) => b !== workspaceId),
        });
    } catch (error) {
      logError(error);
    }
  };

  getAssignedGroupIdsOfUser = (user: User, selectedBotId: number) => {
    if (!workspaces.selectedFunction || !workspaces.selectedWorkspace) {
      return [];
    }

    const liveChatFilters =
      user?.permissions?.perWorkspace?.[workspaces.selectedWorkspace.id]
        ?.perFunction?.[workspaces.selectedFunction.slug]?.liveChatFilters ||
      {};

    const keysWithNonEmptyValues = Object.keys(liveChatFilters).filter(
      (key) => liveChatFilters[key].length > 0
    );
    return keysWithNonEmptyValues.map((m) => parseInt(m)) as number[];
  };

  updateUserInChathub = async (id: string) => {
    if (!workspaces.selectedWorkspace?.id) return;

    try {
      // const snapshot = await this.db.collection("users").doc(id).get();
      // const user = snapshot.data() as User;
      // await syncUserToChathub(
      //   workspaces.selectedWorkspace?.id,
      //   workspaces.selectedWorkspace?.key,
      //   workspaces.selectedWorkspace?.password,
      //   user
      // );
    } catch (error) {
      logError(error);
    }
  };

  private setUsers = (users: User[]) => {
    this.users = users;
  };

  private setAllUsers = (users: User[]) => {
    this.allUsers = users;
  };

  private setLiveChatCards = (cards?: LiveChatCard[]) => {
    this.liveChatCards = cards;
  };
}

export default new UsersStore();
