import React from "react";
import { useField, FieldConfig } from "formik";

export const MultiSelectWithDisable = <T,>(
  props: FieldConfig<T[]> & {
    children?: React.ReactNode;
    disabled?: boolean;
    label?: string;
    options: T[];
    disabledOptions: T[];
    getdisabledToolTip: (t: T) => string;
    getLabel: (t: T) => string;
  }
) => {
  const [, { value, error, touched }, { setValue }] = useField(props);

  const handleToggle = (v: T) => () => {
    if (value.includes(v)) {
      setValue(value.filter((f) => f !== v));
    } else {
      setValue([...value, v]);
    }
  };

  return (
    <div className="field">
      <label className="label">{props.label}</label>
      {props.options.map((m, i) => (
        <div key={i}>
          {props.disabledOptions && props.disabledOptions.includes(m) && (
            <div className="control">
              <label className="checkbox">
                <input
                  className="has-tooltip-multiline has-tooltip-right"
                  type="checkbox"
                  onChange={handleToggle(m)}
                  checked={value.includes(m)}
                  disabled={true}
                  data-tooltip={props.getdisabledToolTip(m)}
                />{" "}
                {props.getLabel(m)}
              </label>
            </div>
          )}
          {!props.disabledOptions || props.disabledOptions.includes(m) || (
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  onChange={handleToggle(m)}
                  checked={value.includes(m)}
                />{" "}
                {props.getLabel(m)}
              </label>
            </div>
          )}
        </div>
      ))}
      {touched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};
