import { EdnaMessageParser } from "../../../../models/EdnaChat";
import { RenderFile } from "./RenderFIle";
import { RenderText } from "./RenderText";

export const MessageRenders = ({ message }: { message: EdnaMessageParser }) => {

  const types = {
    "text": RenderText,
    "file": RenderFile,
  }

  const Component = types[message.type];

  return (
    <Component
      message={message}
    />
  )
}