import ct from "countries-and-timezones";
import { DateTime } from "luxon";

import { Day } from "../models";

export const timezones = Object.entries(ct.getAllTimezones()).map(
  ([, value]) => value.name
);

const dayMap = {
  [Day.Monday]: 1,
  [Day.Tuesday]: 2,
  [Day.Wednesday]: 3,
  [Day.Thursday]: 4,
  [Day.Friday]: 5,
  [Day.Saturday]: 6,
  [Day.Sunday]: 7,
};

export const dayArray = [
  Day.Monday,
  Day.Tuesday,
  Day.Wednesday,
  Day.Thursday,
  Day.Friday,
  Day.Saturday,
  Day.Sunday,
];

export const getDayLocalizedAbb = (d: Day) =>
  DateTime.local()
    .set({ weekday: Number(dayMap[d]) })
    .toFormat("EEE")
    .slice(0, 2);

export const parseFirebaseDateTime = (
  timestamp: any,
  format: any = null,
  toIso = false
) => {
  try {
    const dateTime = DateTime.fromSeconds(timestamp.seconds)
      .plus(timestamp.nanoseconds / 1000000000)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);

    if (toIso) return dateTime.toISO();

    const formattedDate = dateTime.toFormat(format || "M/d/yyyy, h:mm a");

    return formattedDate;
  } catch (error) {
    return "";
  }
};

export const parseFirebaseDateToHumanReadable = (timestamp: any) => {
  try {
    const date = DateTime.fromSeconds(timestamp.seconds)
      .plus(timestamp.nanoseconds / 1000000000)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);

    return date.hasSame(DateTime.utc(), "day")
      ? String(
          date.toRelative({
            style: "short",
          })
        )
      : date.toLocaleString({
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "2-digit",
        });
  } catch (error) {
    return "--:--";
  }
};

export const generateDateRange = (
  currentDate: any,
  endObject: any,
  returnFormat: string
) => {
  try {
    const startDate = currentDate.startOf("month");
    const endDate = startDate.minus(endObject);

    const monthRange = [];
    let current = startDate;

    while (current >= endDate) {
      monthRange.push(current.toFormat(returnFormat));
      current = current.minus({ months: 1 });
    }

    return monthRange.reverse();
  } catch (error) {
    console.log(error);

    return [];
  }
};

export const calculateAverageTime = (datesArray: Date[][]) => {
  if (datesArray.length === 0) {
    return 0; // Return 0 if the array is empty
  }

  let totalDays = 0;

  // Calculate the total time in milliseconds
  datesArray.forEach((dateObject) => {
    const openTime = dateObject[0].getTime();
    const closedTime = dateObject[1].getTime();
    const timeDifference = closedTime - openTime;
    totalDays += timeDifference / (1000 * 60 * 60 * 24); // Convert milliseconds to days
  });

  // Calculate the average time in days
  const averageTime = totalDays / datesArray.length;
  return Math.round(averageTime);
};

export const calculateTimeDifference = (dateObject: Date[]) => {
  if (dateObject.length === 0) {
    return 0; // Return 0 if the array is empty
  }

  const openTime = dateObject[0].getTime();
  const closedTime = dateObject[1].getTime();
  const timeDifference = closedTime - openTime;

  return timeDifference / (1000 * 60 * 60 * 24); // Convert milliseconds to days
};
