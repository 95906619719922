import { DateTime } from "luxon";
import { GrTrigger } from "react-icons/gr";
import { GrAction } from "react-icons/gr";
import { IoMdStopwatch } from "react-icons/io";
import { PiGitBranchBold } from "react-icons/pi";

import IconType from "react-icons";
import locale from "../constants/locale";

export type Category = {
  id?: number;
  name: string;
};

export type Workflow = {
  id?: number;
  categoryId: number;
  userId: string;
  name: string;
  status: "draft" | "published";
  draftData: any;
  publishedData: any;
  eventsCount: number;
  isActive: boolean;
  createdAt: DateTime;
  updatedAt: DateTime;
};

export type WorkflowEvent = {
  id?: number;
  status: "in-progress" | "completed" | "failed";
  workflowData: Record<string, any>;
  meta: Record<string, any>;
  failedReason?: string;
  createdAt: DateTime;
  updatedAt: DateTime;
};

export const workflowStatusMap = {
  "in-progress": locale.inProgress,
  completed: locale.completed,
  failed: locale.failed,
};

export type WorkflowNodes = {
  triggers?: any[];
  actions?: any[];
  delays?: any[];
  logics?: any[];
};

export enum Cases {
  IF = "if",
  AND = "and",
  OR = "or",
}

export const casesMap: Record<Cases, string> = {
  [Cases.IF]: locale.if,
  [Cases.AND]: locale.and,
  [Cases.OR]: locale.or,
};

export const nodeIcon = {
  trigger: GrTrigger,
  action: GrAction,
  delay: IoMdStopwatch,
  logic: PiGitBranchBold,
} as Record<string, IconType.IconType>;
