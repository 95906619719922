import {
  Notification,
  parseNotificationText,
  TicketMentionNotificationMeta,
} from "../../../../../models/Notification";
import clsx from "clsx";
import { MessageRenders } from "../../../../ednaConversations/components/MessageRenders/MessageRenders";
import { forwardRef, useImperativeHandle } from "react";
import { useStore } from "../../../../../stores";
import { useHistory } from "react-router";
import routes from "../../../../../constants/routes";

type Props = {
  notification: Notification;
};

type RefProps = {
  onClick: () => void;
};

export const TicketMentionParser = forwardRef<RefProps, Props>(
  ({ notification }, ref) => {
    const { selectedWorkspace } = useStore("workspaces");
    const { markAsRead } = useStore("notifications");
    const history = useHistory();

    const meta = notification.meta as TicketMentionNotificationMeta;

    const onClick = () => {
      if (!notification.readAt) {
        markAsRead(String(notification.id));
      }

      history.push(
        routes.tickets
          .replace(":workspaceId", String(selectedWorkspace?.id))
          .replace(":functionId", "op")
          .replace(":group", meta.ticket.liveCardId.toString()) +
          `?ticketId=${meta.ticket.id}&commentId=${meta.comment.id}`
      );
    };

    useImperativeHandle(ref, () => ({
      onClick,
    }));

    return (
      <>
        <div
          className="title mb-2"
          style={{
            fontSize: 12,
          }}
        >
          {parseNotificationText(notification)}
        </div>
        <div>
          <span
            style={{
              fontSize: 12,
            }}
            className={clsx("has-long-text has-text-weight-medium")}
          >
            <MessageRenders
              message={{
                type: "text",
                text: meta.comment.comment,
              }}
            />
          </span>
        </div>
      </>
    );
  }
);
