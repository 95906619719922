/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import locale from "../../constants/locale";
import { useStore } from "../../stores";
import { useHistory, useLocation } from "react-router";
import routes, { publicRoutes } from "../../constants/routes";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { WorkspaceMenuContent } from "../workspaceSidebar/components/WorkspaceMenuPopover";
import { Popover } from "react-tiny-popover";

const BottomNavigation = () => {
  const { t } = useTranslation();
  const { user } = useStore("auth");
  const history = useHistory();
  const location = useLocation();
  const { dmNewMessage } = useStore("ednaChats");
  const [isWorkspacePopoverOpen, setIsWorkspacePopoverOpen] = useState(false);
  const { selectedWorkspace } = useStore("workspaces");

  const isHelpCenterUserViewPage = location.pathname.includes(
    routes.helpCenterUserViewHome.replace("/:workspaceId", "")
  );

  const isBookingPage = location.pathname.includes(
    routes.booking.replace("/:bookingWorkspaceId", "").replace("/:eventId", "")
  );

  const routeTo = (path: string) => {
    if (selectedWorkspace) {
      history.push(path.replace(":workspaceId", String(selectedWorkspace?.id)));
    }
  };

  if (
    !selectedWorkspace ||
    !user ||
    isHelpCenterUserViewPage ||
    isBookingPage ||
    publicRoutes.includes(location.pathname)
  ) {
    return null;
  }

  return (
    <nav
      className="navbar is-link is-fixed-bottom is-hidden-tablet"
      role="navigation"
    >
      <div className="navbar-brand">
        <a
          className="navbar-item is-expanded is-block has-text-centered"
          onClick={() => routeTo(routes.workspaceHome)}
        >
          <i className="fa fa-home"></i>
          <p className="is-size-7">{t(locale.home)}</p>
        </a>
        <a
          className="navbar-item is-expanded is-block has-text-centered"
          onClick={() => routeTo(routes.teammates.replace("/:userId?", ""))}
        >
          <span className="is-relative">
            <i className="fa fa-message"></i>
            {dmNewMessage && <span className="badge is-danger" />}
          </span>
          <p className="is-size-7">{t(locale.teammates)}</p>
        </a>
        <Popover
          isOpen={isWorkspacePopoverOpen}
          positions={["top"]}
          align="start"
          containerStyle={{ zIndex: "9999", paddingRight: "0.5rem" }}
          onClickOutside={() => setIsWorkspacePopoverOpen(false)}
          content={() => (
            <WorkspaceMenuContent
              onClose={() => setIsWorkspacePopoverOpen(false)}
            />
          )}
        >
          <a
            onClick={() => setIsWorkspacePopoverOpen(!isWorkspacePopoverOpen)}
            className="navbar-item is-expanded is-block has-text-centered"
          >
            <i className="fa fa-user"></i>
            <p className="is-size-7">{t(locale.workspaces)}</p>
          </a>
        </Popover>
      </div>
    </nav>
  );
};

export default observer(BottomNavigation);
