import { AppModule } from "./User";
import { HelpCenter } from "./HelpCenter";
import { EdnaBotSettings } from "./Bot";
import { MdWorkspacesOutline } from "react-icons/md";
import { IoPeopleOutline } from "react-icons/io5";
import { MdCrisisAlert } from "react-icons/md";
import { RiMegaphoneLine } from "react-icons/ri";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import locale from "../constants/locale";

export type WorkspaceFunction = {
  name: string;
  slug: string;
  features?: WorkspaceFeature[];
  meta?: Record<string, any>;
  active?: boolean;
};

export type WorkspaceFeature = {
  key: string;
  name: string;
  modules?: AppModule[];
};

export type WorkspaceWithoutId = Omit<Workspace, "id">;

export type Workspace = {
  id: string;
  companyName: string;
  industry: string;
  teamSize?: number;
  logo?: string;
  currency?: string;
  defaultFunctionSlug?: string;
  functions?: WorkspaceFunction[];
  settings?: WorkspaceSettings;
  helpCenterSettings?: HelpCenter;
  ednaBotSettings?: EdnaBotSettings;
  liveCardProfiles?: Record<number, string>;
};

export type WorkspaceSettings = {
  openAIEnabled?: boolean;
  openAIApiKey?: string;
  ednaBotEnabled?: boolean;
};

export type SystemFeature = {
  key: string;
  name: string;
  description: string;
  modules?: AppModule[];
};

export type SystemFunction = {
  name: string;
  slug: string;
  description: string;
  features: SystemFeature[];
  isLocked?: boolean;
};

export const functions: SystemFunction[] = [
  {
    name: locale.operations,
    slug: "op",
    description: locale.operationsDescription,
    features: [
      {
        name: locale.operationsFeatureInbox,
        key: "omnichannel_inbox",
        modules: ["CHATS"],
        description: locale.operationsFeatureInboxDescription,
      },
      {
        name: locale.operationsFeatureSupport,
        key: "conversational_support",
        modules: ["QUICK_REPLIES", "CONTACTS", "ANALYTICS"],
        description: locale.operationsFeatureSupportDescription,
      },
      {
        name: locale.operationsFeatureEngagement,
        key: "conversational_engagement",
        modules: ["LIVE_STREAM", "CAMPAIGNS"],
        description: locale.operationsFeatureEngagementDescription,
      },
      {
        name: locale.operationsFeatureConversion,
        key: "conversational_conversions",
        modules: ["BOARDS"],
        description: locale.operationsFeatureConversionDescription,
      },
      {
        name: locale.operationsFeatureCommerce,
        key: "conversational_commerce",
        modules: ["COMMERCE", "CATALOGS"],
        description: locale.operationsFeatureCommerceDescription,
      },
      {
        name: locale.operationsFeatureWorkflows,
        key: "workflows",
        modules: ["WORKFLOWS"],
        description: locale.operationsFeatureWorkflowsDescription,
      },
    ],
    isLocked: false,
  },
  {
    name: locale.marketing,
    slug: "mk",
    description: "",
    features: [
      {
        name: locale.mkFeatureContentMarketing,
        description: locale.mkFeatureContentMarketingDescription,
        key: "content_marketing",
      },
      {
        name: locale.mkFeatureProductMarketing,
        description: locale.mkFeatureProductMarketingDescription,
        key: "product_marketing",
      },
      {
        name: locale.mkFeatureAcquisitionMarketing,
        description: locale.mkFeatureAcquisitionMarketingDescription,
        key: "acquisition_marketing",
      },
    ],
    isLocked: true,
  },
  {
    name: locale.sales,
    slug: "sa",
    description: "",
    features: [
      {
        name: locale.saFeatureSalesOperations,
        key: "sales_operations",
        description: locale.saFeatureSalesOperationsDescription,
      },
      {
        name: locale.saFeatureSalesDevelopment,
        key: "sales_development",
        description: locale.saFeatureSalesDevelopmentDescription,
      },
      {
        name: locale.saFeatureSalesTraining,
        key: "sales_training",
        description: locale.saFeatureSalesTrainingDescription,
      },
    ],
    isLocked: true,
  },
  {
    name: locale.finance,
    slug: "fn",
    description: "",
    features: [
      {
        name: locale.fnFeatureEPA,
        key: "epa",
        description: locale.fnFeatureEPADescription,
      },
      {
        name: locale.fnFeatureFinanceOperations,
        key: "finance_operations",
        description: locale.fnFeatureFinanceOperationsDescription,
      },
      {
        name: locale.fnFeatureTaxation,
        key: "taxation",
        description: locale.fnFeatureTaxationDescription,
      },
    ],
    isLocked: true,
  },
  {
    name: locale.hr,
    slug: "hr",
    description: "",
    features: [
      {
        name: locale.hrFeatureTalentAcquisition,
        key: "talent_acquisition",
        description: locale.hrFeatureTalentAcquisitionDescription,
      },
      {
        name: locale.hrFeatureCompBenefits,
        key: "comp_benefits",
        description: locale.hrFeatureCompBenefitsDescription,
      },
      {
        name: locale.hrFeatureTraining,
        key: "training",
        description: locale.hrFeatureTrainingDescription,
      },
    ],
    isLocked: true,
  },
];

export const functionIconsMap = {
  op: MdWorkspacesOutline,
  mk: RiMegaphoneLine,
  sa: MdCrisisAlert,
  fn: RiMoneyDollarCircleLine,
  hr: IoPeopleOutline,
} as Record<string, any>;
