import strings from "../constants/locale";

export enum Cuisine {
  AfricanEthiopian = 0,
  AfricanOther = 1,
  Alcohol = 2,
  American = 3,
  Argentinian = 4,
  AsianFusion = 5,
  AsianOther = 6,
  BBQ = 7,
  Bakery = 8,
  BarOrPubFood = 9,
  Brazilian = 10,
  BreakfastAndBrunch = 11,
  BubbleTea = 12,
  Burgers = 13,
  Burmese = 14,
  CajunOrCreole = 15,
  Caribbean = 16,
  Chicken = 17,
  Chilean = 18,
  ChineseCantonese = 19,
  ChineseHotPot = 20,
  ChineseNoodlesAndDumplings = 21,
  ChineseOther = 22,
  ChineseSichuan = 23,
  ChineseTaiwanese = 24,
  CoffeeAndTea = 25,
  Colombian = 26,
  ComfortFood = 27,
  DessertOther = 28,
  EuropeanOther = 29,
  FishAndChips = 30,
  French = 31,
  Georgian = 32,
  German = 33,
  Gourmet = 34,
  Halal = 35,
  Healthy = 36,
  IceCreamAndFrozenYogurt = 37,
  Indian = 38,
  Indonesian = 39,
  Italian = 40,
  JapaneseOther = 41,
  JapaneseRamen = 42,
  JapaneseSushi = 43,
  JuiceAndSmoothies = 44,
  Korean = 45,
  Kosher = 46,
  LatinAmericanOther = 47,
  Malaysian = 48,
  Mediterranean = 49,
  Mexican = 50,
  MiddleEastern = 51,
  ModernAustralian = 52,
  Other = 53,
  Peruvian = 54,
  Pizza = 55,
  Russian = 56,
  SaladOrSandwiches = 57,
  Seafood = 58,
  Snacks = 59,
  SoulFood = 60,
  Southern = 61,
  Spanish = 62,
  Steakhouse = 63,
  Thai = 64,
  VegetarianOrVegan = 65,
  Vietnamese = 66,
  Wings = 67,
}

export const cuisinesMap: { [k: number]: string } = {
  [Cuisine.AfricanEthiopian]: strings.cuisines.africanEthiopian,
  [Cuisine.AfricanOther]: strings.cuisines.africanOther,
  [Cuisine.Alcohol]: strings.cuisines.alcohol,
  [Cuisine.American]: strings.cuisines.american,
  [Cuisine.Argentinian]: strings.cuisines.argentinian,
  [Cuisine.AsianFusion]: strings.cuisines.asianFusion,
  [Cuisine.AsianOther]: strings.cuisines.asianOther,
  [Cuisine.BBQ]: strings.cuisines.bbq,
  [Cuisine.Bakery]: strings.cuisines.bakery,
  [Cuisine.BarOrPubFood]: strings.cuisines.barOrPubFood,
  [Cuisine.Brazilian]: strings.cuisines.brazilian,
  [Cuisine.BreakfastAndBrunch]: strings.cuisines.breakfastAndBrunch,
  [Cuisine.BubbleTea]: strings.cuisines.bubbleTea,
  [Cuisine.Burgers]: strings.cuisines.burgers,
  [Cuisine.Burmese]: strings.cuisines.burmese,
  [Cuisine.CajunOrCreole]: strings.cuisines.cajunOrCreole,
  [Cuisine.Caribbean]: strings.cuisines.caribbean,
  [Cuisine.Chicken]: strings.cuisines.chicken,
  [Cuisine.Chilean]: strings.cuisines.chilean,
  [Cuisine.ChineseCantonese]: strings.cuisines.chineseCantonese,
  [Cuisine.ChineseHotPot]: strings.cuisines.chineseHotPot,
  [Cuisine.ChineseNoodlesAndDumplings]:
    strings.cuisines.chineseNoodlesAndDumplings,
  [Cuisine.ChineseOther]: strings.cuisines.chineseOther,
  [Cuisine.ChineseSichuan]: strings.cuisines.chineseSichuan,
  [Cuisine.ChineseTaiwanese]: strings.cuisines.chineseTaiwanese,
  [Cuisine.CoffeeAndTea]: strings.cuisines.coffeeAndTea,
  [Cuisine.Colombian]: strings.cuisines.colombian,
  [Cuisine.ComfortFood]: strings.cuisines.comfortFood,
  [Cuisine.DessertOther]: strings.cuisines.dessertOther,
  [Cuisine.EuropeanOther]: strings.cuisines.europeanOther,
  [Cuisine.FishAndChips]: strings.cuisines.fishAndChips,
  [Cuisine.French]: strings.cuisines.french,
  [Cuisine.Georgian]: strings.cuisines.georgian,
  [Cuisine.German]: strings.cuisines.german,
  [Cuisine.Gourmet]: strings.cuisines.gourmet,
  [Cuisine.Halal]: strings.cuisines.halal,
  [Cuisine.Healthy]: strings.cuisines.healthy,
  [Cuisine.IceCreamAndFrozenYogurt]: strings.cuisines.iceCreamAndFrozenYogurt,
  [Cuisine.Indian]: strings.cuisines.indian,
  [Cuisine.Indonesian]: strings.cuisines.indonesian,
  [Cuisine.Italian]: strings.cuisines.italian,
  [Cuisine.JapaneseOther]: strings.cuisines.japaneseOther,
  [Cuisine.JapaneseRamen]: strings.cuisines.japaneseRamen,
  [Cuisine.JapaneseSushi]: strings.cuisines.japaneseSushi,
  [Cuisine.JuiceAndSmoothies]: strings.cuisines.juiceAndSmoothies,
  [Cuisine.Korean]: strings.cuisines.korean,
  [Cuisine.Kosher]: strings.cuisines.kosher,
  [Cuisine.LatinAmericanOther]: strings.cuisines.latinAmericanOther,
  [Cuisine.Malaysian]: strings.cuisines.malaysian,
  [Cuisine.Mediterranean]: strings.cuisines.mediterranean,
  [Cuisine.Mexican]: strings.cuisines.mexican,
  [Cuisine.MiddleEastern]: strings.cuisines.middleEastern,
  [Cuisine.ModernAustralian]: strings.cuisines.modernAustralian,
  [Cuisine.Other]: strings.cuisines.other,
  [Cuisine.Peruvian]: strings.cuisines.peruvian,
  [Cuisine.Pizza]: strings.cuisines.pizza,
  [Cuisine.Russian]: strings.cuisines.russian,
  [Cuisine.SaladOrSandwiches]: strings.cuisines.saladOrSandwiches,
  [Cuisine.Seafood]: strings.cuisines.seafood,
  [Cuisine.Snacks]: strings.cuisines.snacks,
  [Cuisine.SoulFood]: strings.cuisines.soulFood,
  [Cuisine.Southern]: strings.cuisines.southern,
  [Cuisine.Spanish]: strings.cuisines.spanish,
  [Cuisine.Steakhouse]: strings.cuisines.steakhouse,
  [Cuisine.Thai]: strings.cuisines.thai,
  [Cuisine.VegetarianOrVegan]: strings.cuisines.vegetarianOrVegan,
  [Cuisine.Vietnamese]: strings.cuisines.vietnamese,
  [Cuisine.Wings]: strings.cuisines.wings,
};
