import { useField, FieldConfig, useFormikContext } from "formik";
import clsx from "clsx";

export const Toggle = ({
  onChange,
  children,
  tooltip,
  fieldClassName,
  ...props
}: FieldConfig<boolean> & {
  fieldClassName?: string;
  tooltip?: string;
  disabled?: boolean;
  label?: string;
  labelClass?: string;
  className?: string;
  onChange?: (v: boolean) => void;
}) => {
  const [field, { value, touched, error }, { setValue }] = useField(props);
  const { submitCount } = useFormikContext();

  const isTouched = touched || submitCount > 0;

  return (
    <div className={clsx("field", fieldClassName)}>
      <input
        id={field.name}
        type="checkbox"
        name={field.name}
        className={clsx("switch", props.className)}
        checked={value}
        disabled={props.disabled}
        onBlur={field.onBlur}
        onChange={() => {
          if (onChange) onChange(!value);
          setValue(!value);
        }}
      />
      <label htmlFor={field.name} className={props.labelClass}>
        {props.label}
      </label>
      {isTouched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};
