import { DetailedHTMLProps, VideoHTMLAttributes, useEffect, useRef } from "react";

export const LocalVideo = ({
  file,
  ...rest
}: { file: Blob } & DetailedHTMLProps<
  VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const img = ref.current;

    img.src = URL.createObjectURL(file);
    img.onload = function () {
      URL.revokeObjectURL(img.src); // free memory
    };
  }, [file]);

  return <video ref={ref} {...rest} />;
};
