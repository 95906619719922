import React from "react";
import { useTranslation } from "react-i18next";
import { useField, FieldConfig } from "formik";

import strings from "../../constants/locale";

import { DeliveryZone } from "../../models";
import { useStore } from "../../stores";

export const ZoneDeliveryCoverage = (
  props: FieldConfig<DeliveryZone[]> & {
    children?: never;
    disabled?: boolean;
    label?: string;
    tooltip?: string;
  }
) => {
  const { t } = useTranslation();
  const [, { value, touched, error }, { setValue }] = useField(props);
  const { selectedRestaurant } = useStore("restaurants");

  const handleTimeChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(
        value.map((m, i) => {
          if (i === index) {
            m.time = Number(event.currentTarget.value);
          }

          return m;
        })
      );
  const handlePriceChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(
        value.map((m, i) => {
          if (i === index) {
            m.price = Number(event.currentTarget.value);
          }

          return m;
        })
      );
  const handleNameChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(
        value.map((m, i) => {
          if (i === index) {
            m.name = event.currentTarget.value;
          }

          return m;
        })
      );
  const handleAdd = () =>
    setValue([
      ...value,
      {
        time: 0,
        price: 0,
        name: "",
      },
    ]);
  const handleRemove = (index: number) => () =>
    setValue(value.filter((_, i) => i !== index));

  return (
    <div className="field">
      <label className="label">
        {props.label}
        {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
      </label>
      <table className="table is-bordered is-fullwidth mt-2">
        <thead>
          <tr>
            <th>{t(strings.zone)}</th>
            <th>
              {t(strings.deliveryTime)} ({t(strings.minutes)})
            </th>
            <th>
              {t(strings.price)} ({selectedRestaurant?.currency})
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {value.map((c, i) => (
            <tr key={i}>
              <td>
                <input
                  className="input"
                  type="text"
                  onChange={handleNameChange(i)}
                  value={c.name || ""}
                />
                {touched && Array.isArray(error) && error[i]?.name && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.name}</p>
                  </>
                )}
              </td>
              <td>
                <input
                  className="input"
                  type="number"
                  onChange={handleTimeChange(i)}
                  value={c.time?.toString() || ""}
                />
                {touched && Array.isArray(error) && error[i]?.time && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.time}</p>
                  </>
                )}
              </td>
              <td>
                <input
                  className="input"
                  type="number"
                  onChange={handlePriceChange(i)}
                  value={c.price?.toString() || ""}
                />
                {touched && Array.isArray(error) && error[i]?.price && (
                  <>
                    <br />
                    <p className="help is-danger">{error[i]?.price}</p>
                  </>
                )}
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <button
                  className="delete"
                  type="button"
                  onClick={handleRemove(i)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={5}>
              <button
                type="button"
                className="button is-light is-rounded"
                onClick={handleAdd}
              >
                {t(strings.add)}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {touched && !!error && !Array.isArray(error) && (
        <p className="help is-danger">{error}</p>
      )}
    </div>
  );
};
