import {
  BillingAutoRecharge,
  BillingBalanceNotification,
  BillingCompanyDetails,
  Subscription,
} from ".";
import { HelpCenter } from "./HelpCenter";
import { Schedule } from "./Schedule";
import { UserType } from "./User";

export type AutoLeadCollectorConfig = {
  formId: string;
  boardId: string;
  columnId: string;
  maxCardPerAgent: number;
};

export type BotSettings = {
  agentsSchedule?: Schedule;
  livecardSchedule?: Record<string, any>;
  agentsOutOfScheduleMessage?: string;
  agentsOutOfScheduleDialog?: number;
  timezone?: string;
  usersAllowedToAssignChats?: UserType[];
  chatTransferredTimeout?: number;
  scheduledChatClosingDialog?: number;
  scheduledChatClosingDialogType?: "simple" | "nps" | "csat";
  scheduledChatClosingDialogAt?: number;
  maxChatsAssigned?: number;
  maxCallsAssigned?: number;
  autoRecharge?: BillingAutoRecharge;
  balanceNotification?: BillingBalanceNotification;
  companyDetails?: BillingCompanyDetails;
  inactivityDialog?: number;
  inactivityDialogAt?: number;
  enableAutomaticChatAcceptance?: boolean;
  maxChatAssigned?: number;
  chatAssignmentType?: string;
  autoAssignChatUserTypes?: number[];
  autoAssignRoundRobinChat?: boolean;
  roundRobinChatTimeout?: number;
  ednaBotEnabled?: boolean;
  enableAutoLeadCollector?: boolean;
  autoLeadCollectorConfig?: AutoLeadCollectorConfig[];
  queuePositionEnabled?: boolean;
  queuePositionDialog?: number;
  enableAutoAssignRequestOnNextDay?: boolean;
  autoAssignDealUserTypes?: UserType[];
  autoOpenChat360ModalWhenAgentConnects?: boolean;
  disableACMForChatTransfer?: boolean;
  disableCCDForChatTransfer?: boolean;
  disableChatHistory?: boolean;
  guestModeEnabled?: boolean;
};

export type BotContactsTable = {
  header: string[];
  pageSize: number;
};

export type BotCustomization = {
  contactsTable: BotContactsTable;
};

export enum PlanType {
  First = 0,
  Second = 1,
  Third = 2,
}

export type PlanFeature =
  | "Web"
  | "WhatsApp"
  | "Facebook"
  | "Instagram"
  | "Telegram"
  | "Teams"
  | "Video Calls"
  | "Live Stream"
  | "Campaigns"
  | "Boards"
  | "Contacts";

export type TieredPrices = {
  [qty: number]: number;
};

export type FeaturePrice = {
  feature: PlanFeature;
  price: number;
  // qty >= 10 = 100, qty >= 10 = 49.99
  tieredPrices?: TieredPrices;
};

export type CustomPlanValues = {
  [key in PlanType]: {
    basePrice: number;
    features: FeaturePrice[];
    baseAgents: number;
    agentPrice: number;
  };
};

export type BotPlan = {
  type: PlanType;
  agents: number;
  customer?: {
    id: string;
    subscriptionId?: string;
  };
};

export const defaultWebChatChannels = {
  whatsapp: "Whatsapp",
  messenger: "Messenger",
  instagram: "Instagram",
  telegram: "Telegram",
  email: "Email",
  call: "Call",
};

export type WebChat = {
  botId: string;
  webWidget: string;
  botName: string;
  bubbleMessage: string;
  bubbleVideoUrl?: string;
  botIconLink: string;
  replySoonText: string;
  primaryColor: string;
  secondaryColor: string;
  isTypingEnabled: boolean;
  headerText: string;
  headerDescription: string;
  welcomeText: string;
  greetingsDialogs: string;
  faqText: string;
  popularQueries: string;
  isContentCardEnabled: boolean;
  contentCardId: string;
  contentCardText: string;
  isLinkCardEnabled: boolean;
  linkCardId: string;
  linkCardText: string;
  linkCardUrl: string;

  whatsappChannelEnabled: boolean;
  whatsappChannelText: string;
  whatsappChannelLink: string;

  messengerChannelEnabled: boolean;
  messengerChannelText: string;
  messengerChannelLink: string;

  instagramChannelEnabled: boolean;
  instagramChannelText: string;
  instagramChannelLink: string;

  telegramChannelEnabled: boolean;
  telegramChannelText: string;
  telegramChannelLink: string;

  emailChannelEnabled: boolean;
  emailChannelText: string;
  emailChannelLink: string;

  callChannelEnabled: boolean;
  callChannelText: string;
  callChannelLink: string;
};

export type CatalogSettings = {
  pageSize: number;
  header: string[];
};

export type EdnaBotSettings = {
  embeddingTypes: string[];
};

export type Bot = {
  id: number;
  workspaceId?: string;
  key: string;
  password: string;
  image: string;
  language: string;
  name: string;
  facebook: {
    pageName: string;
    pageId: string;
  };
  plan?: BotPlan;
  balance?: number;
  customPlanValues?: CustomPlanValues;
  settings: BotSettings;
  featureFlags: string[];
  hasVideoCallEnabled?: boolean;
  customization?: BotCustomization;
  subscription?: Subscription;
  helpCenterSettings?: HelpCenter;
  webChatSettings?: WebChat;
  catalogSettings?: CatalogSettings;
  ednaBotSettings?: EdnaBotSettings;
};

export const chatClosingDialogTypeMap: Record<
  "simple" | "nps" | "csat" | "3-point-csat",
  string
> = {
  simple: "Simple Dialog",
  nps: "NPS Dialog",
  csat: "CSAT Dialog",
  "3-point-csat": "3 Point CSAT Dialog",
};

export const inactivityDialogTypeMap: Record<"story" | "template", string> = {
  story: "Story",
  template: "Template",
};

export const chatAssignmentTypeMap: Record<"manual" | "round-robin", string> = {
  manual: "Manual",
  "round-robin": "Round Robin",
};
