import React from "react";
import { useField, FieldConfig, useFormikContext } from "formik";

export const SingleSelect = <T, V>(
  props: FieldConfig<V> & {
    children?: React.ReactNode;
    disabled?: boolean;
    label?: string;
    options: T[];
    name: string;
    getLabel: (t: T) => string;
    getValue?: (t: T) => V;
  }
) => {
  const [field, { touched, error, value }, { setValue }] = useField(props);
  const { submitCount } = useFormikContext();

  const isTouched = touched || submitCount > 0;

  const getValue = (t: T) =>
    t && props.getValue ? props.getValue(t) : (t as unknown as V);

  return (
    <div className="field">
      <label className="label">{props.label}</label>
      {props.options.map((m, i) => (
        <div key={i} className="control">
          <label className="radio">
            <input
              {...field}
              type="radio"
              name={props.name}
              disabled={props.disabled}
              value={Number(getValue(m))}
              checked={getValue(m) === value}
              onChange={(e) => {
                //@ts-ignore
                setValue(Number(e.currentTarget.value));
              }}
            />{" "}
            {props.getLabel(m)}
          </label>
        </div>
      ))}
      {isTouched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};
