import { makeAutoObservable } from "mobx";
import {
  getHelpCenterArticle,
  getHelpCenterCollection,
  getHelpCenterCollections,
  getHelpCenterConfig,
  getHelpCenterSearchArticles,
} from "../services/chathub";
import { Article, Collection, HelpCenter } from "../models/HelpCenter";

class HelpCenterUserView {
  workspaceId: string = "";
  config?: HelpCenter;
  configLoaded: boolean = false;
  collections: Collection[] = [];
  collectionLoaded: boolean = false;
  loading: boolean = true;

  selectedCollection?: Collection;
  articles: Article[] = [];
  selectedArticle?: Article;
  searchLoading: boolean = false;
  searchedArticles: Article[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getConfig = async (workspaceId: string) => {
    if (!workspaceId || this.configLoaded) return;

    this.loading = true;
    this.workspaceId = workspaceId;
    try {
      const config = await getHelpCenterConfig(workspaceId);
      this.config = config;
      this.updateColors();
      this.configLoaded = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  };

  getCollections = async () => {
    try {
      const collections = await getHelpCenterCollections(this.workspaceId);
      this.collections = collections;
      this.collectionLoaded = true;
    } catch (error) {
      console.log(error);
    }
  };

  getCollection = async (collectionId: string) => {
    try {
      const { collection, articles } = await getHelpCenterCollection(
        this.workspaceId,
        collectionId
      );
      this.selectedCollection = collection;
      this.articles = articles;
    } catch (error) {
      console.log(error);
    }
  };

  searchArticles = async (searchText: string) => {
    this.searchLoading = true;
    this.searchedArticles = [];
    try {
      const articles = await getHelpCenterSearchArticles(
        this.workspaceId,
        searchText
      );
      this.searchedArticles = articles;
    } catch (error) {
      console.log(error);
    } finally {
      this.searchLoading = false;
    }
  };

  getArticle = async (articleId: string) => {
    try {
      const article = await getHelpCenterArticle(this.workspaceId, articleId);
      this.selectedArticle = article;
    } catch (error) {
      console.log(error);
    }
  };

  private updateColors = () => {
    const root = document.documentElement;
    root.style.setProperty("--root-position", "unset");
    if (this.config?.primaryColor && this.config?.secondaryColor) {
      root.style.setProperty(
        "--help-center-primary-color",
        this.config.primaryColor
      );
      root.style.setProperty(
        "--help-center-secondary-color",
        this.config.secondaryColor
      );
      root.style.setProperty("--thumb-color", this.config.secondaryColor);
    }
  };
}

export default new HelpCenterUserView();
