import { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";

export const LocalAudio = ({ file, ...rest }: { file: Blob }) => {
  const [url, setUrl] = useState<string>();
  useEffect(() => {
    const src = URL.createObjectURL(file);

    setUrl(src);

    return () => URL.revokeObjectURL(src); // free memory
  }, [file]);

  return (
    <AudioPlayer
      src={url}
      showJumpControls={false}
      autoPlay={false}
      {...rest}
    />
  );
};
