//@ts-ignore
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import locale from "../../constants/locale";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "../../stores";
import { UserPermissions } from "../../models";

const TourGuider = () => {
  const { t } = useTranslation();
  const { user } = useStore("auth");
  const { selectedWorkspace } = useStore("workspaces");
  const [currentQueue, setCurrentQueue] = useState("");
  const ref = useRef(null);

  const disableTour = (key: string) => {
    const tour = JSON.parse(localStorage.getItem("tour") || "[]");
    tour.push(key);
    localStorage.setItem("tour", JSON.stringify(tour));
  };

  const hasDisabled = (key: string) => {
    const tour = JSON.parse(localStorage.getItem("tour") || "[]");
    return tour.includes(key);
  };

  const permissions = useMemo(
    () => user?.permissions?.perWorkspace?.[selectedWorkspace?.id || ""],
    [selectedWorkspace?.id, user?.permissions?.perWorkspace]
  ) as UserPermissions["perWorkspace"];

  const guidedSteps = useMemo(
    () => ({
      intialFunctions: [
        {
          element: ".add-to-workspace-op",
          popover: {
            description: t(locale.guiddedTour.addOperations),
          },
        },
      ],
      functions: [
        {
          element: ".function-switch",
          popover: {
            description: t(locale.guiddedTour.enableFunction),
          },
        },
        {
          element: ".function-settings",
          popover: {
            description: t(locale.guiddedTour.configureFunction),
          },
        },
      ],
      functionView: [
        {
          element: ".features-completed",
          popover: {
            description: t(locale.guiddedTour.featuresCompleted),
          },
        },
      ],
      activity: [
        {
          element: ".all-activities",
          popover: {
            description: t(locale.guiddedTour.activity),
          },
        },
      ],
      calendar: [
        {
          element: ".fc-view-harness",
          popover: {
            description: t(locale.guiddedTour.calender),
          },
        },
        {
          element: ".calendar-sidebar",
          popover: {
            description: t(locale.guiddedTour.calenderEvents),
          },
        },
      ],
      dms: [
        {
          element: ".all-dms",
          popover: {
            description: t(locale.guiddedTour.dms),
          },
        },
      ],
      setup: [
        {
          element: ".step-1",
          popover: {
            description: t(locale.guiddedTour.inbox),
          },
        },
        {
          element: ".step-2",
          popover: {
            description: t(locale.guiddedTour.helpCenter),
          },
        },
        {
          element: ".step-3",
          popover: {
            description: t(locale.guiddedTour.chatBotSettings),
          },
        },
        {
          element: ".step-4",
          popover: {
            description: t(locale.guiddedTour.businessSetting),
          },
        },
        {
          element: ".workspace-profile",
          popover: {
            description: t(locale.guiddedTour.workspaceProfile),
          },
        },
      ],
      operationsOverview: [
        {
          element: ".op-chats",
          popover: {
            description: t(locale.guiddedTour.opInbox),
          },
        },
        {
          element: ".op-chats-myqueue",
          popover: {
            description: t(locale.guiddedTour.opMyQueue),
          },
        },
        {
          element: ".op-teams",
          popover: {
            description: t(locale.guiddedTour.opTeams),
          },
        },
        {
          element: ".op-automation",
          popover: {
            description: t(locale.guiddedTour.opWorkflows),
          },
        },
        {
          element: ".op-support",
          popover: {
            description: t(locale.guiddedTour.opCrm),
          },
        },
        {
          element: ".op-engage",
          popover: {
            description: t(locale.guiddedTour.opCampaigns),
          },
        },
        {
          element: ".op-convert",
          popover: {
            description: t(locale.guiddedTour.opSales),
          },
        },
        {
          element: ".op-preferences",
          popover: {
            description: t(locale.guiddedTour.opSettings),
          },
        },
      ],
    }),
    [t]
  );

  const createNewDriver = useCallback(
    (key: string) => {
      let currentSteps = guidedSteps[key as never] as any;

      if (!currentSteps?.length) {
        return;
      }

      const driverState = driver({
        showProgress: true,
        steps: currentSteps,
        onCloseClick: () => {
          disableTour(key);
          driverState.destroy();
        },
      });
      driverState.drive();
      setCurrentQueue("");
    },
    [guidedSteps]
  );

  const tourListerner = useCallback(
    (e: any) => {
      if (
        currentQueue === e.detail ||
        hasDisabled(e.detail) ||
        permissions?.disableProductTour
      ) {
        return;
      }
      setCurrentQueue(e.detail);
      if (ref.current) clearTimeout(ref.current);
      const timeout = setTimeout(() => createNewDriver(e.detail), 1000);
      //@ts-ignore
      ref.current = timeout;
    },
    [createNewDriver, currentQueue, permissions?.disableProductTour]
  );

  useEffect(() => {
    window.addEventListener("startTour", tourListerner);
    return () => {
      window.removeEventListener("startTour", tourListerner);
    };
  }, [tourListerner]);

  return null;
};

export default observer(TourGuider);
