import { useFormikContext } from "formik";
//@ts-ignore
import { get } from "lodash";
import { useEffect } from "react";
import useGoogleMapsLib from "../../hooks/useGoogleMapsLib";

import { DeliveryZone, Location } from "../../models";

export const ZonePriceSelect = ({
  auto,
  zones,
  zoneFieldName,
  center,
  toLocation,
  label,
}: {
  auto: boolean;
  zones: DeliveryZone[];
  zoneFieldName: string;
  center: Location;
  toLocation?: Location;
  label: string;
}) => {
  const { setFieldValue, values, errors } = useFormikContext<any>();
  const { submitCount } = useFormikContext();
  const error = get(errors, `${zoneFieldName}.price`);

  const { ready } = useGoogleMapsLib();

  const isTouched = submitCount > 0;

  useEffect(() => {
    if (!ready || !toLocation || !auto) {
      return;
    }

    try {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        { lat: center.latitude, lng: center.longitude },
        { lat: toLocation.latitude, lng: toLocation.longitude }
      );

      const zone = zones.find((f) => (f.radius || 0) > distance);

      setFieldValue(`${zoneFieldName}.radius`, zone?.radius);
      setFieldValue(`${zoneFieldName}.price`, zone?.price);
      setFieldValue(`${zoneFieldName}.time`, zone?.time);
    } catch (error) {
      console.error(error);
    }
  }, [
    auto,
    center,
    center.latitude,
    center.longitude,
    ready,
    setFieldValue,
    toLocation,
    zoneFieldName,
    zones,
  ]);

  if (auto) {
    return null;
  }

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="select">
        <select
          onChange={(e) => {
            const val = Number(e.currentTarget.value);
            const zone = val < 0 ? undefined : zones[val];
            setFieldValue(`${zoneFieldName}.name`, zone?.name);
            setFieldValue(`${zoneFieldName}.price`, zone?.price);
            setFieldValue(`${zoneFieldName}.time`, zone?.time);
          }}
          value={zones.findIndex(
            (f) => f.name === get(values, `${zoneFieldName}.name`)
          )}
        >
          <option value={-1}>{label}</option>
          {zones.map((m, i) => (
            <option key={i} value={i}>
              {m.name}
            </option>
          ))}
        </select>
      </div>
      {isTouched && !!error && (
        <p className="help is-danger">{String(error)}</p>
      )}
    </div>
  );
};
