import clsx from "clsx";
import { logError } from "../../services/logging";

type Props = {
  className?: string;
  src: string;
  name?: string;
  disabledPrimaryText?: boolean
};

const download = (url: string, name: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.target = "_blank";
  a.className = "is-hidden";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const DownloadButton = ({ className, src, name, disabledPrimaryText }: Props) => {
  let n = src;

  try {
    const url = new URL(src).pathname.split("/");
    n = name || decodeURI(url[url.length - 1]);
  } catch (error) {
    logError(error)
  }
  return (
    <div
      className={clsx(
        "is-flex is-clickable is-align-items-center",
        className,
        {
          "has-text-primary": !disabledPrimaryText
        }
      )}
      onClick={() => download(src, n)}
    >
      <span className="icon mr-2">
        <i className="fas fa-download"></i>
      </span>
      <span>{n}</span>
    </div>
  );
};
