import {
  Notification,
  parseNotificationText,
  ThreadNotificationMeta,
} from "../../../../../models/Notification";
import clsx from "clsx";
import { MessageRenders } from "../../../../ednaConversations/components/MessageRenders/MessageRenders";
import { forwardRef, useImperativeHandle } from "react";
import { useStore } from "../../../../../stores";
import { EdnaChatType } from "../../../../../models/EdnaChat";
import { useHistory } from "react-router";
import routes from "../../../../../constants/routes";

type Props = {
  notification: Notification;
};

type RefProps = {
  onClick: () => void;
};

export const ThreadParser = forwardRef<RefProps, Props>(
  ({ notification }, ref) => {
    const { selectedWorkspace } = useStore("workspaces");
    const { markAsRead } = useStore("notifications");
    const { user } = useStore("auth");
    const history = useHistory();

    const meta = notification.meta as ThreadNotificationMeta;

    const onClick = () => {
      if (!notification.readAt) {
        markAsRead(String(notification.id));
      }
      let userId;
      if (meta.parentChat.type === EdnaChatType.SINGLE) {
        userId = meta.parentChat.room.split("|").find((f) => f !== user?.id);
      } else if (meta.parentChat.type === EdnaChatType.CHANNEL) {
        userId = meta.parentChat.room.replace("livecard-", "");
      }

      history.push(
        routes.teammates
          .replace(":workspaceId", String(selectedWorkspace?.id))
          .replace(
            ":userId?",
            String(userId) + "?thread=" + meta.chat.threadMessageId
          )
      );
    };

    useImperativeHandle(ref, () => ({
      onClick,
    }));

    return (
      <>
        <div
          className="title mb-2"
          style={{
            fontSize: 12,
          }}
        >
          {parseNotificationText(notification)}
        </div>
        <div>
          <span
            style={{
              fontSize: 12,
            }}
            className={clsx("has-long-text has-text-weight-medium")}
          >
            <MessageRenders message={meta.message.message} />
          </span>
        </div>
      </>
    );
  }
);
