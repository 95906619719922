import { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useField, FieldConfig } from "formik";
import TimePicker from "react-time-picker";

import { Schedule as Model, Day, days } from "../../models";
import { getDayLocalizedAbb } from "../../utils/internationalization";
import locale from "../../constants/locale";

import styles from "./Schedule.module.scss";

export const Schedule = ({
  children,
  ...props
}: FieldConfig<Model> & {
  disabled?: boolean;
  label?: string;
  tooltip?: string;
}) => {
  const { t } = useTranslation();
  const [, { value, touched, error }, { setValue }] = useField(props);
  const [selectedDay, setSelectedDay] = useState(days[0]);

  const schedules = new Map<string, Day[]>();
  Object.entries(value ?? "{}").forEach(([d, t]) =>
    schedules.set(`${t.from || ""}-${t.to || ""}`, [
      ...(schedules.get(`${t.from || ""}-${t.to || ""}`) || []),
      d as Day,
    ])
  );

  const handleChange = (from: string, to: string) =>
    setValue({
      ...value,
      ...{
        [selectedDay]: { from, to },
      },
    });

  return (
    <div className="field">
      <label className="label">
        {props.label}
        {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
      </label>
      <div className="is-flex is-scrollable-x pb-4">
        {days.map((m) => (
          <button
            type="button"
            className={clsx("button is-rounded", styles.buttons, {
              "is-primary": selectedDay === m,
            })}
            onClick={() => setSelectedDay(m)}
          >
            {getDayLocalizedAbb(m)}
          </button>
        ))}
      </div>
      <div
        className={clsx(
          "has-border has-rounded-corners p-4 is-flex is-flex-direction-column is-align-items-center",
          { [styles.disabled]: props.disabled },
          styles.fieldsContainer
        )}
      >
        <div className="is-flex">
          <div className="field mr-5">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={
                    value?.[selectedDay]?.from === "00:00" &&
                    value?.[selectedDay]?.to === "23:59"
                  }
                  onClick={() => handleChange("00:00", "23:59")}
                />{" "}
                {t(locale.allDay)}
              </label>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={
                    (!value?.[selectedDay]?.from &&
                      !value?.[selectedDay]?.to) ||
                    (value?.[selectedDay]?.from === "00:00" &&
                      value?.[selectedDay]?.to === "00:00")
                  }
                  onClick={() => handleChange("00:00", "00:00")}
                />{" "}
                {t(locale.closed)}
              </label>
            </div>
          </div>
        </div>
        <div>
          <span className="icon is-medium">
            <i className="far fa-clock has-text-grey-light"></i>
          </span>
          <TimePicker
            onChange={(v) =>
              handleChange(v as string, value?.[selectedDay]?.to)
            }
            value={value?.[selectedDay]?.from || "00:00"}
            format="HH:mm"
            disableClock
            clearIcon={null}
          />
          {" — "}
          <TimePicker
            onChange={(v) =>
              handleChange(value?.[selectedDay]?.from, v as string)
            }
            value={value?.[selectedDay]?.to || "00:00"}
            format="HH:mm"
            disableClock
            clearIcon={null}
          />
        </div>
      </div>
      {touched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};
