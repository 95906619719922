import React from "react";
import { TFunctionResult } from "i18next";
import clsx from "clsx";
import { useField, FieldConfig } from "formik";
//@ts-ignore
import RSTimeField from "react-simple-timefield";

type Props = {
  children?: React.ReactNode;
  label: React.ReactChild | TFunctionResult;
  tooltip?: string;
  icon?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  useTextArea?: boolean;
} & FieldConfig<string>;

export const TimeField = (props: Props) => {
  const [field, meta, { setValue }] = useField(props);

  const label = typeof props.label === "function" ? props.label() : props.label;

  const handleOnChange = (from: React.ChangeEvent<HTMLInputElement>) =>
    setValue(from.target.value);

  return (
    <div className="field">
      <label className="label">
        {label}
        {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
      </label>
      <div className={clsx("control", { "has-icons-left": !!props.icon })}>
        <RSTimeField
          onChange={handleOnChange}
          value={meta.value || "00:00"}
          showSeconds={false}
          input={
            <input
              name={field.name}
              className={clsx({
                "is-danger-input": meta.touched && meta.error,
              })}
              readOnly={props.readOnly}
              disabled={props.disabled}
              placeholder={props.placeholder}
            />
          }
        />

        {!!props.icon && (
          <span className="icon is-small is-left">
            <i className={clsx("fas", props.icon)}></i>
          </span>
        )}
      </div>
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
    </div>
  );
};
