import { useField, FieldConfig, useFormikContext } from "formik";
import Creatable, { CreatableProps } from "react-select/creatable";
import { components, GroupBase } from "react-select";
import clsx from "clsx";

export const CreatableSelect = <T extends { label: string; value: V }, V>({
  onChange,
  allowEmpty,
  formatOptionLabel,
  components: _components,
  children,
  ...props
}: Pick<
  CreatableProps<T, false, GroupBase<T>>,
  "components" | "formatOptionLabel"
> &
  FieldConfig<V> & {
    disabled?: boolean;
    label?: string;
    placeholder?: string;
    icon?: string | (() => React.ReactChild);
    options: T[];
    allowEmpty?: boolean;
    getLabel?: (t: T) => string;
    getValue?: (t: T) => V;
    onChange?: (v: V) => void;
    className?: string;
  }) => {
  const [field, { value, touched, error }, { setValue }] = useField(props);
  const { submitCount } = useFormikContext();

  const isTouched = touched || submitCount > 0;

  const getValue = (t: T) =>
    t && props.getValue ? props.getValue(t) : (t as unknown as V);

  return (
    <div className="field">
      {props.label && <label className="label">{props.label}</label>}
      <Creatable
        {...field}
        value={(value === undefined ? null : value) as unknown as T}
        onChange={(v) => {
          setValue(v as unknown as V);
          onChange?.(v as unknown as V);
        }}
        className={props.className}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        options={props.options}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={props.getLabel}
        getOptionValue={getValue as any}
        menuShouldScrollIntoView
        components={{
          ..._components,
          SelectContainer: ({ children, ...rest }) => (
            <components.SelectContainer
              {...rest}
              className={clsx("control", rest.className, {
                "has-icons-left": !!props.icon,
              })}
            >
              {children}
              {!!props.icon && (
                <span
                  className="icon is-small is-left"
                  style={{
                    pointerEvents:
                      typeof props.icon === "string" ? "none" : "all",
                  }}
                >
                  {typeof props.icon === "string" ? (
                    <i className={clsx("fas", props.icon)}></i>
                  ) : (
                    props.icon()
                  )}
                </span>
              )}
            </components.SelectContainer>
          ),
          Control: (p) => (
            <components.Control
              {...p}
              className={clsx("select", p.className)}
            />
          ),
          IndicatorsContainer: () => <></>,
        }}
        styles={{
          valueContainer: (b) => ({
            ...b,
            paddingLeft: "2.5rem",
            paddingRight: "2.5rem",
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
        }}
        menuPortalTarget={document.body}
      />
      {isTouched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};
