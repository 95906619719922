import clsx from "clsx";
import { useCallback, useEffect } from "react";
import Timer from "react-compound-timer";
import { ReactMediaRecorder, StatusMessages } from "react-media-recorder";

import { audioBufferToMp3 } from "../../services/audio";

type Props = {
  onRecordingAvailable: (blob: Blob) => void;
};

const TimerStatus = ({
  status,
  start,
  reset,
  startRecording,
}: {
  status: StatusMessages;
  start: () => void;
  reset: () => void;
  startRecording: () => void;
}) => {
  useEffect(() => {
    if (status === "recording") {
      start();
    }
  }, [start, status]);

  return (
    <div className="is-flex is-align-items-center">
      <button
        className="button is-danger is-light is-large"
        onClick={status === "recording" ? reset : startRecording}
      >
        <span className="icon">
          <i
            className={clsx("fas", {
              "fa-circle": status !== "recording",
              "fa-stop": status === "recording",
            })}
          ></i>
        </span>
      </button>
      <span className="ml-4 subtitle">
        <Timer.Minutes />:<Timer.Seconds />
      </span>
    </div>
  );
};

export const Recorder = ({ onRecordingAvailable }: Props) => {
  const handleStop = useCallback(
    (_: string, blob: Blob) => {
      blob.arrayBuffer().then((buffer) => {
        const audioContext = new AudioContext();

        audioContext.decodeAudioData(buffer, (audioBuffer) => {
          const result = audioBufferToMp3(audioBuffer);
          onRecordingAvailable(result);
        });
      });
    },
    [onRecordingAvailable]
  );

  return (
    <ReactMediaRecorder
      audio={{
        channelCount: 1,
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
        sampleRate: 16000,
      }}
      onStop={handleStop}
      render={({ status, startRecording, stopRecording }) => (
        <Timer
          formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
          startImmediately={false}
          onReset={stopRecording}
        >
          {(p: any) => (
            <TimerStatus
              {...p}
              status={status}
              startRecording={startRecording}
            />
          )}
        </Timer>
      )}
    />
  );
};
