import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { FallbackImage } from "../fallbackImage/FallbackImage";

import locale from "../../constants/locale";

import styles from "./ImageModal.module.scss";

const modalRoot = document.getElementById("root");

type Props = {
  isOpen: boolean;
  src?: string;
  onClose?: () => void;
};

export const ImageModal = ({ isOpen, src, onClose }: Props) => {
  const { t } = useTranslation();
  const container = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    modalRoot?.appendChild(container);
    return () => {
      modalRoot?.removeChild(container);
    };
  }, [container]);

  const modal = (
    <div
      className={clsx("modal", styles.imageModal, {
        "is-active": isOpen,
      })}
    >
      <div className="modal-background" onClick={onClose} />
      <div className="modal-content">
        <FallbackImage
          src={src}
          alt="img"
          customFallback={
            <span className="has-text-white">{t(locale.resourceNotFound)}</span>
          }
        />
      </div>
      <button
        className="modal-close is-large has-background-grey-light"
        aria-label="close"
        onClick={onClose}
      />
    </div>
  );

  return createPortal(modal, container);
};
