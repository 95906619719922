import firebaseApp from "firebase/compat/app";
import { EmbeddingStatus } from "./HelpCenter";

export type OKFolder = {
  id?: string;
  name: string;
  documentsCount?: number;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
};

export enum OKDocumentSource {
  WRITE = "write",
  UPLOAD = "upload",
  IMPORT_WEBSITE = "import-website",
}

export const okDocumentSourceMap: Record<OKDocumentSource, string> = {
  [OKDocumentSource.WRITE]: "Write",
  [OKDocumentSource.UPLOAD]: "Upload",
  [OKDocumentSource.IMPORT_WEBSITE]: "Import Website",
};

export type OKDocument = {
  id?: string;
  folderId: string;
  name: string;
  content: any;
  source: OKDocumentSource;
  embeddingStatus: EmbeddingStatus;
  meta?: {
    parsedContent?: string;
  };
  lastEmbeddidAt?: firebaseApp.firestore.FieldValue;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
};
