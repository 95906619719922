import clsx from "clsx";
import { useField, FieldConfig, useFormikContext } from "formik";
import { TFunctionResult } from "i18next";

export const Select = <T, V>({
  onChange,
  allowEmpty,
  hideLabel,
  className,
  children,
  ...props
}: FieldConfig<V> & {
  className?: string;
  controlClass?: string;
  labelClass?: string;
  errorClass?: string;
  disabled?: boolean;
  label?: React.ReactChild | TFunctionResult;
  options: T[];
  allowEmpty?: boolean;
  hideLabel?: boolean;
  getLabel?: (t: T) => string;
  getValue?: (t: T) => V;
  onChange?: (v: V) => void;
  extras?: React.ReactChild;
  labelIsNotAOption?: boolean;
}) => {
  const [field, { value, touched, error }, { setValue }] = useField(props);
  const { submitCount } = useFormikContext();
  const label = typeof props.label === "function" ? props.label() : props.label;
  const isTouched = touched || submitCount > 0;

  const getValue = (t: T) =>
    t && props.getValue ? props.getValue(t) : (t as unknown as V);

  return (
    <div className="field">
      {!hideLabel && label && (
        <label className={clsx("label", props.labelClass)}>{label}</label>
      )}
      <div
        className={clsx("select", className, {
          "is-danger": touched && error,
        })}
      >
        <select
          {...field}
          onChange={(e) => {
            const val =
              e.currentTarget.value === props.label
                ? null
                : getValue(props.options[Number(e.currentTarget.value)]);

            if (val === null && !allowEmpty) {
              return;
            }

            setValue(val as V);
            if (onChange) {
              onChange(val as V);
            }
          }}
          value={props.options.findIndex((f) => getValue(f) === value)}
          disabled={props.disabled}
        >
          {!props.labelIsNotAOption && !!props.label && (
            <option>{label}</option>
          )}
          {props.options.map((m, i) => (
            <option key={i} value={i} disabled={(m as any)?.disabled}>
              {props.getLabel ? props.getLabel(m) : (m as unknown as string)}
            </option>
          ))}
        </select>
      </div>
      {isTouched && !!error && (
        <p className={clsx("help is-danger", props.errorClass)}>{error}</p>
      )}

      {props.extras && props.extras}
    </div>
  );
};
