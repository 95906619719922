
export enum FeatureEnum {
  CAMPAIGNS = "CAMPAIGNS",
  MULTIPLE_BOARDS = "multipleBoards",
  COLLABORATOR_PERMISSION = "collaboratorPermission"
}

export enum AddonEnum {
  LIVESTREAM = "LIVE_STREAM",
  VIDEO_CALL = "VIDEO_CALL",
  ADDITIONAL_SEATS = "ADDITIONAL_SEATS"
}

export const addonMap: Record<AddonEnum, string> = {
  [AddonEnum.LIVESTREAM]: "Live Streaming",
  [AddonEnum.VIDEO_CALL]: "Video Calls",
  [AddonEnum.ADDITIONAL_SEATS]: "Additional Seats"
};

export type Feature = {
  key?: string;
  name: string;
  type: string;
  inputType?: string;
  data?: any;
  includedIn?: string[];
  values?: any;
  addonsFor?: string[];
  limits?: Object
  price?: number
};

export type Plan = {
  key: string;
  name: string;
  price: number;
};

export type Addon = {
  key?: string;
  name: string;
  price?: number
  data?: any;
};

export type CurrentPlan = {
  key: string;
  name: string;
  price: number;
  addons: Addon[]
};

export enum SubscriptionStatus {
  Active = "active",
  InActive = "inactive"
}

export type Subscription = {
  botId?: number;
  price: number,
  currentPeriodStart: string;
  currentPeriodEnd: string;
  status: SubscriptionStatus
  plan: CurrentPlan
}

export enum PaymentHistoryTypeEnum {
  Manual = "manual",
  AutoRecharge = "auto-recharge"
};

export const paymentHistoryTypeMap: Record<PaymentHistoryTypeEnum, string> = {
  [PaymentHistoryTypeEnum.Manual]: "Manual",
  [PaymentHistoryTypeEnum.AutoRecharge]: "Auto Recharge",
};

export enum PaymentHistoryStatusEnum {
  Success = "success",
  Failed = "failed"
};

export const paymentHistoryStatusMap: Record<PaymentHistoryStatusEnum, string> = {
  [PaymentHistoryStatusEnum.Success]: "Success",
  [PaymentHistoryStatusEnum.Failed]: "Failed",
};

export type PaymentHistory = {
  id?: string;
  botId: number;
  price: number;
  paymentProvider: string;
  paymentMethod: string;
  paymentMethodId: string;
  type: PaymentHistoryTypeEnum;
  status: PaymentHistoryStatusEnum;
  statusMessage: string
  createdAt: Date;
}

export type BillingAutoRecharge = {
  status?: boolean;
  belowPrice?: number;
  chargePrice?: number;
  defaultPaymentMethodId?: number;
}

export type BillingBalanceNotification = {
  email: string
}

export type BillingCompanyDetails = {
  name?: string;
  poNumber?: string;
  location?: string;
  attentionTo?: string;
  address?: string;
  street?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  haveBusinessTaxID?: boolean
}

// export type Billing = {
//   autoRecharge?: BillingAutoRecharge;
//   balanceNotification?: BillingBalanceNotification;
//   companyDetails?: BillingCompanyDetails;
// }

export type Invoice = {
  id: string;
  invoiceNo: string;
  title: string;
  botId: number;
  createdAt: Date;
  price: number;
  subscription: Subscription
}

export type PaymentMethod = {
  id: string;
  card: string;
  expiryDate: string;
  cardNumber: string;
  customerName: string;
  billingAddress: any;
  isDefault: boolean
}

export type LiveUsage = {
  id?: string;
  botId: number;
  addonKey: string;
  noOfSec: number;
  price: number;
  createdAt: Date | any;
}