import styles from "../WorkspaceSidebar.module.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores";
import { useCallback } from "react";
import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import routes from "../../../constants/routes";
import { IoChatbubblesOutline } from "react-icons/io5";

const DMsLink = observer(() => {
  const { selectedWorkspace } = useStore("workspaces");
  const { dmNewMessage } = useStore("ednaChats");
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const routeTo = useCallback(
    (path: string) => {
      history.push(path.replace(":workspaceId", String(selectedWorkspace?.id)));
    },
    [history, selectedWorkspace]
  );

  const isActiveRoute = location.pathname.startsWith(
    routes.teammates
      .replace(":workspaceId", String(selectedWorkspace?.id))
      .replace("/:userId?", "")
  );

  if (!selectedWorkspace) {
    return null;
  }

  return (
    <button
      onClick={() => routeTo(routes.teammates.replace("/:userId?", ""))}
      style={{ height: "5rem" }}
      className="is-relative w-100 is-no-box-shadow button has-text-white is-flex is-flex-direction-column is-ghost"
    >
      <div
        style={{ display: "flex", padding: "0.4rem" }}
        className={"is-relative"}
      >
        {isActiveRoute && <div className={styles.active}></div>}
        {dmNewMessage && (
          <span className="badge is-danger no-box-shadow mr-2 mt-1" />
        )}
        <IoChatbubblesOutline
          style={{ zIndex: 50 }}
          color={isActiveRoute ? "#2A79EF" : undefined}
          size={26}
        />
      </div>
      <span className="subtitle is-7 has-text-white has-long-text">
        {t(locale.teammates)}
      </span>
    </button>
  );
});

export default DMsLink;
