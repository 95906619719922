import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";

import { logError } from "../services/logging";
import auth from "./auth";

class AuditStore {
  private db: firebase.firestore.Firestore;

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  logEvent = async (name: string, data: any) => {
    try {
      const doc = this.db.collection("audit").doc();

      await doc.set({
        id: doc.id,
        causedBy: auth.user?.id,
        name,
        data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      logError(error);
    }
  };
}

export default new AuditStore();
