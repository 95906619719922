import { makeAutoObservable } from "mobx";
import {
  Board,
  BoardColumn,
  BoardItemCard,
  BoardItemComment,
  BoardItemHistory,
} from "../models";
import bots from "./bots";
import audit from "./audit";
import notifications from "./notifications";
import { NotificationType } from "../models/Notification";
import axios from "axios";
import { DateTime } from "luxon";
import { chatAdapter } from "../services/chathub";
import auth from "./auth";
import chats from "./chats";
import workflows from "./workflows";

class BoardsStore {
  loading: boolean = true;
  selectingBoard: boolean = false;
  boards: Board[] | undefined;
  selectedBoard?: Board;
  selectedAssignee?: string;
  viewOnlyAccess: boolean = false;
  sortSelectedBoardByDesc: boolean = false;
  disableSorting: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadBoards = async (withColumns?: boolean) => {
    if (!bots.selectedBot) {
      return;
    }
    this.setLoading(true);
    this.setBoards(undefined);
    const {
      data: { response: boards },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          withColumns,
        },
      }
    );

    this.setBoards(boards.map(this.boardAdapter));
    this.setLoading(false);
  };

  findDealByContact = async (contactId: number) => {
    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/item/${contactId}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    return this.boardItemCardAdapter(data);
  };

  selectBoard = async (uuid?: string, filters?: Record<string, any>) => {
    if (!bots.selectedBot || !uuid) {
      return;
    }

    this.setSelectingBoard(true);
    this.selectedBoard = undefined;
    this.disableSorting =
      Object.values(filters ?? {}).filter((f) => f).length > 0;

    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${uuid}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          ...filters,
        },
      }
    );

    const board = this.boardAdapter(data);
    board.columns = data.columns.map((m: any) => {
      m.items = m.items.map(this.boardItemCardAdapter);
      return {
        ...this.boardColumnAdapter(m),
        items: m.items,
      };
    });

    this.selectedBoard = board;

    this.setSelectingBoard(false);
  };

  createBoard = async (
    board: Omit<Board, "id" | "uuid" | "updatedAt" | "createdAt">
  ): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    const {
      data: { response: data },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        name: board.name,
      }
    );

    audit.logEvent("board_created", {
      id: data.id,
      doc: data,
      botId: bots.selectedBot.id,
    });

    const boardData = this.boardAdapter(data);
    this.setBoards([boardData, ...(this.boards || [])]);

    await this.selectBoard(data.uuid);
  };

  updateBoard = async (board: any): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    try {
      const {
        data: { response: data },
      } = await axios.put(
        `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}`,
        {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          name: board.name,
        }
      );

      const boardData = this.boardAdapter(data);

      this.setBoards(
        this.boards?.map((m) => (m.id === data.id ? boardData : m))
      );

      audit.logEvent("board_updated", {
        id: board.id,
        doc: board,
        botId: bots.selectedBot.id,
      });
    } catch (error) {
      throw error;
    }
  };

  deleteBoard = async (id: string): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    await axios.delete(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${id}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    this.setBoards(this.boards?.filter((f) => f.uuid !== id));

    this.selectedBoard = undefined;

    audit.logEvent("board_deleted", {
      id: id,
      botId: bots.selectedBot.id,
    });
  };

  loadMoreItems = async (columnId: string, skip?: number) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }
    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/load-more`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          column: columnId,
          skip: skip,
          limit: 50,
        },
      }
    );

    const items = data.map(this.boardItemCardAdapter);

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.map((m) => {
        if (m.uuid === columnId) {
          return {
            ...m,
            items: [...(m.items || []), ...items],
          };
        }
        return m;
      }),
    };
  };

  createColumn = async (
    column: Omit<BoardColumn, "id" | "updatedAt" | "createdAt">
  ): Promise<void> => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    const {
      data: { response: data },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/column`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        name: column.name,
        type: column.type,
        probability: column.probability,
        forecast_category: column.forecastCategory,
      }
    );

    const columnData = this.boardColumnAdapter(data);
    this.selectedBoard = {
      ...this.selectedBoard,
      columns: [...(this.selectedBoard?.columns || []), columnData],
    };
  };

  updateColumn = async (column: BoardColumn): Promise<void> => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    try {
      const {
        data: { response: data },
      } = await axios.put(
        `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/column/${column.uuid}`,
        {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          name: column.name,
          type: column.type,
          probability: column.probability,
          forecast_category: column.forecastCategory,
        }
      );

      this.selectedBoard = {
        ...this.selectedBoard,
        columns: this.selectedBoard?.columns?.map((m) =>
          m.id === data.id
            ? { ...this.boardColumnAdapter(data), items: m.items }
            : m
        ),
      };
    } catch (error) {
      throw error;
    }
  };

  deleteColumn = async (id: string): Promise<void> => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    await axios.delete(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/column/${id}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.filter((f) => f.uuid !== id),
    };
  };

  addItemToBoard = async (item: BoardItemCard, columnId: number) => {
    if (!this.selectedBoard || !this.selectedBoard) {
      return;
    }

    const currentColumn = this.selectedBoard.columns?.find(
      (f) => f.id === columnId
    );

    if (!currentColumn) {
      return;
    }

    const {
      data: { response },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        user_id: auth.user?.id,
        columnId: currentColumn.uuid,
        subscriber_id: item.subscriberId,
        name: item.name,
        opportunity: item.opportunity,
        assignee_id: item.assigneeId,
        due_date: item.dueDate,
        before_item_id: 0,
      }
    );
    const itemData = this.boardItemCardAdapter(response.item);
    const columnData = this.boardColumnAdapter(response.column);

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.map((m) =>
        m.uuid === currentColumn.uuid
          ? {
              ...m,
              noOfItems: columnData.noOfItems,
              sumOfOpportunity: columnData.sumOfOpportunity,
              items: [...(m.items || []), itemData],
            }
          : m
      ),
    };

    await chats.saveContactData(itemData.subscriberId, {
      dealBoardId: this.selectedBoard.id,
      assignee: itemData.assigneeId as any,
      dealId: itemData.id,
      dealStageType: currentColumn.type,
    });

    await workflows.triggerWorkflowEvent("automation_pipeline", {
      chatId: itemData.subscriberId,
      boardId: this.selectedBoard.id,
      triggered_event: "assignee_changed",
    });

    return itemData;
  };

  updateItemToBoard = async (
    item: BoardItemCard,
    columnId: number,
    oldColumn: number,
    currentAssignee?: string
  ) => {
    if (!this.selectedBoard || !this.selectedBoard) {
      return;
    }

    const column = this.selectedBoard.columns?.find((f) => f.id === columnId);

    if (!column) {
      return;
    }
    const currentItemId = column.items?.findIndex((f) => f.uuid === item.uuid);

    const {
      data: { response },
    } = await axios.put(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${item.uuid}`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        user_id: auth.user?.id,
        columnId: column.uuid,
        subscriber_id: item.subscriberId,
        name: item.name,
        opportunity: item.opportunity,
        assignee_id: item.assigneeId,
        due_date: item.dueDate,
        index: currentItemId,
        custom_fields: item.customFields,
      }
    );
    const itemData = this.boardItemCardAdapter(response.item);
    const columnsData: BoardColumn[] = response.columns.map(
      this.boardColumnAdapter
    );

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.map((m) => {
        const column = columnsData.find((f) => f.id === m.id) ?? m;
        return {
          ...m,
          sumOfOpportunity: column?.sumOfOpportunity,
          noOfItems: column?.noOfItems,
          items:
            m.items?.map((l) => (l.uuid === item.uuid ? itemData : l)) ?? [],
        };
      }),
    };

    // TODO clear old chat assignee
    await chats.saveContactData(itemData.subscriberId, {
      assignee: item.assigneeId as any,
      dealBoardId: this.selectedBoard.id,
      dealId: item.id,
      dealStageType: column?.type,
    });

    if (columnId !== oldColumn) {
      await workflows.triggerWorkflowEvent("automation_pipeline", {
        chatId: itemData.subscriberId,
        boardId: this.selectedBoard.id,
        fromColumnId: oldColumn,
        toColumnId: columnId,
      });
    }

    if (item.assigneeId !== currentAssignee) {
      await workflows.triggerWorkflowEvent("automation_pipeline", {
        chatId: itemData.subscriberId,
        boardId: this.selectedBoard.id,
        triggered_event: "assignee_changed",
      });
    }
  };

  notifyMentionedUsers = async (deal: BoardItemCard) => {
    if (!this.selectedBoard || !deal.comments?.length) {
      return;
    }
    const filter = deal.comments?.filter((f) => !f.notified);

    for (const comment of filter) {
      const userIds =
        comment.comment
          ?.match(/(@\[.*?\]\([^)]+\))/g)
          ?.map((f) => f.replace(/(@\[.*?\]\(|\))/g, "")) || [];

      const uniqueUserIds = Array.from(new Set(userIds));
      for (const userId of uniqueUserIds) {
        await notifications.createNotification(
          userId,
          NotificationType.DEAL_MENTION,
          {
            boardId: this.selectedBoard.id,
            deal,
            comment,
          }
        );
      }
    }

    return deal.comments.map((f) => {
      f.notified = true;
      return f;
    });
  };

  deleteItemFromBoard = async (item: BoardItemCard, columnId: number) => {
    if (!this.selectedBoard) {
      return;
    }
    await axios.delete(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${item.uuid}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.map((m) => {
        if (m.id === columnId) {
          return {
            ...m,
            noOfItems: m.noOfItems - 1,
            sumOfOpportunity: m.sumOfOpportunity - item.opportunity,
            items: m.items?.filter((f) => f.uuid !== item.uuid),
          };
        }
        return m;
      }),
    };

    await chats.saveContactData(item.subscriberId, {
      dealBoardId: "",
      assignee: "",
      dealId: "",
      dealStageType: "",
    });
  };

  loadComments = async (itemId: string) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return [];
    }

    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${itemId}/comment`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    return data.map(this.boardItemCommentAdapter);
  };

  createComment = async (itemId: string, comment: any) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    const {
      data: { response: data },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${itemId}/comment`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        user_id: comment.userId,
        comment: comment.comment,
      }
    );

    return this.boardItemCommentAdapter(data);
  };

  updateComment = async (itemId: string, commentId: number, comment: any) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    const {
      data: { response: data },
    } = await axios.put(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${itemId}/comment/${commentId}`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        comment: comment.comment,
        user_id: comment.userId,
      }
    );

    return this.boardItemCommentAdapter(data);
  };

  deleteComment = async (itemId: string, commentId: number) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    await axios.delete(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${itemId}/comment/${commentId}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );
  };

  setAssignee = (assignee: string) => {
    this.selectedAssignee = assignee;
  };

  setViewOnlyAccess = (viewOnlyAccess: boolean) => {
    this.viewOnlyAccess = viewOnlyAccess;
  };

  private setBoards = (boards?: Board[]) => {
    this.boards = boards?.filter((f) => !f.deletedAt);
  };

  setSelectedBoard = (board?: Board) => {
    this.selectedBoard = board;
  };

  private setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  private setSelectingBoard = (loading: boolean) => {
    this.selectingBoard = loading;
  };

  // private createDefaultBoard = async () => {
  //   if (!bots.selectedBot) {
  //     return;
  //   }

  //   const board = await this.createBoard(defaultBoard as any);

  //   if (board) {
  //     this.selectBoard(board);
  //   }
  // };

  setSortSelectedBoardByDesc = (bool: boolean) => {
    this.sortSelectedBoardByDesc = bool;
  };

  boardAdapter = (item: any): Board => {
    return {
      id: item.id,
      uuid: item.uuid,
      name: item.name,
      columns: item.columns?.map(this.boardColumnAdapter),
      createdAt: DateTime.fromISO(item.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(item.updated_at, {
        zone: "utc",
      }).toLocal(),
    };
  };

  boardColumnAdapter = (item: any): BoardColumn => {
    return {
      id: item.id,
      uuid: item.uuid,
      name: item.name,
      type: item.type,
      probability: item.probability,
      forecastCategory: item.forecast_category,
      noOfItems: item.no_of_items,
      sumOfOpportunity: item.sum_of_opportunity,
      description: item.description,
      createdAt: DateTime.fromISO(item.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(item.updated_at, {
        zone: "utc",
      }).toLocal(),
    };
  };

  boardItemCardAdapter = (item: any): BoardItemCard => {
    return {
      id: item.id,
      uuid: item.uuid,
      name: item.name,
      boardId: item.board_id,
      columnId: item.board_column_id,
      subscriberId: item.subscriber_id,
      dueDate: DateTime.fromISO(item.due_date, {
        zone: "utc",
      }).toLocal(),
      assigneeId: item.assignee_id,
      opportunity: item.opportunity,
      createdAt: DateTime.fromISO(item.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(item.updated_at, {
        zone: "utc",
      }).toLocal(),
      openedAt:
        item.opened_at &&
        DateTime.fromISO(item.opened_at, {
          zone: "utc",
        }).toLocal(),
      closedAt:
        item.closed_at &&
        DateTime.fromISO(item.closed_at, {
          zone: "utc",
        }).toLocal(),
      comments: item.comments,
      customFields: item.custom_fields,
      subscriber: chatAdapter(item.subscriber),
      histories: item.histories?.map(
        (m: any): BoardItemHistory => ({
          createdBy: m.created_by,
          currentStageId: m.current_column_id,
          newStageId: m.new_column_id,
          addedAt: DateTime.fromISO(m.created_at, {
            zone: "utc",
          }).toLocal(),
          removedAt:
            m.removed_at &&
            DateTime.fromISO(m.removed_at, {
              zone: "utc",
            }).toLocal(),
        })
      ),
    };
  };

  boardItemCommentAdapter = (item: any): BoardItemComment => {
    return {
      id: item.id,
      userId: item.user_id,
      comment: item.comment,
      createdAt: DateTime.fromISO(item.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(item.updated_at, {
        zone: "utc",
      }).toLocal(),
      notified: item.notified,
    };
  };
}

export default new BoardsStore();
