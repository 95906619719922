import React, { useCallback } from "react";
import i18n from "i18next";
import { useState, useEffect } from 'react';
import locale from "../../constants/locale";
import { logError } from "../../services/logging";
import { useStore } from "../../stores";
import { storeErrorLogs } from "../../services/chathub";
import { observer } from "mobx-react-lite";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export const ErrorBoundary = observer(({ children }: ErrorBoundaryProps) => {
  const [hasError, setHasError] = useState(false);
  const { selectedBot } = useStore("bots");

  const storeError = useCallback((error: ErrorEvent) => {
    if (!selectedBot || localStorage.getItem('storingError')) {
      return;
    }
    localStorage.setItem("storingError", "true")

    try {
      storeErrorLogs(
        selectedBot.id,
        selectedBot.key,
        selectedBot.password,
        error.message,
        error.error.stack || error.message
      ).finally(() => localStorage.removeItem("storingError"))
    } catch (error) {

    }
  }, [selectedBot])

  useEffect(() => {
    const handleError = (error: ErrorEvent) => {
      setHasError(true);
      logError(error)
      storeError(error);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, [storeError]);

  if (hasError) {
    return <h1>{i18n.t(locale.errorLoadingPage) as string}</h1>;
  }

  return <>{children}</>;
})