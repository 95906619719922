import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useField, FieldConfig } from "formik";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { Popover } from "react-tiny-popover";
import { toast } from "bulma-toast";
import firebase from "../../services/firebase";
import { Recorder } from "../recorder/Recorder";
import { LocalImage } from "../localImage/LocalImage";
import { LocalVideo } from "../localVideo/LocalVideo";
import { LocalAudio } from "../localAudio/LocalAudio";
import { MediaType } from "../../models";
import locale from "../../constants/locale";
import styles from "./MultimediaInput.module.scss";
import { Select } from "antd";
import { t } from "i18next";
import { useStore } from "../../stores";
import { EmailTemplate } from "../../models/EmailTemplates";

const MAX_ALLOWED_SIZE = 10 * 1024 * 1024; // 10 MB

type MediaData = { type: MediaType; file?: Blob };
type MultimediaValue = {
  text: string;
  media?: MediaData[];
};

type Props = {
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  allowEmailTemplate?: boolean;
} & FieldConfig<MultimediaValue>;

const PreviewFiles = ({
  files,
  onRemove,
  onUpdate,
  emailTemplates,
}: {
  files: MediaData[];
  onRemove: () => void;
  onUpdate: (e: MediaData[]) => void;
  emailTemplates: EmailTemplate[];
}) => {
  const previews = [...files].map((file) => {
    switch (file.type) {
      case "image":
        return (
          <div className={clsx("image has-rounded-corners mr-1", styles.image)}>
            <LocalImage file={file.file as Blob} alt="media" />
          </div>
        );
      case "video":
        return (
          <div
            className={clsx(
              "image is-16by9 has-rounded-corners mr-1",
              styles.video
            )}
          >
            <LocalVideo
              className="has-ratio"
              file={file.file as Blob}
              controls
            />
          </div>
        );
      case "audio":
        return (
          <div className="mr-1">
            <LocalAudio file={file.file as Blob} />
          </div>
        );
      case "document":
        return (
          <div className="is-flex has-rounded-corners has-background-light px-2 mr-1">
            <span className="icon mr-2">
              <i className="fas fa-file-pdf"></i>
            </span>
            <span className="has-ellipsis-text">
              {(file.file as File).name}
            </span>
          </div>
        );
      case "emailTemplate":
        return (
          <Select
            className="w-100"
            options={emailTemplates}
            fieldNames={{ label: "name", value: "id" }}
            onChange={(e, value) => {
              onUpdate([
                {
                  type: "emailTemplate",
                  //@ts-ignore
                  id: e,
                  name: (value as EmailTemplate).name,
                },
              ]);
            }}
            placeholder={t(locale.template) as any}
            showSearch
            filterOption
            style={{ width: "100%" }}
          />
        );
      default:
        return null;
    }
  });

  return (
    <div className={clsx("input is-scrollable-x", styles.input)}>
      {previews}
      <span className="delete ml-1" onClick={onRemove}></span>
    </div>
  );
};

export const MultimediaInput = ({ children, ...props }: Props) => {
  const { t } = useTranslation();
  const { selectedWorkspace } = useStore("workspaces");
  const [, { value, error, touched }, { setValue }] = useField(props);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showRecorder, setShowRecorder] = useState(false);
  const [emailTemplates, setTemplates] = useState<EmailTemplate[]>([]);

  const { media, text } = value || {};

  const hasSizeLimit = useMemo(
    () => (blob: Blob) => {
      if (blob.size > MAX_ALLOWED_SIZE) {
        toast({
          message: t(locale.fileIsTooLarge, { size: `10 MB` }),
          position: "top-right",
          dismissible: true,
          type: "is-warning",
          pauseOnHover: true,
        });
        return true;
      }

      return false;
    },
    [t]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue({ text: event.target.value, media });
    },
    [media, setValue]
  );

  const handleRecording = useCallback(
    (blob: Blob) => {
      setValue({ text, media: [{ type: "audio", file: blob }] });
    },
    [setValue, text]
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { files } = target;

      if (files) {
        for (const file of files) {
          if (
            ![
              "audio/mpeg",
              "audio/mp3",
              "audio/ogg",
              "audio/amr",
              "image/jpeg",
              "image/png",
              "video/mp4",
              "application/pdf",
              "text/vcard",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/msword",
            ].includes(file.type)
          ) {
            toast({
              message: t(locale.fileHasNoSupportedFormat),
              position: "top-right",
              dismissible: true,
              type: "is-warning",
              pauseOnHover: true,
            });
            return;
          }

          if (hasSizeLimit(file)) {
            return;
          }
        }

        setValue({
          text,
          media: [...files].map((m) => {
            let type: MediaType;
            if (m.type.startsWith("audio")) {
              type = "audio";
            } else if (m.type.startsWith("video")) {
              type = "video";
            } else if (m.type.startsWith("image")) {
              type = "image";
            } else {
              type = "document";
            }

            return {
              type,
              file: m,
              name: m.name,
            };
          }),
        });
      }
      setIsPopoverOpen(false);
    },
    [hasSizeLimit, setValue, t, text]
  );

  const getTemplates = useCallback(async () => {
    if (!selectedWorkspace) {
      return;
    }

    try {
      let query = firebase
        .firestore()
        .collection("workspaces")
        .doc(String(selectedWorkspace.id))
        .collection("emailTemplates");

      const snapshot = await query.orderBy("createdAt", "desc").get();

      const data = snapshot.docs.map((doc) => {
        const obj = doc.data();
        obj.id = doc.id;
        return obj;
      }) as EmailTemplate[];

      setTemplates(data);
    } catch (error) {
      console.log(error);
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  return (
    <div className="field">
      <div className="control">
        <div className="level is-mobile">
          <div className={styles.column}>
            <TextareaAutosize
              className={clsx("textarea has-fixed-size", styles.textarea)}
              placeholder={props.label}
              rows={1}
              maxRows={4}
              onChange={handleChange}
              value={text}
              disabled={props.disabled}
            />
            {!media && showRecorder && (
              <div
                className={clsx("input is-align-items-center", styles.input)}
              >
                <Recorder onRecordingAvailable={handleRecording} />
                <span
                  className="delete ml-5"
                  onClick={() => setShowRecorder(false)}
                ></span>
              </div>
            )}
            {!!media && (
              <PreviewFiles
                files={media}
                onRemove={() => setValue({ text, media: undefined })}
                onUpdate={(e) => setValue({ text, media: e })}
                emailTemplates={emailTemplates}
              />
            )}
          </div>
          <div className="level-right">
            <Popover
              isOpen={isPopoverOpen}
              positions={["top", "left"]}
              align="end"
              containerClassName={styles.popOver}
              padding={7}
              onClickOutside={() => setIsPopoverOpen(false)}
              content={() => (
                <div
                  style={{ gap: "2rem" }}
                  className="is-flex is-flex-direction-column pl-2 mb-4"
                >
                  <input
                    id="image-video-picker"
                    type="file"
                    className="is-hidden"
                    accept="image/jpeg,image/png,video/mp4"
                    multiple
                    onChange={handleFileChange}
                  />
                  <label
                    htmlFor="image-video-picker"
                    className="button is-ghost p-0"
                  >
                    <span className="">
                      <img src="/assets/file-picker.svg" alt="" />
                    </span>
                  </label>
                  <input
                    id="audio-picker"
                    type="file"
                    className="is-hidden"
                    accept=".mp3,.amr,audio/ogg"
                    multiple
                    onChange={handleFileChange}
                  />
                  <label htmlFor="audio-picker" className="button is-ghost p-0">
                    <span className="">
                      <img src="/assets/audio-picker.svg" alt="" />
                    </span>
                  </label>
                  <button
                    className="button is-ghost p-0"
                    disabled={!(window as any).MediaRecorder}
                    type="button"
                    onClick={() => {
                      setShowRecorder(true);
                      setIsPopoverOpen(false);
                    }}
                  >
                    <span className="">
                      <img src="/assets/mic-picker.svg" alt="" />
                    </span>
                  </button>
                  <input
                    id="file-picker"
                    type="file"
                    className="is-hidden"
                    accept=".pdf,.vcf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    multiple
                    onChange={handleFileChange}
                  />
                  <label htmlFor="file-picker" className="button is-ghost p-0">
                    <span className="">
                      <img src="/assets/document-picker.svg" alt="" />
                    </span>
                  </label>
                  {props?.allowEmailTemplate && (
                    <button
                      className="button is-ghost p-0"
                      disabled={!(window as any).MediaRecorder}
                      type="button"
                      onClick={() => {
                        setIsPopoverOpen(!isPopoverOpen);
                        setValue({
                          text: "Test",
                          media: [{ type: "emailTemplate", file: undefined }],
                        });
                      }}
                    >
                      <span className="">
                        <img
                          src="/assets/mail-picker.png"
                          width="50px"
                          height="50px"
                          alt=""
                        />
                      </span>
                    </button>
                  )}
                </div>
              )}
            >
              <div className="control">
                <button
                  className={clsx(
                    styles.popoverBtn,
                    "button is-ghost has-text-primary ml-2"
                  )}
                  type="button"
                  disabled={props.disabled || (showRecorder && !media)}
                  onClick={() => {
                    setIsPopoverOpen(!isPopoverOpen);
                  }}
                >
                  <span className="icon">
                    <i className="fas fa-plus"></i>
                  </span>
                </button>
              </div>
            </Popover>
          </div>
        </div>
      </div>
      {touched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};
