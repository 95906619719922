import { makeAutoObservable } from "mobx";
import { create, persist } from "mobx-persist";
import firebase from "firebase/compat/app";
import i18next from "i18next";
import { Settings as LuxonSettings } from "luxon";

import { SupportedLanguage, UserSettings } from "../models";

import { updateVideoCallAbility } from "../services/chathub";

import auth from "./auth";
import bots from "./bots";

class SettingsStore {
  @persist darkMode: "auto" | "activated" | "deactivated" = "auto";
  @persist authPersist: boolean = true;
  @persist language: SupportedLanguage = SupportedLanguage.en;
  @persist showRequestedChatsNotifications = true;
  @persist showMyChatsNotifications = true;

  constructor() {
    makeAutoObservable(this);

    i18next.on("languageChanged", this.setUp);
  }

  private setUp = (lng: string) => {
    LuxonSettings.defaultLocale = lng;
    firebase.auth().languageCode = lng;
  };

  setDarkMode = (darkMode: "auto" | "activated" | "deactivated") => {
    this.darkMode = darkMode;
  };

  setAuthPersist = async (authPersist: boolean) => {
    this.authPersist = authPersist;
    await firebase
      .auth()
      .setPersistence(
        authPersist
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION
      );
  };

  setLanguage = async (language: SupportedLanguage) => {
    this.language = language;
    i18next.changeLanguage(language);

    const currUser = firebase.auth().currentUser;

    if (!currUser) {
      return;
    }

    await firebase.firestore().collection("users").doc(currUser.uid).update({
      preferredLanguage: language,
    });
  };

  saveUserSettings = async (settings: UserSettings) => {
    if (!auth.user || !bots.selectedBot) {
      return;
    }

    await updateVideoCallAbility(
      bots.selectedBot.id,
      auth.user.id,
      settings.disableVideoCall
    );

    await auth.updateCurrentUser({
      ...auth.user,
      settings,
    });
  };

  setShowRequestedChatsNotifications = (
    showRequestedChatsNotifications: boolean
  ) => {
    this.showRequestedChatsNotifications = showRequestedChatsNotifications;
  };

  setShowMyChatsNotifications = (showMyChatsNotifications: boolean) => {
    this.showMyChatsNotifications = showMyChatsNotifications;
  };
}

const settings = new SettingsStore();

export default settings;

create()("settings", settings);
