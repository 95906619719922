import { makeAutoObservable } from "mobx";
import { Feature, Plan, CurrentPlan, Addon, Subscription, AddonEnum } from "../models/Plan";
import firebase from "firebase/compat/app";
import { DateTime } from "luxon";
import { features } from "./utils";
import { BotSettings } from "../models";
import bots from "./bots";

class BillingStore {
  private db: firebase.firestore.Firestore;
  features: Feature[];
  plans: Plan[];
  currentPlan: CurrentPlan;
  minAdditionalSeats = 0
  totalNoOfUsers = 0;

  constructor() {
    this.db = firebase.firestore();

    makeAutoObservable(this);
    this.features = features

    this.plans = [
      {
        key: "starter",
        name: "starter",
        price: 199
      },
      {
        key: "pro",
        name: "pro",
        price: 299
      },
      {
        key: "premium",
        name: "premium",
        price: 399
      },
    ]

    this.currentPlan = {
      ...this.plans[0],
      addons: []
    }
  }

  setCurrentPlan = (currentPlan: CurrentPlan) => {
    // this.minAdditionalSeats = this.totalNoOfUsers;

    if (bots.selectedBot?.subscription?.plan.key === currentPlan.key) {
      this.currentPlan = JSON.parse(JSON.stringify(bots.selectedBot.subscription.plan))
    } else {
      this.currentPlan = currentPlan
    }

    // limit the no of seats
    const additinalSeats = bots.selectedBot?.subscription
      ?.plan?.addons
      ?.find(f => f.key === AddonEnum.ADDITIONAL_SEATS)

    if (additinalSeats) {

      const seatAddon = features.find(p => p.key === AddonEnum.ADDITIONAL_SEATS)

      if (!seatAddon) { return null }

      const noOfUsers = this.totalNoOfUsers;

      const addonBasePrice = seatAddon.data.basePrice || 0
      let value = noOfUsers || 0
      let minValue = seatAddon.data.minOrMax[currentPlan.key][0]

      if (noOfUsers < minValue) {
        minValue = 0
        value = 0;
      }

      if (value >= noOfUsers) {
        this.minAdditionalSeats = noOfUsers
      }

      this.addAddon({
        key: seatAddon.key,
        name: seatAddon.name,
        price: (addonBasePrice * value) - (addonBasePrice * minValue),
        data: {
          value,
          noOfUsers
        }
      })
    }

  }

  addAddon = (addon: Addon) => {
    const index = this.getAddonIndexFromCurrentPlan(addon)

    if (index === -1) {
      this.currentPlan.addons.push(addon)
    }
  }

  updateAddon = (addon: Addon) => {
    const index = this.getAddonIndexFromCurrentPlan(addon)

    if (index > -1) {
      this.currentPlan.addons[index] = addon
    }
  }

  deleteAddon = (addon: Addon) => {
    const index = this.getAddonIndexFromCurrentPlan(addon)

    if (index > -1) {
      this.currentPlan.addons.splice(index, 1)
    }
  }

  getAddonIndexFromCurrentPlan = (addon: Addon) => {
    return this.currentPlan.addons
      .findIndex(f => f.name === addon.name)
  }

  getCurrentPlanPricing = () => {
    return this.currentPlan.price +
      this.currentPlan.addons.map(f => f.price || 0)
        .reduce((a, b) => a + b, 0)
  }

  // setSubscription = (subscription: Subscription) => {
  //   this.subscription = subscription
  // }

  getPaymentHistories = async (botId: number, limit: number, createdAt: string) => {
    try {
      const startMonth = DateTime.fromFormat(createdAt, 'MMMM yyyy');
      const endMonth = startMonth.endOf('month');

      const startTimestamp = firebase.firestore.Timestamp.fromDate(startMonth.toJSDate());
      const endTimestamp = firebase.firestore.Timestamp.fromDate(endMonth.toJSDate());

      const snapshot = await this.db
        .collection("bots")
        .doc(String(botId))
        .collection("paymentHistories")
        .where('createdAt', '>=', startTimestamp)
        .where('createdAt', '<=', endTimestamp)
        .orderBy('createdAt', 'desc')
        .limit(limit)
        .get()

      return snapshot.docs.map(doc => doc.data())
    } catch (e) {
      console.log(e);
      return []
    }

  }

  getInvoices = async (botId: number, limit: number, createdAt: string) => {
    try {
      const startMonth = DateTime.fromFormat(createdAt, 'MMMM yyyy');
      const endMonth = startMonth.endOf('month');

      const startTimestamp = firebase.firestore.Timestamp.fromDate(startMonth.toJSDate());
      const endTimestamp = firebase.firestore.Timestamp.fromDate(endMonth.toJSDate());

      const snapshot = await this.db
        .collection("bots")
        .doc(String(botId))
        .collection("invoices")
        .where('createdAt', '>=', startTimestamp)
        .where('createdAt', '<=', endTimestamp)
        .orderBy('createdAt', 'desc')
        .limit(limit)
        .get()

      return snapshot.docs.map(doc => doc.data())
    } catch (e) {
      console.log(e);
      return []
    }
  }


  updateBotSettings = async (botId: number, settings: BotSettings) => {
    await this.db.collection("bots")
      .doc(`${botId}`)
      .set({
        settings
      },
        {
          merge: true
        })
  }

  getLiveUsages = async (botId: number, createdAt: string) => {

    const startMonth = DateTime.fromFormat(createdAt, 'MMMM yyyy');
    const endMonth = startMonth.endOf('month');

    const startTimestamp = firebase.firestore.Timestamp.fromDate(startMonth.toJSDate());
    const endTimestamp = firebase.firestore.Timestamp.fromDate(endMonth.toJSDate());

    const snapshot = await this.db
      .collection("bots")
      .doc(String(botId))
      .collection("LiveUsages")
      .where('createdAt', '>=', startTimestamp)
      .where('createdAt', '<=', endTimestamp)
      .get()

    return snapshot.docs.map(doc => doc.data())
  }

  removeOneSeatFromSubscription = async (botId: number, subscription?: Subscription) => {
    // if default seats donot update invoice
    let seatAddon = subscription
      ?.plan?.addons
      ?.find(a => a.key === AddonEnum.ADDITIONAL_SEATS);

    if (!seatAddon) { return }

    const defaultAddon = features
      .find(a => a.key === AddonEnum.ADDITIONAL_SEATS);

    const mixMaxSeats = defaultAddon?.data.minOrMax?.[subscription?.plan?.key as string]

    if (seatAddon.data.value > mixMaxSeats[0]) {
      const subscriptionCB = firebase
        .functions()
        .httpsCallable("updateSubscription");

      seatAddon.data.value -= 1;

      if (seatAddon.price) {
        seatAddon.price -= defaultAddon?.data?.basePrice || 0
      }

      this.updateAddon(seatAddon)

      await subscriptionCB({
        botId: botId,
        plan: this.currentPlan,
        price: this.getCurrentPlanPricing()
      })
    }

  }

  isDowngrading = () => {
    const plans = this.plans.map(m => m.key);

    if (!bots.selectedBot?.subscription) {
      return false
    }

    const currentPlanIndex = plans.indexOf(bots.selectedBot?.subscription?.plan.key);
    const newPlanIndex = plans.indexOf(this.currentPlan.key);

    return currentPlanIndex > newPlanIndex;
  }

  getNoOfUsersInCurrentBot = async () => {
    try {
      const snapshot = await this.db.collection('users')
        .where('botsIds', 'array-contains', bots.selectedBot?.id)
        .get();

      const count = snapshot.size - 1;
      this.totalNoOfUsers = count;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
}


export default new BillingStore();
