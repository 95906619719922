import { useCallback, useEffect, useState } from 'react';

const useMobileResponsive = (breakpoint = 768) => {
	const [isMobile, setIsMobile] = useState(false);

	const handleResize = useCallback(() => {
		setIsMobile(window.innerWidth < breakpoint);
	}, [breakpoint])

	useEffect(() => {
		// Set initial mobile state on component mount
		handleResize();

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize]);

	return isMobile;
};

export default useMobileResponsive;
