import { ChatChannel } from "./Chat";

export type Metric = {
  name: string;
  channel?: ChatChannel;
  params?: Record<string, string>;
};

export const getMetricId = (metric: Metric) =>
  `${metric.name}_${metric.channel || ""}${
    Object.keys(metric.params || {}).length
      ? `_${btoa(
          Object.entries(metric.params || {})
            .map(([k, v]) => `${k}_${v}`)
            .join("_")
        )}`
      : ""
  }`;

export type MetricData = {
  time: Date;
  value: number;
};

export type TopDialogData = {
  value: string;
  count: number;
};

export type SourceInfo = {
  channel: ChatChannel;
  conversations: number;
  connected: number;
  disconnected: number;
};

export type TeamPerformanceInfo = {
  agentId: string;
  conversations: number;
  messages_sent: number;
  mean_time_to_connect: number;
  average_handling_time: number;
};
