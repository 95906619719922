const routes = {
  home: "/",
  loginOAuthAuthorize: "/login/oauth/authorize",
  auth: "/auth",
  notFound: "/404",
  signUp: "/sign-up",
  signIn: "/sign-in",
  forgotPassword: "/forgot-password",
  helpCenterUserViewHome: "/help-center/:workspaceId",
  helpCenterUserViewCollection:
    "/help-center/:workspaceId/collection/:collectionId",
  helpCenterUserViewArticle: "/help-center/:workspaceId/article/:articleId",
  helpCenterUserViewSearch: "/help-center/:workspaceId/search",
  booking: "/booking/:bookingWorkspaceId/:eventId",
  terms: "/terms",
  linkBot: "/link-bot",
  joinWorkspace: "/join-workspace",
  mfa: "/verify",

  redirectCallback: "/redirect-callback/:provider",

  //workspace routes
  createWorkspace: "/workspace/create",
  noWorkspace: "/workspace/no-workspace",
  workspaceJoin: "/workspace/join",
  workspaceNoAccess: "/workspace/:workspaceId/no-access",
  workspaceHome: "/workspace/:workspaceId",
  workspaceSettings: "/workspace/:workspaceId/settings",
  teammates: "/workspace/:workspaceId/teammates/:userId?",
  aITeamChats: "/workspace/:workspaceId/ai-teams-chats/:teamId",
  users: "/workspace/:workspaceId/users",
  helpCenter: "/workspace/:workspaceId/helpcenter-config",
  orgKnowledge: "/workspace/:workspaceId/org-knowledge/:folderId?",
  aITeams: "/workspace/:workspaceId/ai-teams/:teamId?",
  emailTemplate: "/workspace/:workspaceId/email-template",
  emailTemplateCreate: "/workspace/:workspaceId/email-template/create",
  emailTemplateEdit: "/workspace/:workspaceId/email-template/:emailTemplateId",
  ednaBotSettings: "/workspace/:workspaceId/edna-bot-settings",
  calendar: "/workspace/:workspaceId/calendar",
  activity: "/workspace/:workspaceId/activity",
  errorLogs: "/:botId/error-logs",
  //end workspace routes

  //function routes
  chats: "/workspace/:workspaceId/:functionId/chats/:chatId?",
  cannedResponses: "/workspace/:workspaceId/:functionId/responses",
  contacts: "/workspace/:workspaceId/:functionId/contacts",
  workflows: "/workspace/:workspaceId/:functionId/workflows/:categoryId?",
  workflowEdit:
    "/workspace/:workspaceId/:functionId/workflows/:workflowId/edit",
  settings: "/workspace/:workspaceId/:functionId/settings",
  groupChats: "/workspace/:workspaceId/:functionId/group-chats/:group",
  groupCalendar: "/workspace/:workspaceId/:functionId/group-calendar/:group",
  tickets: "/workspace/:workspaceId/:functionId/tickets/:group",
  analytics: "/workspace/:workspaceId/:functionId/analytics",
  analyticsKpi: "/workspace/:workspaceId/:functionId/analytics/manage-kpi",
  liveStream: "/workspace/:workspaceId/:functionId/live/:chatId?",
  campaigns: "/workspace/:workspaceId/:functionId/campaigns",
  campaignWhatsappTemplate:
    "/workspace/:workspaceId/:functionId/campaigns/whatsapp-template",
  campaignWhatsappTemplateCreate:
    "/workspace/:workspaceId/:functionId/campaigns/whatsapp-template/create",
  campaignWhatsappTemplateShow:
    "/workspace/:workspaceId/:functionId/campaigns/whatsapp-template/:whatsappTemplateId",
  pastCampaignMessages:
    "/workspace/:workspaceId/:functionId/campaigns/past/:campaignId",
  pastCampaigns: "/workspace/:workspaceId/:functionId/campaigns/past",
  board: "/workspace/:workspaceId/:functionId/board/:boardId?",
  boardDashboard:
    "/workspace/:workspaceId/:functionId/board/:boardId/dashboard",
  billing: "/workspace/:workspaceId/billing",
  setup: "/workspace/:workspaceId/:functionId/setup",
  catalog: "/workspace/:workspaceId/:functionId/catalog",
  commerceBranches: "/workspace/:workspaceId/:functionId/commerce/branches",
  commerceBranchesAdd:
    "/workspace/:workspaceId/:functionId/commerce/branches/add",
  commerceBranchView:
    "/workspace/:workspaceId/:functionId/commerce/branches/:branchId",
  commerceProducts: "/workspace/:workspaceId/:functionId/commerce/products",
  commerceProfile: "/workspace/:workspaceId/:functionId/commerce/profile",
  //end function routes
};

// Coomerce Web View
export const webViewRoutes = {
  webViewMenuItemModifier: "/web_view/add_menu_item",
  webViewConfirm: "/web_view/confirm",
  webViewDelivery: "/web_view/delivery",
  webViewBranch: "/web_view/branch",
  webViewCheckOutEdnaPay: "/web_view/checkoutEdnaPay",
  webViewOrder: "/web_view/order/:restaurantId",
  webViewOrderMenu: "/web_view/order/:restaurantId/:branchId/menu",
  webViewOrderCheckout: "/web_view/order/:restaurantId/:branchId/checkout",
  webViewOrderComplete: "/web_view/order/:restaurantId/:branchId/complete",
  webViewOrderTracking: "/web_view/order/:restaurantId/track",
};

export const publicRoutes = [
  routes.notFound,
  routes.helpCenterUserViewHome,
  routes.helpCenterUserViewCollection,
  routes.helpCenterUserViewArticle,
  routes.helpCenterUserViewSearch,
  routes.booking,
  routes.signIn,
  routes.signUp,
  routes.terms,
  routes.mfa,
  routes.auth,
  routes.createWorkspace,
  routes.joinWorkspace,
  routes.loginOAuthAuthorize,
  routes.forgotPassword,
];

export const notExact: string[] = [];

export default routes;
