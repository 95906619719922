import { observer } from "mobx-react-lite";
import { AppModule, UserType } from "../../models";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import DesktopNavbar from "./components/DesktopNavbar";
import MobileNavbar from "./components/MobileNavbar";

export type SubChildMenu = {
  to: string;
  text: string;
  active: boolean;
};

export type SubMenu = {
  to?: string;
  text: string;
  module: AppModule;
  icon: string;
  iconClass?: string;
  active?: boolean;
  roles?: UserType[];
  children?: SubChildMenu[];
  isEllipsis?: boolean;
};

export type Menu = {
  text: string;
  children: SubMenu[];
};

export const NavBar = observer(() => {
  const isMobile = useMobileResponsive();

  return <>{isMobile ? <MobileNavbar /> : <DesktopNavbar />}</>;
});
