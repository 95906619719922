import React, { FC, useEffect, useMemo, useRef } from "react";

import { DeliveryZone, Location } from "../../models";

type Props = {
  defaultLocation?: Location;
  zoom?: number;
  style?: any;
  className?: string;
  options?: any;
  zones: DeliveryZone[];
  colors: Map<DeliveryZone, string>;
  onRadiusChange: (index: number, radius: number) => void;
};

const MAP_VIEW_ID =
  "google-map-view-" + Math.random().toString(36).substr(2, 9);

const MapCoverage: FC<Props> = ({
  defaultLocation = { latitude: 0, longitude: 0 },
  zoom = 14,
  style,
  className,
  options,
  zones,
  colors,
  onRadiusChange,
}) => {
  const map = useRef<google.maps.Map>();
  const onRadiusChangeRef =
    useRef<(index: number, radius: number) => void>(onRadiusChange);
  const circles = useMemo(
    () => new Map<DeliveryZone, google.maps.Circle>(),
    []
  );
  onRadiusChangeRef.current = onRadiusChange;

  useEffect(() => {
    const Google = (window as any).google;

    map.current = new Google.maps.Map(document.getElementById(MAP_VIEW_ID), {
      center: { lat: defaultLocation.latitude, lng: defaultLocation.longitude },
      zoom: zoom,
      disableDefaultUI: true,
      zoomControl: true,
      ...options,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    map.current?.setCenter({
      lat: defaultLocation.latitude,
      lng: defaultLocation.longitude,
    });
  }, [defaultLocation]);

  useEffect(() => {
    map.current?.setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    if (!map.current) {
      return;
    }

    zones.forEach((z, i) => {
      if (circles.has(z)) {
        return;
      }

      const circle = new google.maps.Circle({
        editable: true,
        center: map.current?.getCenter(),
        map: map.current,
        radius: z.radius,
        zIndex: 99999999 - (z.radius || 0),
        fillColor: colors.get(z),
        strokeColor: colors.get(z),
        fillOpacity: 0.1,
      });

      const infowindow = new google.maps.InfoWindow({});
      const marker = new google.maps.Marker({
        map: map.current,
      });

      circle.addListener("mouseover", function () {
        //@ts-ignore
        marker.setPosition(this.getCenter());
        infowindow.setContent("<b>" + (i + 1) + "</b>");
        infowindow.open(map.current, marker); // open at marker's location
        marker.setVisible(false); // hide the marker
      });

      circle.addListener("mouseout", function () {
        infowindow.close();
      });

      circle.addListener("radius_changed", function () {
        //@ts-ignore
        this.setOptions({ zIndex: 99999999 - this.getRadius() });
        //@ts-ignore
        onRadiusChangeRef.current(i, this.getRadius());
      });

      circles.set(z, circle);
    });

    const toRemove: DeliveryZone[] = [];
    circles.forEach((v, k) => {
      if (zones.includes(k)) {
        if (k.radius && k.radius !== v.getRadius()) {
          v.setRadius(k.radius);
        }
        if (v.get("strokeColor") !== colors.get(k)) {
          v.setOptions({
            strokeColor: colors.get(k),
            fillColor: colors.get(k),
          });
        }

        return;
      }

      v.setMap(null);
      toRemove.push(k);
    });

    toRemove.forEach((f) => circles.delete(f));
  }, [circles, colors, onRadiusChange, zones]);

  const componentStyle = Object.assign(
    { width: "100%", height: "50vh" },
    style || {}
  );

  return (
    <div id={MAP_VIEW_ID} style={componentStyle} className={className}></div>
  );
};

export default MapCoverage;
