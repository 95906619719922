import { useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";

type Props = {
  isOpen: boolean;
  title?: string | (() => React.ReactChild);
  children?: any;
  primaryText?: string | (() => React.ReactChild);
  primaryLoading?: boolean;
  disablePrimary?: boolean;
  secondaryText?: string;
  secondaryLoading?: boolean;
  disableSecondary?: boolean;
  disableWhenLoading?: boolean;
  isFullScreen?: boolean;
  isFullScreenMobile?: boolean;
  isMediumScreen?: boolean;
  isWideScreenH?: boolean;
  isLargeScreen?: boolean;
  isLargeXLScreen?: boolean;
  isNonScrollable?: boolean;
  contentContainerClassName?: string;
  smallHeader?: boolean;
  onClose?: () => void;
  onPrimary?: () => void;
  onSecondary?: () => void;
  allowCardOverflow?: boolean;
};

export const Modal = ({
  isOpen,
  title,
  children,
  primaryText,
  primaryLoading,
  secondaryText,
  secondaryLoading,
  disableWhenLoading,
  disablePrimary,
  disableSecondary,
  isFullScreen,
  isLargeScreen,
  isLargeXLScreen,
  isFullScreenMobile,
  isMediumScreen,
  isWideScreenH,
  isNonScrollable,
  contentContainerClassName,
  smallHeader,
  onClose,
  onPrimary,
  onSecondary,
  allowCardOverflow,
}: Props) => {
  const firstRender = useRef(true);
  const container = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    document.body?.appendChild(container);
    return () => {
      document.body?.removeChild(container);
    };
  }, [container]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (!isOpen) return;
    }

    if (isOpen) {
      if (document.body.parentElement)
        document.body.parentElement.style.overflow = `hidden`;
    }

    return () => {
      if (document.body.parentElement)
        document.body.parentElement.style.overflow = "";
    };
  }, [isOpen]);

  container.className = clsx("modal", {
    "is-active": isOpen,
    "is-largescreen": isLargeScreen,
    "is-large-xl-screen": isLargeXLScreen,
    "is-fullscreen": isFullScreen,
    "is-fullscreen-mobile": isFullScreenMobile,
    "is-mediumscreen": isMediumScreen,
    "no-scrollable": isNonScrollable,
    "is-widescreen-h": isWideScreenH,
    "allow-card-overflow": allowCardOverflow,
  });

  const modal = (
    <>
      <div className="modal-background" />
      <div className="modal-card">
        <div
          style={{ display: "flex" }}
          className="is-justify-content-end mt-4 mr-4"
        >
          {!!onClose && (
            <button
              type="button"
              className="button is-ghost"
              aria-label="close"
              disabled={
                disableWhenLoading && (primaryLoading || secondaryLoading)
              }
              onClick={onClose}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1117 0.984375L1.11169 10.9844"
                  stroke="#2A79EF"
                  stroke-width="1.46528"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.11169 0.984375L11.1117 10.9844"
                  stroke="#2A79EF"
                  stroke-width="1.46528"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          )}
        </div>
        {title && (
          <header
            className="modal-card-head"
            style={smallHeader ? { height: "1rem" } : {}}
          >
            {typeof title === "string" ? (
              <p className="modal-card-title has-ellipsis-if-long">{title}</p>
            ) : (
              title()
            )}
          </header>
        )}
        {children && (
          <section
            className={clsx("modal-card-body", contentContainerClassName)}
          >
            {children}
          </section>
        )}

        {(!!primaryText || !!secondaryText) && (
          <footer className="modal-card-foot">
            {!!primaryText && (
              <button
                type="button"
                className={clsx(
                  "button has-text-weight-medium custom-button w-100",
                  "is-primary",
                  {
                    "is-loading": primaryLoading,
                  }
                )}
                disabled={
                  disablePrimary || (disableWhenLoading && secondaryLoading)
                }
                onClick={onPrimary}
              >
                {typeof primaryText == "string" ? primaryText : primaryText()}
              </button>
            )}

            {!!secondaryText && (
              <button
                type="button"
                className={clsx("button custom-button", {
                  "is-loading": secondaryLoading,
                  "w-100": !!primaryText,
                })}
                disabled={
                  disableSecondary || (disableWhenLoading && primaryLoading)
                }
                onClick={onSecondary}
              >
                {secondaryText}
              </button>
            )}
          </footer>
        )}
      </div>
    </>
  );

  return createPortal(modal, container);
};
