import { Mention, MentionsInput } from "react-mentions";

const defaultMentionStyle = {
  backgroundColor: "#ecf3fe",
  borderRadius: 2,
  padding: "0",
};

const defaultStyle = {
  control: {
    backgroundColor: "#fff",
    backgroundImage: "none",
    transition: "all 0.2s",
    // fontSize: 14,
    // fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      // fontFamily: "monospace",
      // minHeight: 63
      // padding: 6,
    },
    highlighter: {
      boxSizing: "border-box",
      overflow: "hidden",
      maxHeight: 80,
      padding: 6,
      // border: "1px solid transparent",
      fontWeight: 500,
      fontSize: 12,
    },
    input: {
      padding: 6,
      overflow: "auto",
      border: "none",
      outline: "none",
      maxHeight: 80,
      fontWeight: 500,
      fontSize: 12,
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: "100%",
    highlighter: {
      padding: 6,
      border: "2px inset transparent",
    },
    input: {
      padding: 6,
      border: "1px solid #d9d9d9",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#d9d9d9",
      borderRadius: "6px",
      "&focused": {
        backgroundColor: "#85e3db",
      },
    },
  },

  suggestions: {
    zIndex: 99999999999999,
    list: {
      backgroundColor: "white",
      border: "1px solid #ecf3fe",
      borderRadius: 5,
      fontSize: 12,
      maxHeight: 200,
      overflowX: "hidden",
      overflowY: "auto",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid #ecf3fe",
      "&focused": {
        backgroundColor: "#ecf3fe",
      },
    },
  },
  // input: {
  //   overflow: "auto",
  //   height: 70,
  // },
  // highlighter: {
  //   boxSizing: "border-box",
  //   overflow: "hidden",
  //   height: 70,
  // },
};

function Mentions({
  refMention,
  trigger,
  data,
  value,
  onChange,
  isMultiline,
  renderSuggestion,
  displayTransform,
  markup,
  placeholder = undefined,
  appendSpaceOnAdd = false,
  onKeyDown,
  disabled,
  onPaste,
}: {
  refMention?: any;
  trigger: string;
  data: any;
  value?: string;
  onChange?: any;
  isMultiline?: boolean;
  renderSuggestion: any;
  displayTransform: any;
  markup: any;
  placeholder?: string;
  appendSpaceOnAdd?: boolean;
  onKeyDown?: any;
  disabled?: boolean;
  onPaste?: any;
}) {
  return (
    <MentionsInput
      inputRef={refMention}
      value={value}
      onChange={onChange}
      style={defaultStyle}
      singleLine={isMultiline}
      placeholder={placeholder}
      allowSuggestionsAboveCursor={true}
      onKeyDown={onKeyDown}
      disabled={disabled}
      onPaste={onPaste}
    >
      <Mention
        trigger={trigger}
        data={data}
        renderSuggestion={renderSuggestion}
        style={defaultMentionStyle}
        displayTransform={displayTransform}
        markup={markup}
        appendSpaceOnAdd={appendSpaceOnAdd}
      />
    </MentionsInput>
  );
}

export default Mentions;
