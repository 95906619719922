import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
// import { ToastContainer, Slide } from "react-toastify";

import routes, { webViewRoutes } from "./constants/routes";

import { getWebViewPage } from "./pages";
import { useStore } from "./stores";

const AutoSelect = observer(({ Comp }: { Comp: any }) => {
  const { restaurantId, branchId } = useParams<{
    restaurantId?: string;
    branchId?: string;
  }>();

  const { selectBranch, selectRestaurant, isLoading } = useStore("restaurants");

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const select = async () => {
      if (restaurantId) await selectRestaurant(restaurantId);
      if (branchId) await selectBranch(branchId);
    };

    select();
  }, [branchId, isLoading, restaurantId, selectBranch, selectRestaurant]);

  return <Comp />;
});

const ScrollReset = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const WebViewApp = () => {
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--root-position", "unset");
  }, []);

  return (
    <React.Suspense fallback={null}>
      <Router>
        <ScrollReset />
        <div className="section w-100" style={{ padding: "3rem 0" }}>
          <React.Suspense fallback={null}>
            <Switch>
              {Object.values(webViewRoutes).map((route) => (
                <Route
                  key={route}
                  path={route}
                  exact
                  render={() => <AutoSelect Comp={getWebViewPage(route)} />}
                />
              ))}
              <Redirect to={routes.notFound} />
            </Switch>
          </React.Suspense>
        </div>
      </Router>
    </React.Suspense>
  );
};

export default WebViewApp;
