import { useEffect, useState } from "react";
import { loadScript } from "../utils/scripts";

const useGoogleMapsLib = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?v=3.46&key=${
        (window as any).firebaseAppConfig.apiKey
      }&libraries=geometry,places`,
      "google-maps-js",
      () => {
        // Check if the Google Maps API is available
        if (window.google && window.google.maps) {
          setReady(true);
        } else {
          // If not available, wait and try again
          setTimeout(() => {
            if (window.google && window.google.maps) {
              setReady(true);
            }
          }, 1000);
        }
      }
    );
  }, []);

  return { ready };
};

export default useGoogleMapsLib;
