import { makeAutoObservable } from "mobx";
import {
  EdnaChat,
  EdnaChatType,
  EdnaMessage,
  EdnaMessageParser,
} from "../models/EdnaChat";
import firebase from "firebase/compat/app";
import workspaces from "./workspaces";
import auth from "./auth";
import firebaseApp from "firebase/compat/app";
import { logError } from "../services/logging";
import locale from "../constants/locale";
import i18n from "../services/localization";
import ednaChats from "./ednaChats";
import notifications from "./notifications";
import { NotificationType } from "../models/Notification";
import users from "./users";
import { UserType } from "../models";

class EdnaThreadChatsStore {
  private db: firebase.firestore.Firestore;
  private notificationAudio = new Audio("/assets/notification.mp3");
  private lastChatMessage = "";
  threadOpen?: boolean;
  selectedChat?: EdnaChat;
  messages: EdnaMessage[] = [];
  findingChat = false;
  private loadingMessage = false;

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  firstOrCreateChat = async (
    room: string,
    chatId: string,
    messageId: string
  ) => {
    this.clearChatAndMessages();

    if (!workspaces.selectedWorkspace || !auth.user || this.findingChat) {
      return;
    }

    this.threadOpen = true;
    this.findingChat = true;
    let chat: EdnaChat;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .where("type", "==", EdnaChatType.THREAD)
      .where("threadChatId", "==", chatId)
      .where("threadMessageId", "==", messageId)
      .limit(1)
      .get();

    if (snapshot.empty) {
      chat = {
        type: EdnaChatType.THREAD,
        threadChatType: ednaChats.selectedChat?.type,
        room,
        threadMessageId: messageId,
        threadChatId: chatId,
        createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      };

      const docRef = await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("ednaChats")
        .add(chat);

      const snapshot = await docRef.get();
      chat = snapshot.data() as EdnaChat;
      chat.id = docRef.id;

      const messageThread = ednaChats.messages.find(
        (f) => f.id === messageId
      ) as EdnaMessage;

      const messageDocRef = await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("ednaChats")
        .doc(chat.id)
        .collection("messages")
        .add(messageThread);

      const messageSnapshot = await messageDocRef.get();
      const newMessage = messageSnapshot.data() as EdnaMessage;
      newMessage.id = docRef.id;

      this.insertNewMessage(newMessage);
    } else {
      chat = snapshot.docs[0].data() as EdnaChat;
      chat.id = snapshot.docs[0].id;
    }

    this.setChat(chat);
    this.findingChat = false;
  };

  sendMessage = async (message: EdnaMessageParser) => {
    if (!workspaces.selectedWorkspace || !auth.user || !this.selectedChat?.id) {
      return;
    }

    const messageObj: EdnaMessage = {
      userId: auth.user.id,
      message,
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };

    const docRef = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .collection("messages")
      .add(messageObj);

    const snapshot = await docRef.get();
    const newMessage = snapshot.data() as EdnaMessage;
    newMessage.id = docRef.id;

    this.insertNewMessage(newMessage);

    await this.increaseRepliesInThreadMessage();
    await this.updateThreadInteractions();
    await ednaChats.mentionedUsers(this.selectedChat, newMessage);
    await this.sendNotification(newMessage);
  };

  loadMessages = async (pageSize = 100) => {
    if (
      !workspaces.selectedWorkspace ||
      !this.selectedChat?.id ||
      this.loadingMessage
    ) {
      return;
    }

    this.loadingMessage = true;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .collection("messages")
      .orderBy("createdAt", "desc")
      .get();

    const messages = snapshot.docs.map((f) => {
      const ref = f.data();
      ref.id = f.id;
      return ref;
    }) as EdnaMessage[];

    this.setMessages(messages);

    const lastMessage =
      messages.slice().find((f) => f.userId !== auth.user?.id)?.id || "";

    if (this.lastChatMessage && lastMessage !== this.lastChatMessage) {
      this.playNotification();
    }

    this.updateChat();

    this.lastChatMessage = lastMessage;

    this.loadingMessage = false;
  };

  closeThreadChat = () => {
    this.threadOpen = false;
    this.selectedChat = undefined;
    this.messages = [];
  };

  private sendNotification = async (message: EdnaMessage) => {
    if (!workspaces.selectedWorkspace) {
      return;
    }
    const userIds = [];

    if (ednaChats.selectedChat?.type === EdnaChatType.SINGLE) {
      const room = ednaChats.selectedChat.room;
      const userId = room.split("|").find((f) => f !== auth.user?.id);
      if (userId) {
        userIds.push(userId);
      }
    } else if (ednaChats.selectedChat?.type === EdnaChatType.CHANNEL) {
      const room = ednaChats.selectedChat.room;
      const liveChatId = parseInt(room.replace("livecard-", ""));
      if (liveChatId) {
        const filteredUsers = users?.users
          ?.filter((f) => f.id !== auth.user?.id)
          ?.filter((user) => {
            if ([UserType.Root, UserType.Administrator].includes(user?.type!)) {
              return true;
            }
            const liveChatFilters =
              user.permissions?.perWorkspace?.[
                workspaces.selectedWorkspace?.id!
              ]?.perFunction?.["op"]?.liveChatFilters ?? {};

            const keysWithNonEmptyValues = Object.keys(liveChatFilters).filter(
              (key) => liveChatFilters[key].length > 0
            );

            return keysWithNonEmptyValues
              .map((m) => parseInt(m))
              .includes(liveChatId);
          });
        userIds.push(...(filteredUsers?.map((f) => f.id) || []));
      }
    }

    for (const userId of userIds) {
      await notifications.createNotification(userId, NotificationType.THREAD, {
        chatName: String(ednaChats.chatInfo?.name),
        parentChat: ednaChats.selectedChat!,
        chat: this.selectedChat!,
        message,
      });
    }
  };

  private playNotification = async () => {
    this.notificationAudio.play().catch(function (error) {
      console.log("Chrome cannot play sound without user interaction first");
    });

    if (document.visibilityState === "visible") {
      return;
    }

    try {
      await Notification.requestPermission();
      new Notification("EDNA Platform", {
        body: i18n.t(locale.newMessageReceived),
      });
    } catch (error) {
      logError(error);
    }
  };

  private async updateChat() {
    if (!workspaces.selectedWorkspace || !this.selectedChat) return;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .get();

    const chat = snapshot.data() as EdnaChat;

    this.setChat({
      ...this.selectedChat,
      ...chat,
    });
  }

  private async increaseRepliesInThreadMessage() {
    if (!workspaces.selectedWorkspace || !this.selectedChat) return;

    await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(this.selectedChat.threadChatId)
      .collection("messages")
      .doc(this.selectedChat.threadMessageId)
      .update({
        repliesCount: firebaseApp.firestore.FieldValue.increment(1),
        updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      });

    //update chat
    await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .update({
        updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      });
  }

  private updateThreadInteractions = async () => {
    if (
      !workspaces.selectedWorkspace ||
      !this.selectedChat ||
      this.selectedChat.hasThreadInteractions
    )
      return;

    await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .update({
        hasThreadInteractions: true,
      });

    this.setChat({
      ...this.selectedChat,
      hasThreadInteractions: true,
    });
  };

  clearChatAndMessages = () => {
    this.threadOpen = false;
    this.selectedChat = undefined;
    this.messages = [];
    this.lastChatMessage = "";
  };

  private insertNewMessage(message: EdnaMessage) {
    const index = this.messages.findIndex((f) => f.id === message.id);
    if (index === -1) {
      this.setMessages([message, ...this.messages]);
    }
  }

  private setChat(chat: EdnaChat) {
    this.selectedChat = chat;
  }

  private setMessages(messages: EdnaMessage[]) {
    this.messages = messages;
  }
}

export default new EdnaThreadChatsStore();
