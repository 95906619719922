import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
// @ts-ignore
import { setDefaults } from "bulma-toast";

import "./styles/index.scss";
import "./services/localization";
import { customError } from "./utils/console";
// import reportWebVitals from './reportWebVitals';

(window as any).firebase = undefined;
require("./services/firebase");

setDefaults({
  duration: 5000,
  closeOnClick: false,
  dismissible: true,
  pauseOnHover: true,
  animate: { in: "slideInRight", out: "slideOutRight" },
});

const root = createRoot(document.getElementById("root")!);

const App = () => {
  const AppRouter = window.location.pathname.toLowerCase().includes("web_view")
    ? require("./WebViewApp").default
    : require("./App").default;

  return <AppRouter />;
};

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
console.error = customError;

if ("serviceWorker" in navigator) {
  const configString = encodeURIComponent(
    //@ts-ignore
    JSON.stringify(window.firebaseAppConfig)
  );

  navigator.serviceWorker
    .register(`/firebase-messaging-sw.js?config=${configString}`, {
      scope: "/firebase-cloud-messaging-push-scope",
    })
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((err) => {
      console.error("Service Worker registration failed:", err);
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
