import { ChatChannel } from "./Chat";

export type CampaignDataType = "file" | "json" | "tag";

export enum CampaignStatus {
  PENDING = "pending",
  ENDED = "ended",
  SCHEDULED = "scheduled",
  IN_PROGRESS = "in-progress",
}

export const campaignStatusMap: Record<CampaignStatus, string> = {
  [CampaignStatus.PENDING]: "Pending",
  [CampaignStatus.ENDED]: "Ended",
  [CampaignStatus.SCHEDULED]: "Scheduled",
  [CampaignStatus.IN_PROGRESS]: "In Progress",
};

export const campaignStatusColorMap: Record<CampaignStatus, string> = {
  [CampaignStatus.PENDING]: "#b5b5b5",
  [CampaignStatus.ENDED]: "#27AE60",
  [CampaignStatus.SCHEDULED]: "#e6a500",
  [CampaignStatus.IN_PROGRESS]: "#2f79ef",
};

export type Campaign = {
  id: number;
  name: string;
  channel: ChatChannel;
  dataType?: CampaignDataType;
  createdAt: Date;
  status?: CampaignStatus;
};
