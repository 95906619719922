import { useTranslation } from "react-i18next";
import {
  DownloadButton,
  FallbackImage,
  ImageModal,
} from "../../../../components";
import locale from "../../../../constants/locale";

import { EdnaMessageParser } from "../../../../models/EdnaChat";
import AudioPlayer from "react-h5-audio-player";
import { useState } from "react";

export const RenderFile = ({
  message: { file },
}: {
  message: EdnaMessageParser;
}) => {
  const { t } = useTranslation();
  const [imageToShow, setImageToShow] = useState("");

  if (!file) {
    return null;
  }

  let media: JSX.Element = <div />;

  switch (file.type) {
    case "image/png":
    case "image/jpeg":
      media = (
        <div
          className="image is-square has-rounded-corners is-clickable"
          onClick={() => setImageToShow(file.link)}
        >
          <FallbackImage
            src={file.link}
            alt="media"
            customFallback={<span>{t(locale.resourceNotFound)}</span>}
          />
        </div>
      );
      break;
    case "audio/mpeg":
    case "audio/mp3":
    case "audio/amr":
    case "audio/ogg":
      media = (
        <div>
          <AudioPlayer
            src={file.link}
            showJumpControls={false}
            autoPlay={false}
          />
        </div>
      );
      break;
    case "video/mp4":
      media = (
        <div className="image is-16by9 has-rounded-corners">
          <video className="has-ratio" src={file.link} controls />
        </div>
      );
      break;
    case "application/pdf":
      return (
        <DownloadButton
          // className="has-text-white-bis"
          disabledPrimaryText
          src={file.link}
          name={file.name}
        />
      );
  }

  return (
    <>
      {media}
      {file.name}
      {!!imageToShow && (
        <ImageModal
          src={imageToShow}
          isOpen
          onClose={() => setImageToShow("")}
        />
      )}
    </>
  );
};
