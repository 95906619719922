import firebase from "./firebase";
import { logError } from "./logging";

import { RemoteConfig } from "../models";

const remoteConfig = firebase.remoteConfig();

if (process.env.NODE_ENV === "development") {
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // 1 hour
}

//Used to be 15 mins
remoteConfig.defaultConfig = {
  session_timeout: "240", 
  search_chats_min_chars: "6",
};

const getConfig = async (key: RemoteConfig) => {
  try {
    await remoteConfig.fetchAndActivate();
  } catch (error) {
    logError(error);
  }

  return remoteConfig.getValue(key);
};

export default getConfig;
