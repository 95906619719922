import { useField, FieldConfig } from "formik";

export const MultiSelect = <T,>({
  children,
  ...props
}: FieldConfig<T[]> & {
  disabled?: boolean;
  label?: string;
  options: T[];
  disabledOptions?: T[];
  getLabel: (t: T) => string;
}) => {
  const [, { value, error, touched }, { setValue }] = useField(props);

  const handleToggle = (v: T) => () => {
    if (value.includes(v)) {
      setValue(value.filter((f) => f !== v));
    } else {
      setValue([...value, v]);
    }
  };

  return (
    <div className="field">
      <label className="label">{props.label}</label>
      {props.options.map((m, i) => (
        <div key={i} className="control">
          <label className="checkbox">
            <input
              type="checkbox"
              onChange={handleToggle(m)}
              checked={value?.includes(m)}
              disabled={props.disabledOptions?.includes(m)}
            />{" "}
            {props.getLabel(m)}
          </label>
        </div>
      ))}
      {touched && !!error && <p className="help is-danger">{error}</p>}
    </div>
  );
};
