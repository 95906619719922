import firebaseApp from "firebase/compat/app";

export enum IntegrationProviderEnum {
  OUTLOOK = "outlook",
  GOOGLE = "google",
}

export const integrationProviderMap: Record<IntegrationProviderEnum, string> = {
  [IntegrationProviderEnum.OUTLOOK]: "Outlook",
  [IntegrationProviderEnum.GOOGLE]: "Google",
};

export const integrationProviderIconMap: Record<
  IntegrationProviderEnum,
  string
> = {
  [IntegrationProviderEnum.OUTLOOK]: "outlook-calendar.svg",
  [IntegrationProviderEnum.GOOGLE]: "google-calendar.svg",
};

export type Integration = {
  provider: IntegrationProviderEnum;
} & (OutlookIntegration | GoogleIntegration);

export type OutlookIntegration = {
  id?: string;
  name: string;
  token: any;
  provider: IntegrationProviderEnum.OUTLOOK;
  connected: boolean;
  calendarId: string;
  liveChatId: number;
  meta: any;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
};

export type GoogleIntegration = {
  id?: string;
  name: string;
  token: any;
  provider: IntegrationProviderEnum.GOOGLE;
  connected: boolean;
  calendarId: string;
  liveChatId: number;
  meta: any;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
};
