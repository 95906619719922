import { observer } from "mobx-react-lite";
import { TicketComment } from "../../models";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores";
import { DateTime } from "luxon";
import { useRef, useState, useCallback } from "react";
import { FallbackImage } from "../fallbackImage/FallbackImage";
import Mentionify from "../mentionify/Mentionify";
import locale from "../../constants/locale";
import Mentions from "../mentions/Mentions";
import { v4 as uuid } from "uuid";

const Comments = ({
  value,
  onChange,
}: {
  value?: TicketComment[];
  onChange?: (v: TicketComment[]) => void;
}) => {
  const { t } = useTranslation();
  const auth = useStore("auth");
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [selectedComment, setSelectedComment] = useState<
    TicketComment | undefined
  >();
  const [messageValue, setMessageValue] = useState("");
  const { users } = useStore("users");

  const listener = useCallback(
    (e: any) => {
      if (e.keyCode !== 13) {
        return false;
      }
      e.preventDefault();

      if (e.altKey || e.ctrlKey) {
        setMessageValue((p) => p + "\n");
      } else {
        if (selectedComment) {
          onChange &&
            onChange(
              value?.map((f) =>
                f.id === selectedComment.id
                  ? { ...f, comment: messageValue, notified: false }
                  : f
              ) || []
            );
        } else {
          onChange &&
            onChange([
              ...(value || []),
              {
                id: uuid(),
                userId: auth?.user?.id || "",
                comment: messageValue,
                createdAt: new Date(),
                updatedAt: new Date(),
                notified: false,
              },
            ]);
        }
        setSelectedComment(undefined);
        setMessageValue("");
      }
    },
    [auth?.user?.id, messageValue, onChange, selectedComment, value]
  );

  const renderUserSuggestion = useCallback((suggestion: any) => {
    return <div>{suggestion.display}</div>;
  }, []);

  const displayTransformHandler = useCallback((_: any, display: any) => {
    return "@" + display;
  }, []);

  return (
    <div>
      <div className="has-border mb-4" id="comment-box">
        <Mentions
          refMention={textAreaRef}
          value={messageValue}
          onChange={(e: any) => {
            setMessageValue(e.target.value);
          }}
          trigger="@"
          data={users
            ?.filter((f) => f.id !== auth?.user?.id)
            ?.map((user: any) => ({
              id: user.id,
              display: user.name,
            }))}
          onKeyDown={listener}
          renderSuggestion={renderUserSuggestion}
          displayTransform={displayTransformHandler}
          markup={"@[__display__](__id__)"}
          placeholder={t(locale.addAComment)}
          appendSpaceOnAdd={true}
        />
      </div>
      <div className="is-flex is-flex-direction-column is-flex-direction-column-reverse">
        {value?.map((comment) => {
          const user = users?.find((f) => f.id === comment.userId);
          const date = DateTime.fromJSDate(
            (comment?.createdAt as any)?.toDate
              ? (comment.createdAt as any)?.toDate()
              : comment.createdAt
          );
          const time = date.hasSame(DateTime.utc(), "day")
            ? date.toFormat("t")
            : date.toLocaleString({
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
              });
          return (
            <div
              key={comment.id}
              id={"ticket-comment-" + comment.id}
              className="is-flex"
            >
              <figure className="is-flex-shrink-0">
                <p className="image is-40x40 is-square">
                  <FallbackImage
                    className="is-rounded"
                    src={user?.image}
                    fallback="/assets/avatar.svg"
                    alt={user?.name || "user"}
                  />
                </p>
              </figure>
              <div className="ml-3 is-flex is-flex-direction-column">
                <div className="is-flex">
                  <strong className="mr-2">{user?.name || "User"}</strong>
                  <span>{time}</span>
                </div>
                <div className={comment.userId !== auth.user?.id ? "mb-5" : ""}>
                  <Mentionify>{comment.comment}</Mentionify>
                </div>
                {comment.userId === auth.user?.id && (
                  <div className="is-flex">
                    <button
                      className="button is-ghost p-0 is-no-box-shadow"
                      type="button"
                      onClick={() => {
                        setSelectedComment(comment);
                        setMessageValue(comment.comment);
                        document.getElementById("comment-box")?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }}
                    >
                      {t(locale.edit)}
                    </button>
                    <button
                      className="button is-ghost p-0 ml-2 is-no-box-shadow"
                      type="button"
                      onClick={() =>
                        onChange &&
                        onChange(
                          value?.filter((f) => f.id !== comment.id) || []
                        )
                      }
                    >
                      {t(locale.delete)}
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(Comments);
