import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "../NavBar.module.scss";
import ProfilePopover from "./ProfilePopover";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import DesktopNavbar from "./DesktopNavbar";
import WorkspaceSidebar from "../../workspaceSidebar/WorkspaceSidebar";
import { matchPath, useLocation } from "react-router";
import { useStore } from "../../../stores";
import { observer } from "mobx-react-lite";
import routes, { publicRoutes } from "../../../constants/routes";

const MobileNavbar = () => {
  const { user } = useStore("auth");
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: "/workspace/:workspaceId/:functionId",
    exact: false,
    strict: false,
  });

  const functionId = (match?.params as any)?.functionId as never;
  const isAFunction = ["op"].includes(functionId);

  const isHelpCenterUserViewPage = location.pathname.includes(
    routes.helpCenterUserViewHome.replace("/:workspaceId", "")
  );

  const isBookingPage = location.pathname.includes(
    routes.booking.replace("/:bookingWorkspaceId", "").replace("/:eventId", "")
  );

  useEffect(() => {
    if (location.pathname || location.search) {
      setIsOpen(false);
    }
  }, [location.pathname, location.search]);

  if (
    !user ||
    isHelpCenterUserViewPage ||
    isBookingPage ||
    publicRoutes.includes(location.pathname)
  ) {
    return null;
  }

  return (
    <>
      <div className={clsx(styles.mobileContainer, "p-4 has-border-bottom")}>
        <div className="is-flex is-justify-content-space-between">
          <div className="is-flex">
            {location.pathname !== "/workspace/create" && (
              <button
                onClick={() => setIsOpen(true)}
                className="button is-ghost is-no-box-shadow "
              >
                <div className="icon">
                  <img src="/assets/navbar-open.svg" alt="" />
                </div>
              </button>
            )}

            <img
              className={clsx(styles.mobileLogo)}
              src="/assets/mobile-logo.svg"
              alt="logo"
            />
          </div>
          <ProfilePopover />
        </div>
      </div>

      {isAFunction && isOpen && (
        <Drawer open={true} onClose={() => setIsOpen(false)} direction="left">
          <DesktopNavbar />
        </Drawer>
      )}

      {!isAFunction && isOpen && (
        <Drawer
          style={{ width: "6.4rem", height: "100%" }}
          open={true}
          onClose={() => setIsOpen(false)}
          direction="left"
        >
          <WorkspaceSidebar checkMobile={false} />
        </Drawer>
      )}
    </>
  );
};

export default observer(MobileNavbar);
