import firebaseApp from "firebase/compat/app";
import { SupportedLanguage } from ".";

export enum ArticleStatus {
  PUBLISHED = "published",
  DRAFT = "draft",
}

export const articleStatusMap: Record<ArticleStatus, string> = {
  [ArticleStatus.PUBLISHED]: "Published",
  [ArticleStatus.DRAFT]: "Draft",
};

export type Article = {
  id: string;
  userId: string;
  collectionId: string;
  collectionName: string;
  title: string;
  description: string;
  status: ArticleStatus;
  lang: SupportedLanguage;
  embeddingStatus: EmbeddingStatus;
  lastEmbeddidAt?: firebaseApp.firestore.FieldValue;
  createdAt: Date | firebaseApp.firestore.FieldValue;
  updatedAt: Date | firebaseApp.firestore.FieldValue;
};

export type Collection = {
  id: string;
  name: string;
  noOfArticles: number;
  embeddingStatus: EmbeddingStatus;
  lastEmbeddidAt?: firebaseApp.firestore.FieldValue;
  createdAt: Date;
  updatedAt: Date;
};

export type FAQ = {
  id: string;
  question: string;
  answer: number;
  embeddingStatus: EmbeddingStatus;
  lastEmbeddidAt?: firebaseApp.firestore.FieldValue;
  createdAt: Date;
  updatedAt: Date;
};

export type HelpCenter = {
  botName?: string;
  welcomeText: string;
  primaryColor: string;
  secondaryColor: string;
  logoUrl: string;
  gotoUrlEnabled: boolean;
  gotoUrlText: string;
  gotoUrl: string;
  communityUrlEnabled: boolean;
  communityUrlText: string;
  communityUrl: string;
  academyUrlEnabled: boolean;
  academyUrlText: string;
  academyUrl: string;
  popularArticlesIds: string[];
  popularArticles?: Article[];
  facebookUrl?: string;
  twitterUrl?: string;
  linkedinUrl?: string;
};

export enum EmbeddingStatus {
  PENDING = "pending",
  TRAINED = "trained",
  IN_PROGRESS = "in-progress",
}

export const embeddingStatusMap: Record<EmbeddingStatus, string> = {
  [EmbeddingStatus.PENDING]: "Pending",
  [EmbeddingStatus.TRAINED]: "Trained",
  [EmbeddingStatus.IN_PROGRESS]: "In progress",
};

export const embeddingStatusColorMap: Record<EmbeddingStatus, string> = {
  [EmbeddingStatus.PENDING]: "#3d3d3d",
  [EmbeddingStatus.IN_PROGRESS]: "#ffc61d",
  [EmbeddingStatus.TRAINED]: "#27AE60",
};

export type EmbeddingData = {
  collectionId: string;
  collectionName: string;
  text?: string;
  meta?: any;
};
