import { DetailedHTMLProps, FC } from "react";
import useCollapse from "react-collapsed";
import { GetTogglePropsOutput } from "react-collapsed/dist/types";

type CollapseProps = {
  defaultExpanded?: boolean;
  renderTrigger?: FC<GetTogglePropsOutput & { isExpanded: boolean }>;
} & DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Collapse: FC<CollapseProps> = ({
  renderTrigger,
  children,
  defaultExpanded,
  ...props
}) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded,
    hasDisabledAnimation: false,
  });

  return (
    <>
      {!!renderTrigger && renderTrigger({ ...getToggleProps(), isExpanded })}
      <div {...props} {...getCollapseProps()}>
        {children}
      </div>
    </>
  );
};
